import apiBuilder from "../api/apiBuilder";
import { decryptData, importKey } from "../utils";
import { IpService } from "./IpService";


const baseURL = apiBuilder;


export const getOAuthToken = async (email?: string, password?: string) => {

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
  const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || '';
  const USER_EMAIL = email ?? process.env.REACT_APP_USER_EMAIL;
  const USER_PASSWORD = password ?? process.env.REACT_APP_USER_PASSWORD;
  const CLIENT_REDIRECT_URI = process.env.REACT_APP_CLIENT_REDIRECT_URI || '';

  try {
    const OAuthBody = {
      client_id: CLIENT_ID,
      redirect_uri: CLIENT_REDIRECT_URI,
      response_type: "code",
      grant_type: ["authorization_code"],
      userEmail: USER_EMAIL,
      userPassword: USER_PASSWORD
    }

    const clientData = await IpService.publicIPs()

    if (clientData?.[0]) {
      const params = (clientData) ? {
        headers: { 'ip-client': clientData?.[0] }
      } : {}
      const { data } = await baseURL.post('/oauth/authorize', OAuthBody, params);

      if (data.code) {
        const OAuthTokenBody = {
          code: data.code.authorizationCode,
          redirect_uri: data.code.redirectUri,
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          grant_type: "authorization_code",
          scope: ["user.write"]
        }

        const { data: { accessToken } } = await baseURL.post('/oauth/token', OAuthTokenBody, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });

        return accessToken;
      }
    }


  }
  catch (error) {
    console.error('ERROR GETTING TOKEN', error);
  }
}

export const getUserProfile = async () => {
  try {
    const { data } = await baseURL.get('/auth/user-info');
    if (data) {
      const criptoKey = await importKey(data.key);
      const decriptedData = await decryptData(data.info, criptoKey);
      return decriptedData;
    }
  }
  catch (error: any) {
    console.error('ERROR GETTING USER PROFILE ', error.response.data);
    return null;
  }
}
