import { CookieDataLayer, CookieDataLayerArray, CookieEventVirtualPage, DataLayerArray, DataLayerObj } from "../hooks/useGa";

type GaAction = 
| { type: 'initialPageDataLayer', payload: DataLayerObj}
| { type: 'addEventDataLayer', payload: DataLayerArray | CookieDataLayerArray | CookieDataLayer}
| { type: 'addEventVirtualPageDataLayer', payload: CookieEventVirtualPage }


export const GaReducer = (state : any[], action : GaAction) => {
 
    switch(action.type){
        case 'initialPageDataLayer':
            return [
                ...state, 
                action.payload
            ]
        case 'addEventDataLayer': 
            return [
                ...state,
                action.payload
            ]
        case 'addEventVirtualPageDataLayer': 
            return [
                ...state,
                action.payload
            ]
        default :
            return state
    }
}

