import { Footer, FooterMobile, NavbarHeader } from '../../components'
import './StaticPageStyle.css'
import { Container, Table } from 'react-bootstrap';
import { useDeviceType } from '../../hooks';


export const CookiePage = () => {

  const isMobile = useDeviceType();

  return (
    <>
    <NavbarHeader />
    <div className='static-page-container'>
      {
        (isMobile)
        ? (<Container fluid className="static-fix-size">


<h1 className='static-page-title'>Política de Cookies</h1>
          <p className="static-page-text">En su primera visita a esta página, le pedimos (mediante un banner de notificación) que aceptase el uso de cookies y tecnologías similares y nos gustaría explicarle cómo usamos estas tecnologías.</p>

          <p className="static-page-text">Al igual que otras páginas web, utilizamos cookies para diferentes propósitos. Algunas cookies son necesarias para la funcionalidad del sitio web, otras recogen datos sobre el hardware de su dispositivo y su interacción con nuestra página. Es nuestra política usar cookies y tecnologías de rastreo solo con el consentimiento de los usuarios de los servicios digitales que brindamos, excepto cuando el uso de cookies o tecnologías de rastreo es estrictamente necesario para el uso de un servicio.</p>

          <p className="static-page-text">En esta Política de cookies se aplica a nuestros Servicios en línea que se vinculan o muestran esta política y explica cómo usamos las cookies, sus propósitos y cómo puede controlarlos. Para obtener más información sobre nuestras prácticas de privacidad, visite nuestra <a href='/privacidad' className='static-page-links'>Política de privacidad.</a></p>


          <div className='static-page-content'>
              <ol className='static-page-ordered-list static-page-main-list'>
                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Qué son las cookies?</li>

                  <p className="static-page-text">Son pequeños archivos de texto que son exclusivos de su dispositivo.  Pueden estar basadas en sesiones (en otras palabras, durar mientras su navegador está abierto) o persistentes (en otras palabras, durar hasta que las elimine o caduquen).</p>
                </div>
                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Qué información obtenemos a través de las cookies?</li>

                  <p className="static-page-text">Nuestro uso de cookies puede implicar el procesamiento de datos personales.  La información que podemos obtener a través de las cookies incluye información sobre su dispositivo, acciones de navegación, patrones de uso y ubicación, tales como: dirección IP de su dispositivo, información de ubicación, identificadores únicos del dispositivo, tipo y modelo de dispositivo, características y configuración del dispositivo, configuración y características del navegador, tipo y versión del sistema operativo, preferencias de idioma y país, estadísticas de uso,  informes de errores, y otros detalles del dispositivo. También podemos obtener información sobre sus interacciones con nuestros Servicios en línea, como las páginas visitadas, los enlaces en los que se hizo clic, las funciones utilizadas, las URL de referencia o de salida, las fechas y horas de uso, la información de la sesión, los datos de secuencia de clics y desplazamiento de la página, y otra información sobre sus interacciones con nuestros Servicios en línea.</p>
                </div>

                <div className="static-page-content-box">
                    <li className='static-page-list-title'>En esta web utilizamos:</li>
                    <p className="static-page-text">Las cookies utilizadas en nuestros servicios en línea se dividen en las siguientes categorías:</p>
                    <ol className='static-page-list-small-margin static-page-list-margin-bottom'>
                      <li className="static-page-list-text">Cookies técnicas</li>
                      <li className="static-page-list-text">Analítica</li>
                      <li className="static-page-list-text">Cookies de Terceros</li>
                    </ol>

                    <ol className='static-page-list-small-margin'>
                      <li className='static-page-text'><strong>Cookies técnicas</strong></li>
                      <p className="static-page-text">Estas cookies permiten que nuestros Servicios en línea recuerden las elecciones que realiza y proporcionen funciones mejoradas y personalizadas. Recopilan información (como su IP, preferencias y opciones, páginas navegadas o productos vistos) para que podamos brindarle una mejor experiencia, mejorar nuestros Servicios en línea y desarrollar nuevos servicios. Por ejemplo, cuando inicia sesión en su cuenta para utilizar los Servicios en línea, utilizamos estas cookies para almacenar su información de inicio de sesión. Esto permite que nuestros Servicios en línea recuerden sus preferencias de usuario y le brinden una experiencia más personalizada basada en esas preferencias. También le permite utilizar los Servicios en línea sin tener que iniciar sesión cada vez que regrese a un Servicio en línea.</p>

                      <p className="static-page-text">Estas cookies generalmente se eliminan cuando finaliza su sesión de navegación o dentro de un período de tiempo posterior. Si no permite estas cookies, es posible que no pueda aprovechar al máximo nuestros servicios en línea.</p>

                      <li className='static-page-text'><strong>Cookies analíticas/de rendimiento</strong></li>
                      <p className="static-page-text">Utilizamos estas cookies para ayudarnos a analizar cómo se utilizan nuestros Servicios en línea y mejorar su funcionamiento, por ejemplo, para mejorar el rendimiento y hacer que nuestros Servicios en línea sean más fáciles de usar. Estas cookies recopilan información sobre cómo los usuarios interactúan con nuestros servicios en línea, por ejemplo, sobre qué funciones de los servicios en línea se utilizan, durante cuánto tiempo se utilizan y si se ha producido algún error al utilizarlas. Estas cookies también nos ayudan a contar el número de usuarios únicos de un servicio en línea en particular y a medir el rendimiento y la eficacia del contenido que ponemos a disposición a través de nuestros servicios en línea. La información obtenida a través de estas cookies puede ser divulgada o recopilada directamente por servicios de análisis de terceros.</p>

                      <li className='static-page-text'><strong>Cookies de Terceros</strong></li>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Denominación</th>
                            <th>Titular</th>
                            <th>Tipología</th>
                            <th>Duración</th>
                            <th>Finalidad</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>aam_uuid</td>
                            <td>Adobe</td>
                            <td>Publicidad comportamental</td>
                            <td>120 días</td>
                            <td>Permiten mejorar y gestionar la exposición de anuncios publicitarios al usuario mientras navega, evitando que aparezcan anuncios que ya ha visto, así como publicar anuncios relevantes para sus intereses</td>
                          </tr>
                          <tr>
                            <td>_ga</td>
                            <td>Google</td>
                            <td>Análisis</td>
                            <td>2 años</td>
                            <td>Se usa para distinguir a los Usuarios</td>
                          </tr>
                          <tr>
                            <td>_gid</td>
                            <td>Google</td>
                            <td>Análisis</td>
                            <td>24 h</td>
                            <td>Se usa para distinguir a los Usuarios</td>
                          </tr>
                          <tr>
                            <td>_gat</td>
                            <td>Google</td>
                            <td>Análisis</td>
                            <td>1 minuto</td>
                            <td>Se usa para limitar el porcentaje de solicitudes</td>
                          </tr>
                          <tr>
                            <td>_utmc</td>
                            <td>Google</td>
                            <td>Análisis</td>
                            <td>Cierre de navegador</td>
                            <td>Se usa para calcular el promedio de tiempo que los Usuarios pasan en el Sitio Web</td>
                          </tr>
                          <tr>
                            <td>_utma</td>
                            <td>Google</td>
                            <td>Análisis</td>
                            <td>1 minuto</td>
                            <td>Se usa para registrar la fecha de la primera y última vez que el Usuario visitó el Sitio Web</td>
                          </tr>
                          <tr>
                            <td>_utmb</td>
                            <td>Google</td>
                            <td>Análisis</td>
                            <td>30 min</td>
                            <td>Se usa para registrar la hora de llegada al Sitio Web</td>
                          </tr>
                          <tr>
                            <td>_utmz</td>
                            <td>Google</td>
                            <td>Análisis</td>
                            <td>6 meses</td>
                            <td>Se usa para el almacenamiento de la fuente de tráfico o una campaña, y así entender cómo el Usuario llegó al Sitio Web</td>
                          </tr>
                          <tr>
                            <td>_fbp</td>
                            <td>Facebook</td>
                            <td>Publicidad comportamental</td>
                            <td>3 meses</td>
                            <td>Es utilizada por Facebook y permiten mejorar y gestionar la exposición de anuncios publicitarios al usuario mientras navega, evitando que aparezcan anuncios que ya ha visto, así como publicar anuncios relevantes para sus intereses.</td>
                          </tr>
                          <tr>
                            <td>_fr</td>
                            <td>Facebook</td>
                            <td>Publicidad comportamental</td>
                            <td>3 meses</td>
                            <td>Es utilizada por Facebook y permiten mejorar y gestionar la exposición de anuncios publicitarios al usuario mientras navega, evitando que aparezcan anuncios que ya ha visto, así como publicar anuncios relevantes para sus intereses</td>
                          </tr>
                          <tr>
                            <td>_tr</td>
                            <td>Facebook</td>
                            <td>Publicidad comportamental</td>
                            <td>Cierre de navegado</td>
                            <td>Es utilizada por Facebook y permiten mejorar y gestionar la exposición de anuncios publicitarios al usuario mientras navega, evitando que aparezcan anuncios que ya ha visto, así como publicar anuncios relevantes para sus intereses.</td>
                          </tr>
                          <tr>
                            <td>ebOptUut</td>
                            <td>sizmek</td>
                            <td>Publicidad comportamental</td>
                            <td>5 Años</td>
                            <td>Identifica los clientes que han rechazado las cookies de Sizmek en la propia página del fabricante. En este caso, Sizmek nunca descargará sus cookies en los browsers de estos clientes.</td>
                          </tr>
                          <tr>
                            <td>A3, B4, C4, D1, F1</td>
                            <td>sizmek</td>
                            <td>Publicidad comportamental</td>
                            <td>90 días</td>
                            <td>Permite saber cuántas veces un usuario ha visto el anuncio para asignar una frecuencia, si ha clicado en él y si ha cumplido un objetivo de campaña.</td>
                          </tr>
                          <tr>
                            <td>bs.serving.sys</td>
                            <td>sizmek</td>
                            <td>Publicidad comportamental</td>
                            <td>90 días</td>
                            <td>Información técnica que permite servir los anuncios en el formato adecuado.</td>
                          </tr>
                          <tr>
                            <td>u2</td>
                            <td>sizmek</td>
                            <td>Publicidad comportamental</td>
                            <td>90 días</td>
                            <td>Permite asignar un ID único de cookie.</td>
                          </tr>
                          <tr>
                            <td>TargetingInfo, g1</td>
                            <td>sizmek</td>
                            <td>Publicidad comportamental</td>
                            <td>90 días</td>
                            <td>Permite grabar información de navegación en la web para servir anuncios a medida de los intereses del usuario</td>
                          </tr>
                        </tbody>
                      </Table>


                    </ol>
                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>Solución de gestión del consentimiento</li>
                  <p className="static-page-text">Dependiendo de dónde se encuentre, puede gestionar sus preferencias de cookies basadas en la web a través de nuestra solución de gestión del consentimiento en los servicios en línea correspondientes. Esto incluye retirar el consentimiento para esas cookies si nos ha proporcionado previamente dicho consentimiento. Tenga en cuenta que cuando utiliza estas herramientas, debe renovar sus opciones cada vez que borre las cookies de su navegador o dispositivo, utilice un nuevo navegador o dispositivo, o visite un servicio en línea diferente.</p>

                </div>


                <div className="static-page-content-box">
                <li className='static-page-list-title'>Controles del navegador</li>
                <p className="static-page-text">También puede evitar que se descarguen ciertas cookies basadas en la web en su dispositivo seleccionando la configuración adecuada en su navegador web. La mayoría de los navegadores web le dirán cómo dejar de aceptar nuevas cookies, cómo recibir una notificación cuando reciba una nueva cookie y cómo deshabilitar las cookies existentes. Sin embargo, tenga en cuenta que sin las cookies es posible que no pueda aprovechar al máximo todas las funciones de nuestros servicios en línea.</p>

                <p className="static-page-text">Haga clic en los siguientes enlaces para saber cómo gestionar las cookies en los navegadores más populares:</p>

                <ol className='static-page-list-small-margin'>
                  <li className="static-page-list-text"><a target="_blank" href='https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en' className='static-page-links' rel="noreferrer">Google Chrome</a></li>
                  <li className="static-page-list-text"><a target="_blank" href='https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd' className='static-page-links' rel="noreferrer">Microsoft Edge</a></li>
                  <li className="static-page-list-text"><a target="_blank" href='https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US' className='static-page-links' rel="noreferrer">Mozilla Firefox</a></li>
                  <li className="static-page-list-text"><a target="_blank" href='https://support.microsoft.com/en-gb/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d' className='static-page-links' rel="noreferrer">Microsoft Internet Explorer</a></li>
                  <li className="static-page-list-text"><a target="_blank" href='https://help.opera.com/en/latest/web-preferences/' className='static-page-links' rel="noreferrer">Ópera</a></li>
                  <li className="static-page-list-text"><a target="_blank" href='https://support.apple.com/en-gb/safari' className='static-page-links' rel="noreferrer">Apple-Safari</a></li>
                </ol>

              </div>

            </ol>
          </div>
          </Container>)
        : (<Container className="static-fix-size">


          <h1 className='static-page-title'>Política de Cookies</h1>
                    <p className="static-page-text">En su primera visita a esta página, le pedimos (mediante un banner de notificación) que aceptase el uso de cookies y tecnologías similares y nos gustaría explicarle cómo usamos estas tecnologías.</p>

                    <p className="static-page-text">Al igual que otras páginas web, utilizamos cookies para diferentes propósitos. Algunas cookies son necesarias para la funcionalidad del sitio web, otras recogen datos sobre el hardware de su dispositivo y su interacción con nuestra página. Es nuestra política usar cookies y tecnologías de rastreo solo con el consentimiento de los usuarios de los servicios digitales que brindamos, excepto cuando el uso de cookies o tecnologías de rastreo es estrictamente necesario para el uso de un servicio.</p>

                    <p className="static-page-text">En esta Política de cookies se aplica a nuestros Servicios en línea que se vinculan o muestran esta política y explica cómo usamos las cookies, sus propósitos y cómo puede controlarlos. Para obtener más información sobre nuestras prácticas de privacidad, visite nuestra <a href="/privacidad" className='static-page-links'>Política de privacidad</a>.</p>


                    <div className='static-page-content'>
                        <ol className='static-page-ordered-list static-page-main-list'>
                          <div className="static-page-content-box">
                            <li className='static-page-list-title'>¿Qué son las cookies?</li>

                            <p className="static-page-text">Son pequeños archivos de texto que son exclusivos de su dispositivo.  Pueden estar basadas en sesiones (en otras palabras, durar mientras su navegador está abierto) o persistentes (en otras palabras, durar hasta que las elimine o caduquen).</p>
                          </div>
                          <div className="static-page-content-box">
                            <li className='static-page-list-title'>¿Qué información obtenemos a través de las cookies?</li>

                            <p className="static-page-text">Nuestro uso de cookies puede implicar el procesamiento de datos personales.  La información que podemos obtener a través de las cookies incluye información sobre su dispositivo, acciones de navegación, patrones de uso y ubicación, tales como: dirección IP de su dispositivo, información de ubicación, identificadores únicos del dispositivo, tipo y modelo de dispositivo, características y configuración del dispositivo, configuración y características del navegador, tipo y versión del sistema operativo, preferencias de idioma y país, estadísticas de uso,  informes de errores, y otros detalles del dispositivo. También podemos obtener información sobre sus interacciones con nuestros Servicios en línea, como las páginas visitadas, los enlaces en los que se hizo clic, las funciones utilizadas, las URL de referencia o de salida, las fechas y horas de uso, la información de la sesión, los datos de secuencia de clics y desplazamiento de la página, y otra información sobre sus interacciones con nuestros Servicios en línea.</p>
                          </div>

                          <div className="static-page-content-box">
                              <li className='static-page-list-title'>En esta web utilizamos:</li>
                              <p className="static-page-text">Las cookies utilizadas en nuestros servicios en línea se dividen en las siguientes categorías:</p>
                              <ol className='static-page-list-small-margin static-page-list-margin-bottom'>
                                <li className="static-page-list-text">Cookies técnicas</li>
                                <li className="static-page-list-text">Analítica</li>
                                <li className="static-page-list-text">Cookies de Terceros</li>
                              </ol>

                              <ol className='static-page-list-small-margin'>
                                <li className='static-page-text'><strong>Cookies técnicas</strong></li>
                                <p className="static-page-text">Estas cookies permiten que nuestros Servicios en línea recuerden las elecciones que realiza y proporcionen funciones mejoradas y personalizadas. Recopilan información (como su IP, preferencias y opciones, páginas navegadas o productos vistos) para que podamos brindarle una mejor experiencia, mejorar nuestros Servicios en línea y desarrollar nuevos servicios. Por ejemplo, cuando inicia sesión en su cuenta para utilizar los Servicios en línea, utilizamos estas cookies para almacenar su información de inicio de sesión. Esto permite que nuestros Servicios en línea recuerden sus preferencias de usuario y le brinden una experiencia más personalizada basada en esas preferencias. También le permite utilizar los Servicios en línea sin tener que iniciar sesión cada vez que regrese a un Servicio en línea.</p>

                                <p className="static-page-text">Estas cookies generalmente se eliminan cuando finaliza su sesión de navegación o dentro de un período de tiempo posterior. Si no permite estas cookies, es posible que no pueda aprovechar al máximo nuestros servicios en línea.</p>

                                <li className='static-page-text'><strong>Cookies analíticas/de rendimiento</strong></li>
                                <p className="static-page-text">Utilizamos estas cookies para ayudarnos a analizar cómo se utilizan nuestros Servicios en línea y mejorar su funcionamiento, por ejemplo, para mejorar el rendimiento y hacer que nuestros Servicios en línea sean más fáciles de usar. Estas cookies recopilan información sobre cómo los usuarios interactúan con nuestros servicios en línea, por ejemplo, sobre qué funciones de los servicios en línea se utilizan, durante cuánto tiempo se utilizan y si se ha producido algún error al utilizarlas. Estas cookies también nos ayudan a contar el número de usuarios únicos de un servicio en línea en particular y a medir el rendimiento y la eficacia del contenido que ponemos a disposición a través de nuestros servicios en línea. La información obtenida a través de estas cookies puede ser divulgada o recopilada directamente por servicios de análisis de terceros.</p>

                                <li className='static-page-text'><strong>Cookies de Terceros</strong></li>
                                <Table responsive>
                                  <thead>
                                    <tr>
                                      <th>Denominación</th>
                                      <th>Titular</th>
                                      <th>Tipología</th>
                                      <th>Duración</th>
                                      <th>Finalidad</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>aam_uuid</td>
                                      <td>Adobe</td>
                                      <td>Publicidad comportamental</td>
                                      <td>120 días</td>
                                      <td>Permiten mejorar y gestionar la exposición de anuncios publicitarios al usuario mientras navega, evitando que aparezcan anuncios que ya ha visto, así como publicar anuncios relevantes para sus intereses</td>
                                    </tr>
                                    <tr>
                                      <td>_ga</td>
                                      <td>Google</td>
                                      <td>Análisis</td>
                                      <td>2 años</td>
                                      <td>Se usa para distinguir a los Usuarios</td>
                                    </tr>
                                    <tr>
                                      <td>_gid</td>
                                      <td>Google</td>
                                      <td>Análisis</td>
                                      <td>24 h</td>
                                      <td>Se usa para distinguir a los Usuarios</td>
                                    </tr>
                                    <tr>
                                      <td>_gat</td>
                                      <td>Google</td>
                                      <td>Análisis</td>
                                      <td>1 minuto</td>
                                      <td>Se usa para limitar el porcentaje de solicitudes</td>
                                    </tr>
                                    <tr>
                                      <td>_utmc</td>
                                      <td>Google</td>
                                      <td>Análisis</td>
                                      <td>Cierre de navegador</td>
                                      <td>Se usa para calcular el promedio de tiempo que los Usuarios pasan en el Sitio Web</td>
                                    </tr>
                                    <tr>
                                      <td>_utma</td>
                                      <td>Google</td>
                                      <td>Análisis</td>
                                      <td>1 minuto</td>
                                      <td>Se usa para registrar la fecha de la primera y última vez que el Usuario visitó el Sitio Web</td>
                                    </tr>
                                    <tr>
                                      <td>_utmb</td>
                                      <td>Google</td>
                                      <td>Análisis</td>
                                      <td>30 min</td>
                                      <td>Se usa para registrar la hora de llegada al Sitio Web</td>
                                    </tr>
                                    <tr>
                                      <td>_utmz</td>
                                      <td>Google</td>
                                      <td>Análisis</td>
                                      <td>6 meses</td>
                                      <td>Se usa para el almacenamiento de la fuente de tráfico o una campaña, y así entender cómo el Usuario llegó al Sitio Web</td>
                                    </tr>
                                    <tr>
                                      <td>_fbp</td>
                                      <td>Facebook</td>
                                      <td>Publicidad comportamental</td>
                                      <td>3 meses</td>
                                      <td>Es utilizada por Facebook y permiten mejorar y gestionar la exposición de anuncios publicitarios al usuario mientras navega, evitando que aparezcan anuncios que ya ha visto, así como publicar anuncios relevantes para sus intereses.</td>
                                    </tr>
                                    <tr>
                                      <td>_fr</td>
                                      <td>Facebook</td>
                                      <td>Publicidad comportamental</td>
                                      <td>3 meses</td>
                                      <td>Es utilizada por Facebook y permiten mejorar y gestionar la exposición de anuncios publicitarios al usuario mientras navega, evitando que aparezcan anuncios que ya ha visto, así como publicar anuncios relevantes para sus intereses</td>
                                    </tr>
                                    <tr>
                                      <td>_tr</td>
                                      <td>Facebook</td>
                                      <td>Publicidad comportamental</td>
                                      <td>Cierre de navegado</td>
                                      <td>Es utilizada por Facebook y permiten mejorar y gestionar la exposición de anuncios publicitarios al usuario mientras navega, evitando que aparezcan anuncios que ya ha visto, así como publicar anuncios relevantes para sus intereses.</td>
                                    </tr>
                                    <tr>
                                      <td>ebOptUut</td>
                                      <td>sizmek</td>
                                      <td>Publicidad comportamental</td>
                                      <td>5 Años</td>
                                      <td>Identifica los clientes que han rechazado las cookies de Sizmek en la propia página del fabricante. En este caso, Sizmek nunca descargará sus cookies en los browsers de estos clientes.</td>
                                    </tr>
                                    <tr>
                                      <td>A3, B4, C4, D1, F1</td>
                                      <td>sizmek</td>
                                      <td>Publicidad comportamental</td>
                                      <td>90 días</td>
                                      <td>Permite saber cuántas veces un usuario ha visto el anuncio para asignar una frecuencia, si ha clicado en él y si ha cumplido un objetivo de campaña.</td>
                                    </tr>
                                    <tr>
                                      <td>bs.serving.sys</td>
                                      <td>sizmek</td>
                                      <td>Publicidad comportamental</td>
                                      <td>90 días</td>
                                      <td>Información técnica que permite servir los anuncios en el formato adecuado.</td>
                                    </tr>
                                    <tr>
                                      <td>u2</td>
                                      <td>sizmek</td>
                                      <td>Publicidad comportamental</td>
                                      <td>90 días</td>
                                      <td>Permite asignar un ID único de cookie.</td>
                                    </tr>
                                    <tr>
                                      <td>TargetingInfo, g1</td>
                                      <td>sizmek</td>
                                      <td>Publicidad comportamental</td>
                                      <td>90 días</td>
                                      <td>Permite grabar información de navegación en la web para servir anuncios a medida de los intereses del usuario</td>
                                    </tr>
                                  </tbody>
                                </Table>


                              </ol>
                          </div>

                          <div className="static-page-content-box">
                            <li className='static-page-list-title'>Solución de gestión del consentimiento</li>
                            <p className="static-page-text">Dependiendo de dónde se encuentre, puede gestionar sus preferencias de cookies basadas en la web a través de nuestra solución de gestión del consentimiento en los servicios en línea correspondientes. Esto incluye retirar el consentimiento para esas cookies si nos ha proporcionado previamente dicho consentimiento. Tenga en cuenta que cuando utiliza estas herramientas, debe renovar sus opciones cada vez que borre las cookies de su navegador o dispositivo, utilice un nuevo navegador o dispositivo, o visite un servicio en línea diferente.</p>

                          </div>


                          <div className="static-page-content-box">
                          <li className='static-page-list-title'>Controles del navegador</li>
                          <p className="static-page-text">También puede evitar que se descarguen ciertas cookies basadas en la web en su dispositivo seleccionando la configuración adecuada en su navegador web. La mayoría de los navegadores web le dirán cómo dejar de aceptar nuevas cookies, cómo recibir una notificación cuando reciba una nueva cookie y cómo deshabilitar las cookies existentes. Sin embargo, tenga en cuenta que sin las cookies es posible que no pueda aprovechar al máximo todas las funciones de nuestros servicios en línea.</p>

                          <p className="static-page-text">Haga clic en los siguientes enlaces para saber cómo gestionar las cookies en los navegadores más populares:</p>

                          <ol className='static-page-list-small-margin'>
                            <li className="static-page-list-text"><a target="_blank" href='https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en' className='static-page-links' rel="noreferrer">Google Chrome</a></li>
                            <li className="static-page-list-text"><a target="_blank" href='https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd' className='static-page-links' rel="noreferrer">Microsoft Edge</a></li>
                            <li className="static-page-list-text"><a target="_blank" href='https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US' className='static-page-links' rel="noreferrer">Mozilla Firefox</a></li>
                            <li className="static-page-list-text"><a target="_blank" href='https://support.microsoft.com/en-gb/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d' className='static-page-links' rel="noreferrer">Microsoft Internet Explorer</a></li>
                            <li className="static-page-list-text"><a target="_blank" href='https://help.opera.com/en/latest/web-preferences/' className='static-page-links' rel="noreferrer">Ópera</a></li>
                            <li className="static-page-list-text"><a target="_blank" href='https://support.apple.com/en-gb/safari' className='static-page-links' rel="noreferrer">Apple-Safari</a></li>
                          </ol>

                        </div>

                      </ol>
                    </div>
                    </Container>)
      }

      </div>
      <footer>
        {
          (isMobile) ? <FooterMobile /> : <Footer />
        }
      </footer>
    </>
  )
}
