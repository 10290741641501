import { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive";



export const useDeviceType = () => {

  const [isMobile, setIsMobile] = useState(true);
  const isMobileQuery = useMediaQuery({ query: '(max-width: 992px)' });

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery])

  return isMobile;
}