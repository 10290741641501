import { createSlice } from "@reduxjs/toolkit";


export const prizesSlice = createSlice({
  name: 'prizes',
  initialState: {
    prizesSelectValue: '',
    prizesSearchInputValue: ''
  },
  reducers: {
    setPrizesSelectValue: (state, action) => {
      state.prizesSelectValue = action.payload
    },
    setPrizesSearchInputValue: (state, action) => {
      state.prizesSearchInputValue = action.payload
    },
    resetPrizesValue: (state) => {
      state.prizesSearchInputValue = '';
      state.prizesSelectValue = '';
    }
  }
});

export const { setPrizesSelectValue, setPrizesSearchInputValue, resetPrizesValue } = prizesSlice.actions;