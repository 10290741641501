
import './PrizeCouponText.css';
import Barcode from 'react-barcode';

interface Props {
    winner: boolean
    couponPrize ?: number
    couponBarcodeValue ?: string
    bestPrize ?: boolean
    printFn ?: any
    printing: boolean;
}

export const PrizeCouponText = ({ winner, couponPrize, bestPrize, printFn, couponBarcodeValue, printing } : Props) => {
  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();

  if(winner){

    if(bestPrize){
      return (
      <div className="prize-coupon-container">
          <div className="prize-coupon-text-with-img-responsive">
            <span className='d-block prize-coupon-high-text'>Eres el más</span>
            <img src={`${appURL}/images/bp_badge.png`} alt='bp badge' className="prize-coupon-img-badge" />
          </div>
          <div className='prize-coupon-text-with-img-responsive'>
          <img src={`${appURL}/images/bip_winner_img.png`} alt="bp biiiip" className="prize-coupon-img-biiiip"/>
          </div>

          <span className='prize-coupon-text-responsive prize-coupon-high-text prize-coupon-separator-margin'>of the world y te vas a
          Abu Dabi.</span>

          {
            (printing)
              ? (
                <div > {/* style={{ width: '100vw' }} */ }
                  {/* <p className="prize-coupon-prize-description mb-0">
                    Ve ensayando tu cara BIP para todas las fotos que te vas a hacer
                  </p> */}
                  <p className='prize-coupon-best-prize-bold mb-0'>
                    ¡Enhorabuena!
                  </p>
                  <p className=''>
                   Ve preparando tus maletas y escogiendo a tus a compañantes para disfrutar de esta exclusiva experiencia que incluye:
                  </p>
                  <ul className='prize-coupon-best-prize-ul'>
                    <li>Vuelo ida y vuelta Madrid - Abu Dabi para 4 personas (del 4 de diciembre al 10 de diciembre de 2024).</li>
                    <li>Traslados al aeropuerto, a la clasificación y a la carrera.</li>
                    <li>Hotel 4* con desayuno incluido.</li>
                    <li>Acceso a la carrera.</li>
                    <li>Visita al paddock y conocer a los pilotos o miembros del equipo BTW Alpine F1® Team.</li>
                    <li>Visita al taller.</li>
                    <li>Seguro de viaje.</li>
                  </ul>
                </div>
              )
              : (
                <>
                  {/* <p className="prize-coupon-text-responsive prize-coupon-prize-description mb-0" >
                    Ve ensayando tu cara BIP para todas las fotos que te vas a hacer
                  </p> */}
                  <p className='prize-coupon-best-prize-bold mb-0'>
                    ¡Enhorabuena!
                  </p>
                  <p className=''>
                   Ve preparando tus maletas y escogiendo a tus a compañantes para disfrutar de esta exclusiva experiencia que incluye:
                  </p>
                  <ul className='prize-coupon-best-prize-ul'>
                    <li>Vuelo ida y vuelta Madrid - Abu Dabi para 4 personas (del 4 de diciembre al 10 de diciembre de 2024).</li>
                    <li>Traslados al aeropuerto, a la clasificación y a la carrera.</li>
                    <li>Hotel 4* con desayuno incluido.</li>
                    <li>Acceso a la carrera.</li>
                    <li>Visita al paddock y conocer a los pilotos o miembros del equipo BTW Alpine F1® Team.</li>
                    <li>Visita al taller.</li>
                    <li>Seguro de viaje.</li>
                  </ul>
                </>
              )
          }
          <button className="prize-coupon-download-btn mb-3" onClick={printFn} style={{ display: (printing) ? 'none' : 'block' }}>
            Descargar tu cupón
          </button>
        </div>
      )
    }

    if(couponPrize && couponBarcodeValue && !bestPrize ) {

      return (
        
	<div className="prize-coupon-container">
  <div className="prize-coupon-text-with-img-responsive">
    <span className='d-block prize-coupon-high-text'>Eres el más</span>
    <img src={`${appURL}/images/bp_badge.png`} alt='bp badge' className="prize-coupon-img-badge"
                 style={{ width: (printing) ? '2em' : '3.5em'}}
            />
  </div>
  <div className='prize-coupon-text-with-img-responsive'>
  <img src={`${appURL}/images/bip_winner_img.png`} alt="bp biiiip" className="prize-coupon-img-biiiip"
                 style={{ width: (printing) ? '300px' : '20em', height: (printing) ? 'auto' : '6em' }}
            />
  </div>
  
  <span className='prize-coupon-text-responsive prize-coupon-high-text prize-coupon-separator-margin'
        style={{ marginBottom: (printing) ? '10px' : '1.25em' }}
  >
    y has ganado:
  </span>
  
  <p className="prize-coupon-text-responsive prize-coupon-prize-description mb-0">Un cupón de carburante de</p>
  
  <span className="prize-coupon-ultra-high-value prize-coupon-text-responsive" style={{ fontSize : (printing) ? '70px' : '5em' }}>
    { couponPrize }
  </span>

  <button className="prize-coupon-download-btn" onClick={printFn} style={{ display: (printing) ? 'none' : 'block' }}>
    Descargar tu cupón
  </button>
  {
    (!printing) && <p className='prize-coupon-remember'>Recuerda registrar tu tarjeta para poder usar este cupón</p>
  }
  <p className='prize-coupon-text prize-coupon-text-hight' 
     style={{ fontSize: (printing) ? '15px' : '18px', lineHeight: (printing) ? '15px' : '30.6px' }}
  >
    Y recuerda que para cualquier consulta puedes contactar llamando al
    <a href="tel:911799166" className='prize-coupon-links'><strong> 911 799 166 </strong></a>
    o escribiéndonos a nuestro e-mail:
    <a href='mailto:atencionalcliente@mibp.es' className='prize-coupon-links'><strong> atencionalcliente@mibp.es</strong></a>
  </p>

  <div style={{ alignSelf: 'center' }}>
    <Barcode format='EAN13' value={couponBarcodeValue} />
  </div>
</div>
      )
    }
    }else{
      return (
        <div className="prize-coupon-container-no-winner">

          <span className='prize-coupon-high-text'>Lo sentimos,</span>
          <span className='prize-coupon-high-text prize-coupon-separator-margin'>no has resultado ganador</span>
          <p className="prize-coupon-no-winner-description">¡Pero has acumulado una participación para el sorteo final de una experiencia VIP motera en Valencia!</p>

          <p className="prize-coupon-no-winner-gray-text">Recuerda registrar tu tarjeta miBP o Plan DinoBP para que podamos contactar contigo si resultas ganador. Es muy fácil, puedes registrarla a través de la app miBP o del botón "Registrarme/iniciar sesión" en la web <a target="_blank" href='https://www.miBP.es' className="prize-coupon-no-winner-link" rel="noreferrer">www.miBP.es</a>  o en la web de Plan DinoBP (<a target="_blank" href='https://www.plandinobp.es/es/inicio' className="prize-coupon-no-winner-link" rel="noreferrer">https://www.plandinobp.es/es/inicio</a>)</p>
        </div>
      )
    }


  return (
    <></>
  )

}
