import { useEffect, useRef, useState } from "react";
import {
  PrizeCouponText,
  PrizeCouponImg,
  PrizeCouponInstructionsAndTerms,
  NavbarHeader,
  FooterMobile,
  Footer,
} from "../../components";
import "./PrizeCheckPage.css";
import { Container, Col, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { useDeviceType, useGa } from "../../hooks";
import { useLocation } from "react-router-dom";

export const PrizeCheckPage = () => {
  const location = useLocation();
  const { state } = location
  let componentRef = useRef(null);
  const [printing, setPrinting] = useState(false);
  const [winner] = useState(state.winner || false);

  const [bestPrize] = useState(state.bestPrize || false);
  const [coupon] = useState(state.coupon || "5");
  const [couponBarcodeValue] = useState(state.couponBarcodeValue || "");

  const isMobile = useDeviceType();

  const { eventDataLayer, virtualPageEventDataLayer } = useGa();
  const setGaResult = () => {
    if (winner) {
      if (bestPrize) {
        return "ganador viaje";
      } else {
        let result = "";
        switch (coupon) {
          case "5€":
            result = "cupon 5";
            break;
          case "15€":
            result = "cupon 15";
            break;
          case "50€":
            result = "cupon 50";
            break;
          case "100€":
            result = "cupon 100";
            break;
        }

        return result;
      }
    } else {
      return "no ganador";
    }
  };

  const resultElements = () : string => {
    if(winner == true) {
      if(bestPrize == true){
        return setGaResult()
      } 
      return "ganador " + setGaResult()
    } else {
    return setGaResult()
    }
  }

  // useEffect(() => {
  //   const result = setGaResult();
  //   startDataLayerCookie(window.location.href, window.location.pathname, result);
  // }, []);
  useEffect(() => {
    var result = setGaResult() 
      virtualPageEventDataLayer("virtual_page", window.location.href, window.location.pathname, result)
  }, [location]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrinting(true);
      const result = setGaResult();
      let item_id = "cupon_viaje";
      let hierarchy = (window.location.pathname + "| ganador" + result);
      if (!result.includes("no ganador")) {
        if(bestPrize == true){
          item_id = "descarga tu " + item_id;
        }else {
          item_id = "descarga tu " + result.replace(" ", "_");
        }
        hierarchy = (bestPrize == true) ? (window.location.pathname + "|" + result) : (window.location.pathname + "|ganador " + result) 
        
      }
      eventDataLayer(
        "select_content",
        hierarchy,
        "clic_boton",
        item_id
      );
      return Promise.resolve();
    },
    onAfterPrint: () => setPrinting(false),
    onPrintError: () => setPrinting(false),
  });

  return (
    <>
      <NavbarHeader result={resultElements()}/>
      <div className="prize-check-container">
        <Container fluid className="prize-check-fix-size">
          <div className="prize-check-coupon mb-5">
            <PrizeCouponImg
              winner={winner}
              bestPrize={bestPrize}
              printing={printing}
            />
            <PrizeCouponText
              winner={winner}
              couponPrize={coupon}
              bestPrize={bestPrize}
              printFn={handlePrint}
              printing={printing}
              couponBarcodeValue={couponBarcodeValue}
            />
          </div>
          <div>
            <div>
              <PrizeCouponInstructionsAndTerms
                winner={winner}
                bestPrize={bestPrize}
                printing={printing}
              />
            </div>
          </div>
        </Container>
      </div>
      {isMobile ? <FooterMobile result={resultElements()} /> : <Footer result={resultElements()} />}
      <Container
        fluid
        ref={componentRef}
        style={{ display: printing ? "block" : "none", padding: "25px" }}
      >
        <Row className="prize-check-coupon flex-row mb-5">
          
          <Col xs={5}>
            
            <PrizeCouponText
              winner={winner}
              couponPrize={coupon}
              bestPrize={bestPrize}
              printFn={handlePrint}
              printing={printing}
              couponBarcodeValue={couponBarcodeValue}
            />
          </Col>
          <Col xs={5}>
            
            <PrizeCouponImg
              winner={winner}
              bestPrize={bestPrize}
              printing={printing}
            />
          </Col>
        </Row>
        <Row>
          
          <Col sm={12}>
            
            <PrizeCouponInstructionsAndTerms
              winner={winner}
              bestPrize={bestPrize}
              printing={printing}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
