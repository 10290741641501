import './HomePage.css';
import { Footer, FooterMobile, FormBanner, FormBannerMobile, MainBanner, MainBannerMobile, NavbarHeader, SliderBanner, SliderBannerMobile } from '../../components';
import { useDeviceType } from '../../hooks/useDeviceType';
import { Container } from 'react-bootstrap';
import { useGa } from '../../hooks/useGa';
import { useEffect } from 'react';

export const HomePage = () => {

  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();
  const isMobile = useDeviceType();
  const { virtualPageEventDataLayer ,eventDataLayer, isExternalCookieActive} = useGa();

  useEffect(() => {
    setTimeout( () => {
      virtualPageEventDataLayer("virtual_page", window.location.href, window.location.pathname, "")
    }, 100)

    if(isExternalCookieActive()){
      const script  = document.createElement("script")
      script.src="../../scripts/floodlight_conversion"

      document.body.appendChild(script)
    }
  }, [])

  // Coger wl windows datalayer y ver la cookie 03

  // insertar la de conversion de lexcel

  return (
    <div>
      <main>
        <NavbarHeader isHomeNavbar/>
        {
          (isMobile) ? <MainBannerMobile /> : <MainBanner />
        }

        <div className='prices-band'>
          <p>
            PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS PREMIOS
          </p>
        </div>

        {
          (isMobile) ? <SliderBannerMobile /> : <SliderBanner />
        }

        {
          (isMobile) ? <FormBannerMobile /> : <FormBanner />
        }

        <div className="sorteo-band">
          <div className="sorteo-band-fix-size">
            {
              (isMobile)
                ? (<Container fluid>
                  <p className="sorteo-band-text">Además, con cada código que canjees acumularás una participación para el sorteo de <strong>una <br /> experiencia motera VIP en Valencia</strong></p>
                </Container>)
                : (
                <Container>

                  <p className="sorteo-band-text">Además, con cada código que canjees acumularás una participación para el sorteo de <strong>una experiencia motera VIP en Valencia</strong></p>
                </Container>
                )
            }
          </div>
        </div>
        <div className="faq-band">
          <div className="faq-band-fix-size">


          {
            (isMobile)
              ? (
              <Container fluid>
                <p>Preguentas frecuentes <br/> (FAQs)</p>
              </Container>
              )
              : (<Container>
                <p>Preguentas frecuentes (FAQs)</p>
              </Container> )
          }
          <a href="/preguntas-frecuentes" target='_blank'>
            <button type='button' onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_boton', 'preguntas frecuentes_ver mas')}>Ver más</button>
          </a>
        </div>
        </div>

        <div className='bp-video'>
          <a href="https://youtu.be/tzBhWTLcLxQ" target='_blank' rel="noreferrer" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_video', 'video_play')} >
            <img src={`${appURL}/images/bp-video-play-btn.png`} alt="bP botón de play personalizado" />
          </a>
        </div>

        <div className="slogan-band">
          <div className="slogan-band-fix-size">
            {
              (isMobile)
                ? (<Container fluid>
                  <p style={{ fontSize: '28px' }}>Elegir un carburante tan eficiente, tan a la Ultimate, así en inglés, que te lleva más lejos, far away, es de ser muy BIP.</p>
                </Container>)
                :(<Container>
                  <p style={{ fontSize: '32px' }}>Elegir un carburante tan eficiente, tan a la Ultimate, así en inglés, que te <br/> lleva más lejos, far away, es de ser muy BIP.</p>
                </Container>)
            }

            {/* <div> */}
            { (isMobile)
              ? (<Container fluid>
                <div style={{ marginTop: '50px' }}>
                  <p style={{ fontSize: (isMobile) ? '20px' : '30px', color: 'black'}}>Descárgate la app miBP</p>
                </div>
                <div className="slogan-band-market-container">
                  <div className='market-img-container'>
                    <a href="https://mibp-es.onelink.me/WFsN/eresmuyBIP"><img src={`${appURL}/images/app_store.png`} alt="Link para descarga de miBP en Apple Store" className='market-img' /></a>
                  </div>
                  <div className='market-img-container'>
                    <a href="https://mibp-es.onelink.me/WFsN/eresmuyBIP"><img src={`${appURL}/images/google_play.png`} alt="Link para descarga de miBP en Google Play" className='market-img'/></a>
                  </div>
                </div>
              </Container>)
              :(<Container>
                <div style={{ marginTop: '50px' }}>
                  <p style={{ fontSize: (isMobile) ? '20px' : '30px', color: 'black'}}>Descárgate la app miBP</p>
                </div>
                <div className="slogan-band-market-container">
                  <div className='market-img-container'>
                    <a href="https://mibp-es.onelink.me/WFsN/eresmuyBIP"><img src={`${appURL}/images/app_store.png`} alt="Link para descarga de miBP en Apple Store" className='market-img'/></a>
                  </div>
                  <div className='market-img-container'>
                    <a href="https://mibp-es.onelink.me/WFsN/eresmuyBIP"><img src={`${appURL}/images/google_play.png`} alt="Link para descarga de miBP en Google Play" className='market-img'/></a>
                  </div>
                </div>
              </Container>)
            }
          </div>
        </div>
      </main>
      <footer>
        {
          (isMobile) ? <FooterMobile /> : <Footer />
        }
      </footer>
    </div>
  )
}