
import './NavbarHeader.css'
import { useNavigate } from 'react-router-dom'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDeviceType } from '../../hooks'
import { Container } from 'react-bootstrap'
import { useGa } from '../../hooks/useGa'

interface Props {
  isHomeNavbar ?: boolean,
  result ?: string
}

export const NavbarHeader = ({isHomeNavbar, result} : Props) => {
  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  const isMobile = useDeviceType();

  const navbarRef: MutableRefObject< null | HTMLDivElement> = useRef(null);
  const navbarStickyRef: MutableRefObject< null | HTMLDivElement> = useRef(null);
  const navbarSubMenuRef: MutableRefObject< null | HTMLDivElement> = useRef(null);

  const { eventDataLayer, gaState} = useGa(); 

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const navigateToHome = () => {
    navigate("/")
  }

  const adjustMenuPosition = () => {
    if (navbarRef.current != null && navbarSubMenuRef.current != null){
      let navbarHeight = 0
       if(isHomeNavbar && isMobile && navbarStickyRef.current != null){
          navbarHeight = window.scrollY + 160 ;
       } else {
        navbarHeight =  window.scrollY;
       } 
      navbarSubMenuRef.current.style.top = `${navbarHeight}px`;
    }
  }

  const handleScroll = () => {
    const scrollTop = window.scrollY;

    if (navbarRef.current) {
      const componentBottom = navbarRef.current.offsetTop + 175;
      const classname = 'sticky'
      if(isHomeNavbar) {
        if (scrollTop > componentBottom) {
          navbarRef.current.classList.add('navbar-component-home-none-container')
          adjustMenuPosition()
          navbarStickyRef.current?.classList.remove('navbar-component-home-none-container')
          navbarStickyRef.current?.classList.add('navbar-component-home-sticky-container')
        }else {
          navbarStickyRef.current?.classList.remove('navbar-component-home-sticky-container')
          navbarStickyRef.current?.classList.add('navbar-component-home-none-container')
          navbarRef.current.classList.remove('navbar-component-home-none-container')
        }
      }else{
        adjustMenuPosition()
        navbarRef.current.classList.add(classname)
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', adjustMenuPosition);
    navbarStickyRef.current?.classList.add('navbar-component-home-none-container')
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.addEventListener('resize', adjustMenuPosition);
    };
  }, []); 

  useEffect( () => {
    adjustMenuPosition()
  }, [isMenuOpen]);

   

  return (
    <>
      <div className={isHomeNavbar ? 'navbar-component-home-container' : 'navbar-component-container'} ref={navbarRef}>
        {
          (isMobile)
          ? (<Container className='navbar-component-fix-size'>
             
            <img src={`${appURL}/images/bp_logo_1.png`} className='navbar-component-logo-img' alt='Logo BP' onClick={navigateToHome}/> 
              
            <div>
              <div onClick={toggleMenu}>
                <img src={isHomeNavbar ? `${appURL}/images/burger-menu.png` : `${appURL}/images/dark-burger-menu.png`} className='navbar-component-menu-icon-mobile' alt="Icono de menú de hamburguesa" />
              </div>
            </div>
          </Container>
          ) 
          : (
            <Container className='navbar-component-fix-size'>
              {
                (isHomeNavbar)
                  ? (<img src={`${appURL}/images/bp_logo_1.png`} className='navbar-component-logo-img' alt='Logo BP' onClick={navigateToHome}/> )
                  : (<img src={`${appURL}/images/bp_logo_1.png`} className='navbar-component-logo-img' alt='Logo BP' onClick={navigateToHome}/> ) 
              }
              {
                (result != undefined)
                ? ( <div className="navbar-component-menu-box">
                  <a href="/preguntas-frecuentes" className={isHomeNavbar ? "navbar-component-menu-home-item" : "navbar-component-menu-item"} target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname + "|" + result, 'click_menu superior', 'menu superior_preguntas frecuentes')}>Preguntas Frecuentes</a>
                  <a href="/condiciones" className={isHomeNavbar ? "navbar-component-menu-home-item" : "navbar-component-menu-item"} target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname + "|" + result, 'click_menu superior', 'menu superior_terminos y condiciones')}>Términos y condiciones</a>
                  <a href="/privacidad" className={isHomeNavbar ? "navbar-component-menu-home-item" : "navbar-component-menu-item"} target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname + "|" + result, 'click_menu superior', 'menu superior_declaracion de privacidad')}>Declaración de privacidad</a>
                </div>) 
                : ( <div className="navbar-component-menu-box">
                  <a href="/preguntas-frecuentes" className={isHomeNavbar ? "navbar-component-menu-home-item" : "navbar-component-menu-item"} target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_preguntas frecuentes')}>Preguntas Frecuentes</a>
                  <a href="/condiciones" className={isHomeNavbar ? "navbar-component-menu-home-item" : "navbar-component-menu-item"} target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_terminos y condiciones')}>Términos y condiciones</a>
                  <a href="/privacidad" className={isHomeNavbar ? "navbar-component-menu-home-item" : "navbar-component-menu-item"} target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_declaracion de privacidad')}>Declaración de privacidad</a>
                </div>)
              }
             
              {
                (isHomeNavbar)
                  ? (<img src={`${appURL}/images/bip_white.png`}alt='Bepear te hace bip blanco' className='navbar-component-bip-logo-img'/>)
                  : (<img src={`${appURL}/images/bip_green.png`}alt='Bepear te hace bip verde' className='navbar-component-bip-logo-img'/>)
              }
                
            </Container>
          )
        }
      </div>
        {/* Este nuevo diseño es para cuando estás en la home y es sticky */}
        {
          (isHomeNavbar) 
            && (
              <div className='navbar-component-home-sticky-container sticky' ref={navbarStickyRef}>
                <Container className='navbar-component-fix-size'>
                  <img src={`${appURL}/images/bp_logo_1.png`} className='navbar-component-logo-img' alt='Logo BP' onClick={navigateToHome}/> 
                {
                  (!isMobile)
                    ? (
                      <>
                      <div >
                        <div className="navbar-component-menu-box">
                          
                              <a href="/preguntas-frecuentes" className="navbar-component-menu-sticky-item" target='_blank'  onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_preguntas frecuentes')}>Preguntas Frecuentes</a>
                              <a href="/condiciones" className="navbar-component-menu-sticky-item" target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_terminos y condiciones')}>Términos y condiciones</a>
                              <a href="/privacidad" className="navbar-component-menu-sticky-item" target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_declaracion de privacidad')}>Declaración de privacidad</a>
                            
                          
                          </div>
                        </div>
                        <img src={`${appURL}/images/bip_green.png`}alt='Bepear te hace bip verde' className='navbar-component-bip-logo-img'/>
                      </>
                    )
                    :(
                      <div>
                        <div onClick={toggleMenu}>
                        <img src={`${appURL}/images/dark-burger-menu.png`} className='navbar-component-menu-icon-mobile' alt="Icono de menú de hamburguesa" />
                        </div>
                      </div>
                    )
                }
                </Container>
              </div>
           )
        }
        {
          (isMenuOpen && isMobile) 
            && (
              <div className={isHomeNavbar ? "navbar-component-menu-box-home-mobile" : "navbar-component-menu-box-mobile"} ref={navbarSubMenuRef}>
                {
                  (result != undefined)
                  ? (
                    <>
                      <a href="/preguntas-frecuentes" className="navbar-component-menu-sticky-item" target='_blank'  onClick={() => eventDataLayer('select_content', window.location.pathname + "|" + result, 'click_menu superior', 'menu superior_preguntas frecuentes')}>Preguntas Frecuentes</a>
                      <a href="/condiciones" className="navbar-component-menu-sticky-item" target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname + "|" + result, 'click_menu superior', 'menu superior_terminos y condiciones')}>Términos y condiciones</a>
                      <a href="/privacidad" className="navbar-component-menu-sticky-item" target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname + "|" + result, 'click_menu superior', 'menu superior_declaracion de privacidad')}>Declaración de privacidad</a>
                    </>
                  )
                  : (
                    <>
                        <a href="/preguntas-frecuentes" className="navbar-component-menu-sticky-item" target='_blank'  onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_preguntas frecuentes')}>Preguntas Frecuentes</a>
                        <a href="/condiciones" className="navbar-component-menu-sticky-item" target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_terminos y condiciones')}>Términos y condiciones</a>
                        <a href="/privacidad" className="navbar-component-menu-sticky-item" target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname, 'click_menu superior', 'menu superior_declaracion de privacidad')}>Declaración de privacidad</a>
                    </>
                  )
                }
                
              </div>
            )
        }
    </>
  )
}

