export const parseDateTime = (dateString: string, add2Hours: boolean = false) => {
  const date = new Date(dateString);

  const pad = (num: number): string => num.toString().padStart(2, '0');

  const day = pad(date.getUTCDate());
  const month = pad(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();
  const hours = pad(date.getUTCHours());
  const minutes = pad(date.getUTCMinutes());
  const seconds = pad(date.getUTCSeconds());

	if (add2Hours) {
		return `${year}-${month}-${day} ${Number(hours) + 2}:${minutes}:${seconds}`;
	} else {
		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}
}

const flattenObject = (obj: any, parent: string = '', res: any = {}): any => {
  for (let key in obj) {
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

const convertArrayOfObjectsToCSV = (array: any[]) => {
	let result: any;

	const columnDelimiter = ';';
	const lineDelimiter = '\n';

  // Flatten all objects in the array
  const flattenedArray = array.map(item => flattenObject(item));
	if (flattenedArray.length <= 0) {
		return;
	}
	const keys = Object.keys(flattenedArray[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	flattenedArray.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;
			let value = item[key];
      if (value === null) {
        value = '';
      }
			result += value;
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

export const downloadCSV = (csv: any, fileName: string) => {
	const link = document.createElement('a');
	if (csv == null) return;

	const filename = `${fileName}_${Date.now()}`;

	// Add BOM for UTF-8 encoding
	const BOM = '\uFEFF';
	csv = BOM + csv;
	
	csv = `data:text/csv;charset=utf-8,${csv}`;

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
}

export const JWT_TOKEN_EXPIRATION_TIME = 600 * 1000;