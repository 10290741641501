
import { Container } from 'react-bootstrap';
import { Footer, FooterMobile, NavbarHeader } from '../../components'
import { useDeviceType } from '../../hooks';
import './FAQ.css'

export const FAQ = () => {

  const isMobile = useDeviceType();

  return (
    <>
    <NavbarHeader />
        <div className="static-page-container">
          {
            (isMobile)
            ? (<Container fluid className="static-fix-size">
              <h1 className="static-page-title">Preguntas Frecuentes (FAQ)</h1>

              <div className="static-page-content">
                <ol className='static-page-ordered-list static-page-main-list'>
                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>¿En qué consiste la promoción?</li>

                    <p className="static-page-text">Esta promoción premia a los clientes miBP que reposten 30 litros o más en una sola transacción. Cada repostaje de 30L o más, el cliente recibirá un código promocional en su ticket de compra, o dos códigos en el caso de que el repostaje sea con Carburates Ultimate. El cliente introducirá ese código en esta página web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> y descubrirá al momento si ha resultado ganador de uno de los 4503 premios que sorteamos.</p>
                  </div>

                  <div className="static-page-content-box">
                      <li className='static-page-list-title'>¿Cómo puedo participar?</li>
                      <p className="static-page-text">Para participar:</p>
                      <ol className='static-page-list-mid-margin'>
                        <li className="static-page-list-text">Reposta 30 litros o más de nuestros carburantes en cualquier estación bp de España o Andorra.</li>
                        <li className="static-page-list-text">Recibirás un código en su ticket de compra.</li>
                        <li className="static-page-list-text">Visita <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a></li>
                        <li className="static-page-list-text">Introduce su código y acepta los términos y condiciones.</li>
                        <li className="static-page-list-text">¡Descubre inmediatamente si ha ganado un premio!</li>
                      </ol>
                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Dónde puedo encontrar mi código?</li>
                  <p className="static-page-text">El código se imprimirá en tu ticket de compra después de repostar 30 litros o más en una estación bp participante</p>

                </div>


                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Cuántas veces puedo participar?</li>
                  <p className="static-page-text">Puedes participar una vez por cada código que recibas. Cada código es válido para una sola participación. Se limitará a un total de 10 códigos por día por cliente.</p>

                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Cuáles son los premios?</li>
                  <p className="static-page-text">Los premios incluyen:</p>
                  <ul className='static-page-list-mid-margin'>
                    <li className="static-page-list-text">3 experiencias V.I.P en Abu Dabi</li>
                    <li className="static-page-list-text">100 cupones de 100€ en carburante</li>
                    <li className="static-page-list-text">150 cupones de 50€ en carburante</li>
                    <li className="static-page-list-text">750 cupones de 15€ en carburante</li>
                    <li className="static-page-list-text">3500 cupones de 5€ en carburante</li>
                  </ul>

                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Necesito conservar mi recibo?</li>
                  <p className="static-page-text">No, pero te recomendamos que lo guardes para conservar tu código promocional.</p>

                </div>
                <div className="static-page-content-box">
                  <li className='static-page-list-title'>He resultado ganador de un premio, pero no he descargado mi cupón. ¿Puedo reclamar mi premio?</li>
                  <p className="static-page-text">Sí, en caso de no haber podido descargar el bono, ponte en contacto con nuestro servicio de atención al cliente través de:</p>
                  <p className='static-page-text'>E-mail de contacto: <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a></p>
                  <p className='static-page-text'>Teléfono: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>

                </div>
                <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Qué hago si mi código no funciona?</li>
                <p className="static-page-text">Asegúrate de introducir el código exactamente como aparece en tu ticket de compra. Si aún así el código no funciona, por favor contacta a nuestro servicio de atención al cliente para recibir ayuda.</p>
                <p className='static-page-text'>E-mail de contacto: <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a></p>
                <p className='static-page-text'>Teléfono: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Puedo participar en la promoción desde cualquier dispositivo?</li>
                <p className="static-page-text">Sí, la página web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> es accesible desde cualquier dispositivo con acceso a internet, incluyendo smartphones, tablets y ordenadores.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Necesito crear una cuenta para participar?</li>
                <p className="static-page-text">No, no necesitas crear una cuenta. Simplemente introduce tu código y acepta los términos y condiciones para participar.</p>
                <p className='static-page-text'>Sin embargo, para poder canjear tus premios deberás tener tu tarjeta miBP registrada. Puedes registrar tu tarjeta a través de <a target="_blank" href='https://www.miBP.es' className='static-page-links' rel="noreferrer">www.miBP.es</a> o a través de la aplicación móvil miBP.
                </p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Cómo sabré si he ganado?</li>
                <p className="static-page-text">Después de introducir tu código y aceptar los términos y condiciones, la página web te mostrará inmediatamente si has ganado un premio.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Qué debo hacer si gano un premio?</li>
                <p className="static-page-text">Consulta las condiciones para canjear tu premio en el bono que podrás descargarte de la web al resultar ganador.</p>
                <p className='static-page-text'>Te recordamos que para poder canjear tus premios deberás tener tu tarjeta miBP registrada. Puedes registrar tu tarjeta a través de <a target="_blank" href='https://www.miBP.es' className='static-page-links' rel="noreferrer">www.miBP.es</a> o a través de la aplicación móvil miBP.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Qué pasa si no estoy registrado y gano un viaje a Abu Dhabi o el premio final a Valencia?</li>
                <p className="static-page-text">Si has ganado un premio a Abu Dhabi, sigue las instrucciones que aparecen en el voucher y ponte en contacto con nuestro equipo de atención al cliente en:</p>
                <p className='static-page-text'>E-mail de contacto: <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a></p>
                <p className='static-page-text'>Teléfono: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>
                <p className='static-page-text'>Recuerda que para poder participar en el sorteo final del viaje a Valencia deberás tener tu tarjeta miBP registrada para que podamos contactar contigo. Puedes registrar tu tarjeta a través de <a target="_blank" href='https://www.miBP.es' className='static-page-links' rel="noreferrer">www.miBP.es</a> o a través de la aplicación móvil miBP.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Hay algún término y condición que deba tener en cuenta?</li>
                <p className="static-page-text">Sí, por favor lee y acepta los términos y condiciones en el página web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> antes de introducir tu código. Estos incluirán detalles sobre la elegibilidad, la recogida de premios y otra información importante.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Quién puede participar?</li>
                <p className="static-page-text">La promoción está abierta a todos los clientes miBP que reposten 30 litros o más en estaciones bp participantes, que sean mayores de edad y residentes en España o Andorra. Por favor, consulta los términos y condiciones para cualquier requisito específico de elegibilidad.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Cuánto tiempo dura la promoción?</li>
                <p className="static-page-text">El período de la promoción comienza el 17 de junio hasta el 2 de septiembre a las 23.59.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿A quién puedo contactar para más información?</li>
                <p className="static-page-text">Para cualquier pregunta adicional o problemas, por favor contacta a nuestro equipo de atención al cliente en:</p>
                <p className="static-page-text">E-mail de contacto: <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a></p>
                <p className="static-page-text">Teléfono: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>

              </div>


            </ol>
            </div>
            </Container>)
            : (<Container className="static-fix-size">
              <h1 className="static-page-title">Preguntas Frecuentes (FAQ)</h1>

              <div className="static-page-content">
                <ol className='static-page-ordered-list static-page-main-list'>
                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>¿En qué consiste la promoción?</li>

                    <p className="static-page-text">Esta promoción premia a los clientes miBP que reposten 30 litros o más en una sola transacción. Cada repostaje de 30L o más, el cliente recibirá un código promocional en su ticket de compra, o dos códigos en el caso de que el repostaje sea con Carburates Ultimate. El cliente introducirá ese código en esta página web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> y descubrirá al momento si ha resultado ganador de uno de los 4503 premios que sorteamos.</p>
                  </div>

                  <div className="static-page-content-box">
                      <li className='static-page-list-title'>¿Cómo puedo participar?</li>
                      <p className="static-page-text">Para participar:</p>
                      <ol className='static-page-list-mid-margin'>
                        <li className="static-page-list-text">Reposta 30 litros o más de nuestros carburantes en cualquier estación bp de España o Andorra.</li>
                        <li className="static-page-list-text">Recibirás un código en su ticket de compra.</li>
                        <li className="static-page-list-text">Visita <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a></li>
                        <li className="static-page-list-text">Introduce su código y acepta los términos y condiciones.</li>
                        <li className="static-page-list-text">¡Descubre inmediatamente si ha ganado un premio!</li>
                      </ol>
                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Dónde puedo encontrar mi código?</li>
                  <p className="static-page-text">El código se imprimirá en tu ticket de compra después de repostar 30 litros o más en una estación bp participante</p>

                </div>


                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Cuántas veces puedo participar?</li>
                  <p className="static-page-text">Puedes participar una vez por cada código que recibas. Cada código es válido para una sola participación. Se limitará a un total de 10 códigos por día por cliente.</p>

                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Cuáles son los premios?</li>
                  <p className="static-page-text">Los premios incluyen:</p>
                  <ul className='static-page-list-mid-margin'>
                    <li className="static-page-list-text">3 experiencias V.I.P en Abu Dabi</li>
                    <li className="static-page-list-text">100 cupones de 100€ en carburante</li>
                    <li className="static-page-list-text">150 cupones de 50€ en carburante</li>
                    <li className="static-page-list-text">750 cupones de 15€ en carburante</li>
                    <li className="static-page-list-text">3500 cupones de 5€ en carburante</li>
                  </ul>

                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>¿Necesito conservar mi recibo?</li>
                  <p className="static-page-text">No, pero te recomendamos que lo guardes para conservar tu código promocional.</p>

                </div>
                <div className="static-page-content-box">
                  <li className='static-page-list-title'>He resultado ganador de un premio, pero no he descargado mi cupón. ¿Puedo reclamar mi premio?</li>
                  <p className="static-page-text">Sí, en caso de no haber podido descargar el bono, ponte en contacto con nuestro servicio de atención al cliente través de:</p>
                  <p className='static-page-text'>E-mail de contacto: <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a></p>
                  <p className='static-page-text'>Teléfono: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>

                </div>
                <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Qué hago si mi código no funciona?</li>
                <p className="static-page-text">Asegúrate de introducir el código exactamente como aparece en tu ticket de compra. Si aún así el código no funciona, por favor contacta a nuestro servicio de atención al cliente para recibir ayuda.</p>
                <p className='static-page-text'>E-mail de contacto: <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a></p>
                <p className='static-page-text'>Teléfono: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Puedo participar en la promoción desde cualquier dispositivo?</li>
                <p className="static-page-text">Sí, la página web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> es accesible desde cualquier dispositivo con acceso a internet, incluyendo smartphones, tablets y ordenadores.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Necesito crear una cuenta para participar?</li>
                <p className="static-page-text">No, no necesitas crear una cuenta. Simplemente introduce tu código y acepta los términos y condiciones para participar.</p>
                <p className='static-page-text'>Sin embargo, para poder canjear tus premios deberás tener tu tarjeta miBP registrada. Puedes registrar tu tarjeta a través de <a target="_blank" href='https://www.miBP.es' className='static-page-links' rel="noreferrer">www.miBP.es</a> o a través de la aplicación móvil miBP.
                </p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Cómo sabré si he ganado?</li>
                <p className="static-page-text">Después de introducir tu código y aceptar los términos y condiciones, la página web te mostrará inmediatamente si has ganado un premio.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Qué debo hacer si gano un premio?</li>
                <p className="static-page-text">Consulta las condiciones para canjear tu premio en el bono que podrás descargarte de la web al resultar ganador.</p>
                <p className='static-page-text'>Te recordamos que para poder canjear tus premios deberás tener tu tarjeta miBP registrada. Puedes registrar tu tarjeta a través de <a target="_blank" href='https://www.miBP.es' className='static-page-links' rel="noreferrer">www.miBP.es</a> o a través de la aplicación móvil miBP.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Qué pasa si no estoy registrado y gano un viaje a Abu Dhabi o el premio final a Valencia?</li>
                <p className="static-page-text">Si has ganado un premio a Abu Dhabi, sigue las instrucciones que aparecen en el voucher y ponte en contacto con nuestro equipo de atención al cliente en:</p>
                <p className='static-page-text'>E-mail de contacto: <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a></p>
                <p className='static-page-text'>Teléfono: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>
                <p className='static-page-text'>Recuerda que para poder participar en el sorteo final del viaje a Valencia deberás tener tu tarjeta miBP registrada para que podamos contactar contigo. Puedes registrar tu tarjeta a través de <a target="_blank" href='https://www.miBP.es' className='static-page-links' rel="noreferrer">www.miBP.es</a> o a través de la aplicación móvil miBP.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Hay algún término y condición que deba tener en cuenta?</li>
                <p className="static-page-text">Sí, por favor lee y acepta los términos y condiciones en el página web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> antes de introducir tu código. Estos incluirán detalles sobre la elegibilidad, la recogida de premios y otra información importante.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Quién puede participar?</li>
                <p className="static-page-text">La promoción está abierta a todos los clientes miBP que reposten 30 litros o más en estaciones bp participantes, que sean mayores de edad y residentes en España o Andorra. Por favor, consulta los términos y condiciones para cualquier requisito específico de elegibilidad.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿Cuánto tiempo dura la promoción?</li>
                <p className="static-page-text">El período de la promoción comienza el 17 de junio hasta el 2 de septiembre a las 23.59.</p>

              </div>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>¿A quién puedo contactar para más información?</li>
                <p className="static-page-text">Para cualquier pregunta adicional o problemas, por favor contacta a nuestro equipo de atención al cliente en:</p>
                <p className="static-page-text">E-mail de contacto: <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a></p>
                <p className="static-page-text">Teléfono: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>

              </div>


            </ol>
            </div>
            </Container>)
          }

        </div>
        <footer>
        {
          (isMobile) ? <FooterMobile /> : <Footer />
        }
      </footer>
    </>
  )
}
