import './SliderBannerMobile.css';
import { Carousel, Col, Container, Row } from "react-bootstrap";



export const SliderBannerMobile = () => {

  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();

  return (
    <Container fluid className="main-container-mobile">
      <Row className='top-text-container-mobile mt-5 mb-3'>
        <Col>
          <h3>Poder ganar todos estos premios es de ser muy BIP</h3>
        </Col>
      </Row>
      <Row className='secondary-top-text-container-mobile'>
        <Col>
          <h6>Puedes participar hasta el 2 de septiembre de 2024</h6>
        </Col>
      </Row>
      <Row className='image-slider-container-mobile mt-5'>
        <Col>
          <Carousel interval={5000} indicators={true}>
            <Carousel.Item >
              <div className="image-slider-mobile-fix-width">
                <div className="image-slider-mobile-item-center-image">
                  <div className="px-4">
                  <img src={`${appURL}/images/slider-image-1.png`} alt="Piloto de la Fórmula 1" className="image-slider-mobile-big-img" />
                  </div>
                </div>
                <h5 className="image-slider-mobile-title">3 EXPERIENCIAS V.I.P</h5>
                <p className="image-slider-mobile-subtitle">en Abu Dabi para 4 personas cada una, valoradas en 16.000€, para vivir la gran final del motor.</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="image-slider-mobile-fix-width">
                <div className="image-slider-mobile-item-center-image">
                  <div className="px-4">
                  <img src={`${appURL}/images/slider-image-2.png`} alt="Bidón de gasolina" className="image-slider-mobile-big-img" />
                  </div>
                </div>
                <h5 className="image-slider-mobile-title">4.500 CUPONES DE CARBURANTE</h5>
              </div>
            </Carousel.Item>
            <Carousel.Item >
              <div className="image-slider-mobile-fix-width">
                <div className="image-slider-mobile-item-center-image">
                  <div className="px-4">
                  <img src={`${appURL}/images/slider-image-3.png`} alt="Piloto de motos" className="image-slider-mobile-big-img" />
                  </div>
                </div>
                <h5 className="image-slider-mobile-title">UN SORTEO INCREÍBLE</h5>
                <p className="image-slider-mobile-subtitle">para vivir una experiencia motera V.I.P en Valencia.</p>
              </div>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  )
}