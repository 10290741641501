import { Container, Spinner } from 'react-bootstrap';
import { AdminSidebar, FilterComponent } from '../../components';
import { useContext, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { parseDateTime } from '../../utils';
import { CheckVoucherResponse, CodeResults, Prizeid } from '../../models';
import { getCodes, goPage } from '../../services';
import { FaPowerOff } from 'react-icons/fa';
import './admin_pages.css'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { resetCodesValues } from '../../store/codes';



export const Codes = () => {

  const [data, setData] = useState<CodeResults[]>([]);

  const ROW_SIZE = 30;
  const [totalRows, setTotalRows] = useState(0);
  const [totalOffset, setTotalOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filterText, setFilterText] = useState('');
  const [isSorted, setIsSorted] = useState(false);
  const [sortText, setSortText] = useState('');
  
  const [loading, setLoading] = useState(false);
  const { logout } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();

  const handlerLogOut = async (event : any) => {
    event.preventDefault();
    dispatch(resetCodesValues());
    logout();
  }
  
  const columns = [
    {
      name: 'Código',
      selector: (row: CodeResults) => row.code,
      sortable: true,
    },
    {
      name: 'Usado el',
      selector: (row: CodeResults) => (row.used_at) ? parseDateTime(row.used_at, false) : 'No usado',
      sortable: true,
    },
    {
      name: 'Premio',
      selector: (row: CodeResults) => (row.moment_id?.prize_id.description) ?? ((row.used_at) ? 'No premiado' : 'No usado'),
      sortable: true,
    },
    {
      name: 'Momento asignado',
      selector: (row: CodeResults) => (row.moment_id?.moment_at) ? row.moment_id.moment_at : '',
      sortable: true
    },
    {
      name: 'IP',
      selector: (row: CodeResults) => (row.ip?.id) ?? '',
      sortable: false,
    },
    {
      name: 'Acciones',
      cell: (row: CodeResults) => {
        return row.moment_id?.prize_id ? (
          <button className='admin-generate-voucher-btn' onClick={() => generateVoucher(row)}>Generar voucher</button>
        ) : null;
      },
      ignoreRowClick: true
    }
  
  ];

  const paginationComponentOptions = {
    noRowsPerPage: true, 
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
  };

  const fetchData = async (filters: string) => {
    setLoading(true);
    const response: any = await getCodes(filters, totalOffset);
    setData((response != null)? response.data : []);
    setTotalRows((response != null)? response.total : 0);
    setLoading(false);
  }

  const fetchSortData = async (sort : string) => {
    setLoading(true);
    setIsSorted(true);
    setSortText(sort);
    const fullFilter = filterText + "&" + sort;
    const response : any = await getCodes(fullFilter, totalOffset);
    setData((response != null)? response.data : []);
    setLoading(false);
  }

  const handlePageChange = async (page : any) => {
    if (currentPage < page) {
      setTotalOffset(totalOffset + ROW_SIZE);
    }
    if (currentPage > page) {
      setTotalOffset(totalOffset - ROW_SIZE);
    }
    setCurrentPage(page);
  };

  const generateVoucher = (row: CodeResults) => {
    const voucherResponse: CheckVoucherResponse = {
      message: '',
      code: row.code,
      prize_id: row.moment_id?.prize_id as unknown as Prizeid,
      ip: row.ip?.id
    }
    goPage({ response: voucherResponse, navigate });
  }


  const customSort = async (rows : any, selector : any, direction: any) => {
    let cleanField = '';
    switch(rows.name) {
      case 'Usado el':
        cleanField = 'used_at';
        break;
      case 'Premio':
        cleanField = 'moment_id.prize_id.description';
        break;
      case 'Código':
        cleanField = 'code';
        break;
      case 'Momento asignado':
        cleanField = 'moment_id.moment_at';
        break;
    }
     
    let upperCaseSelector = selector.toUpperCase();
    let sortParam = `orderBy[]=${cleanField}=${upperCaseSelector}`
    await fetchSortData(sortParam)
  }

  const subHeaderComponentMemo = useMemo(() => {
		const handleClear = async() => {
			if (filterText) {
        setCurrentPage(1);
        setTotalOffset(0);
        setIsSorted(false);
        setResetPaginationToggle(!resetPaginationToggle);
        await fetchData(filterText);
      }
		};

		return (
			<FilterComponent
        onFilter={(text: string) => setFilterText(text)}
        onClear={handleClear}
        page='codes'
      />
		);
	}, [filterText, data]);


  useEffect(() => {
    setTimeout(() => {
      if (!filterText) {
        return;
      }
      if (isSorted) {
        fetchSortData(sortText)
      } else {
        fetchData(filterText);
      }
    }, 1000);
  }, [currentPage, filterText]);


  return (
    <>
      <Container fluid className="admin-main-navbar-container">
        <div className="admin-main-navbar-logo-menu">
          <div className="admin-main-navbar-logo-box">
            <img src={`${appURL}/images/bp-logo.png`} alt="Logo BP" className='admin-main-navbar-logo' />
          </div>
        </div>
        <div className="admin-main-navbar-profile">
          <FaPowerOff className='admin-navbar-profile-icon' onClick={handlerLogOut}/>
        </div>
      </Container>

    
      <div className="admin-main-container">
        <AdminSidebar />
        <div className="admin-main-content">
          <h1>Códigos</h1>
          <div className='admin-main-content-datatable'>
            <DataTable
               columns={columns}
               data={data}
               pagination
               paginationServer
               progressPending={loading}
               progressComponent={
                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                   <Spinner animation="border" role="status" style={{ marginTop: '15px', color: '#009640' }}></Spinner>
                 </div>
               }
               noDataComponent={
                (!loading) && (
                  <div style={{ padding: '24px' }}>
                    {
                      (filterText.length > 0)
                        ? 'No se han encontrado datos con los filtros aplicados'
                        : 'Es necesario filtrar para poder mostrar los datos'
                    }
                  </div>
                )
               }
               paginationTotalRows={totalRows}
               paginationPerPage={ROW_SIZE}
               paginationComponentOptions={paginationComponentOptions}
               paginationResetDefaultPage={resetPaginationToggle}
               subHeader
               subHeaderComponent={subHeaderComponentMemo}
               persistTableHead
               onChangePage={handlePageChange}
               sortServer
               onSort={customSort as any}
             />
          </div>
        </div>
      </div>
    </>
  )
}