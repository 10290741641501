import { NavigateFunction } from "react-router-dom";
import apiBuilder from "../api/apiBuilder";
import { CheckVoucherResponse, CodeEntity, CodeErrors, PrizeType, Prizeid } from "../models";
import { downloadCSV } from "../utils";
import { IpService } from "./IpService";



const baseURL = apiBuilder;

export const getCodes = async (filters: string, offset: number): Promise<CodeEntity | null> =>{
  try {
    const { data } = await baseURL.get(`/codes?limit=30&offset=${offset}${filters}`);
    return data as Promise<any>;
  }
  catch (error: any) {
    console.error('ERROR GETTING CODES ', error?.response?.data);
    return null;
  }
}

export const exportCodes = async (filters: string) => {
  try {
    const { data } = await baseURL.post(`/codes/download?${filters}`);
    downloadCSV(data, 'codes');
  }
  catch (error: any) {
    console.log(error);
  }
}

export const getVoucherPrizeValue = async (voucherCode: string) => {
  try {
    const clientData = await IpService.publicIPs()

    if (clientData?.[0]) {
      const { data, status } = await baseURL.get(`/codes/validation/${voucherCode}`, {
        headers: { 'ip-client': clientData?.[0] }
      });
      return {...data, statusCode: status} as Promise<CheckVoucherResponse>;
    }
  }
  catch (error: any) {
    console.error('ERROR GETTING CODE PRIZE ', error);
    return {...error.response.data, statusCode: error.response.status } as Promise<CheckVoucherResponse>;
  }
}

export const goPage = ({ response,
  navigate }: { response: CheckVoucherResponse, navigate: NavigateFunction }): void => {

  if (response.message && response.error) {
    switch (response.type) {
      case CodeErrors.NOT_WINNER:
        navigate('/check-prize', { replace: false, state: { winner: false, bestPrize: false } });
        break;

    }
  } else {
    const prizeId = (response.prize_id) ?? response;
    switch ((prizeId as unknown as Prizeid)?.name) {
      case PrizeType.ONE:
        navigate('/check-prize', {
          replace: false,
          state: { winner: true, bestPrize: false, coupon: PrizeType.ONE, couponBarcodeValue: (prizeId as unknown as Prizeid)?.coupon},
        });
        break;
      case PrizeType.TWO:
        navigate('/check-prize', {
          replace: false,
          state: { winner: true, bestPrize: false, coupon: PrizeType.TWO, couponBarcodeValue: (prizeId as unknown as Prizeid)?.coupon},
        });
        break;
      case PrizeType.THREE:
        navigate('/check-prize', {
          replace: false,
          state: { winner: true, bestPrize: false, coupon: PrizeType.THREE, couponBarcodeValue: (prizeId as unknown as Prizeid)?.coupon},
        });
        break;
      case PrizeType.FOUR:
        navigate('/check-prize', {
          replace: false,
          state: { winner: true, bestPrize: false, coupon: PrizeType.FOUR, couponBarcodeValue: (prizeId as unknown as Prizeid)?.coupon},
        });
        break;
      case PrizeType.SPECIAL:
        navigate('/check-prize', { replace: false, state: { winner: true, bestPrize: true} });
        break;
      default:
        navigate('/check-prize', { replace: false, state: { winner: false, bestPrize: false} });
    }
  }
}