import { Col, Container, Row } from "react-bootstrap"
import './PrizeCouponInstructionsAndTerms.css'

interface Props {
    winner: boolean;
    bestPrize?: boolean
    printing: boolean;
}

export const PrizeCouponInstructionsAndTerms = ({ winner, bestPrize, printing } : Props) => {
    if( winner ){
        if(bestPrize){
            return (
              <>
                  <Container fluid>
                      <Row>
                          <Col>
                                  <div className="prize-coupon-terms-green-title-box" style={{ marginBottom: (printing) ? '5px' : '20px' }}>
                                      <span className="prize-coupon-terms-green-title-text"
                                            style={{ fontSize: (printing) ? '18px' : '28px', lineHeight: (printing) ? '25px' : '33px' }}
                                      >
                                        Cómo Canjear
                                      </span>
                                  </div>
                                  <p>Para reclamar tu premio por favor ponte en contacto con atención al cliente a través de los siguientes canales en los próximos 7 días naturales:</p>
                                  
                                  <p>Teléfono: <a href="tel:911799166" className="prize-coupon-terms-links">911 799 166</a></p> 
                                  <p>Email: <a href="mailto:atencionalcliente@mibp.es" className="prize-coupon-terms-links">atencionalcliente@mibp.es</a></p>
                                  
                                   <p>Por favor, para poder gestionar tu premio necesitamos que nos indiques los siguientes datos cuando te pongas en contacto con nosotros para verificar que has sido el ganador:</p>
          
                                   <ul>
                                      <li>Nombre</li>
                                      <li>Apellidos</li>
                                      <li>Teléfono e email (asociados a tu cuenta miBP o Plan DinoBP)</li>
                                      <li>Número de tarjeta miBP o Plan DinoBP con la que realizaste la transacción en la que recibiste el código</li>
                                   </ul>
                                   
                                  <p>¡Recuerda! Si no te pones en contacto con nosotros en los próximos 7 días para reclamar tu premio pasaremos al siguiente ganador.</p>
                          </Col>
                      </Row>
                      <Row className="mb-4">
                          <Col>
                                  <div className="prize-coupon-terms-green-title-box" style={{ marginBottom: (printing) ? '5px' : '20px' }}>
                                      <span className="prize-coupon-terms-green-title-text"
                                            style={{ fontSize: (printing) ? '18px' : '28px', lineHeight: (printing) ? '25px' : '33px' }}
                                      >
                                        Términos y condiciones
                                      </span>
                                  </div>
                                  <ol>
                                      <div className="prize-coupon-terms-list-box">
                                          <li className="prize-coupon-terms-black-list-title">Aceptación del Premio</li>
                                          <ul>
                                              <li>El ganador deberá confirmar la aceptación del premio dentro de los 7 días naturales siguientes a la recepción de este bono.
                                              </li>
                                              <li>La confirmación deberá realizarse por escrito a la dirección de correo electrónico proporcionada en la notificación o por teléfono en el 911 799 166</li>
                                              <li>Si el ganador no confirma la aceptación del premio dentro del plazo estipulado, el premio se otorgará al siguiente participante en la lista de ganadores</li>
                                          </ul>
                                      </div>
                                      
                                      <div className="prize-coupon-terms-list-box">
                                          <li className="prize-coupon-terms-black-list-title">Detalles del Premio</li>
                                          <p className="mb-2">El premio consiste en una experiencia V.I.P. a Abu Dabi, incluyendo:</p>
                                          <ul>
                                          <li>Vuelo ida y vuelta Madrid - Abu Dabi para 4 personas (del 4 de diciembre al 10 de diciembre de 2024).</li>
                                          <li>Traslados al aeropuerto, a la clasificación y a la carrera.</li>
                                          <li>Hotel 4* con desayuno incluido.</li>
                                          <li>Acceso a la carrera.</li>
                                          <li>Visita al paddock y conocer a los pilotos o miembros del equipo BTW Alpine F1® Team.</li>
                                          <li>Visita al taller.</li>
                                          <li>Seguro de viaje.</li>
                                          </ul>
                                      </div>
                                      <div className="prize-coupon-terms-list-box">
                                          <li className="prize-coupon-terms-black-list-title">Exclusiones</li>
                                          <p className="mb-2">El premio no incluye:</p>
                                          <ul>
                                              <li>Comidas y bebidas no especificadas en el apartado "Detalles del Premio".</li>
                                              <li>Gastos personales, compras y actividades adicionales.</li>
                                              <li>Transporte desde la residencia del ganador hasta el aeropuerto de salida.</li>
                                          </ul>
                                      </div>
          
                                      <div className="prize-coupon-terms-list-box">
                                          <li className="prize-coupon-terms-black-list-title">Contrato de Aceptación del Premio</li>
                                          <p className="mb-1">Para disfrutar del premio, el ganador deberá firmar un contrato de aceptación del premio, en el que se detallarán todos los aspectos del premio, incluyendo lo que incluye y no incluye.</p>
                                          <p>El contrato de aceptación del premio será enviado al ganador tras la confirmación de aceptación del premio y deberá ser firmado y devuelto en un plazo de 7 días naturales.</p>
                                      </div>
          
                                      <div className="prize-coupon-terms-list-box">
                                          <li className="prize-coupon-terms-black-list-title">Modificaciones y Cancelaciones</li>
                                          <p>La organización se reserva el derecho de realizar modificaciones razonables en el itinerario del viaje o en los componentes del premio, notificando al ganador con antelación.
                                          En caso de cancelación por motivos ajenos a la organización, se buscarán alternativas razonables, pero la organización no será responsable de reembolsos o compensaciones adicionales.</p>
                                      </div>
          
                                      <div className="prize-coupon-terms-list-box">
                                          <li className="prize-coupon-terms-black-list-title">Aceptación de los Términos y Condiciones</li>
                                          <p>Al aceptar el premio, el ganador y sus acompañantes aceptan cumplir con estos términos y condiciones.</p>
          
                                      </div>
                                      <div className="prize-coupon-terms-final-list-box">
                                          <li className="prize-coupon-terms-black-list-title">Contacto</li>
                                          <p className="mb-4">Para cualquier consulta relacionada con el premio, el ganador puede contactar a la organización a través del correo electrónico proporcionado en la notificación de confirmación del premio:</p>
                                          <p className="mb-1">Teléfono: 911 799 166</p>
                                          <p className="mb-4">Email: atencionalcliente@mibp.es</p>
                                          <p>*Para más información y condiciones, visita <a href="https://www.bepear.com" className="prize-coupon-terms-links">www.bepear.com</a></p>
                                      </div>
                                  </ol>
                          </Col>
                      </Row>
                  </Container>
              </>
            )
            
        }
        return (
            <>
                <Container fluid>
                    <Row style={{ marginTop: (printing) ? '-30px' : '0px'}}>
                        <Col>
                            <div className="prize-coupon-terms-green-title-box" style={{ marginBottom: (printing) ? '5px' : '20px' }}>
                                <span className="prize-coupon-terms-green-title-text"
                                      style={{ fontSize: (printing) ? '18px' : '28px', lineHeight: (printing) ? '25px' : '33px' }}>
                                    Cómo Canjear
                                </span>
                            </div>

                            <p>Para canjear este cupón sigue los siguientes pasos:</p>

                            <ol>
                                <li>Descarga tu cupón o hazle un pantallazo.</li>
                                <li>Registra tu tarjeta miBP en la app miBP o en la web <a href="https://www.mibp.es" className="prize-coupon-terms-links">www.mibp.es</a> o bien en la web Plan DinoBP</li>
                                <li>Visita tu Estación de Servicio bp de preferencia y reposta</li>
                                <li>Muestra tu tarjeta miBP o Plan DinoBP y el  código de barras que aparece en este cupón en el momento de pago para que el empleado de caja lo pueda escanear.</li>
                                <li>El dinero se cargará en tu ahorro miBP para que puedas utilizarlo en tus repostajes</li>
                            </ol>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <div className="prize-coupon-terms-green-title-box" style={{ marginBottom: (printing) ? '5px' : '20px' }}>
                                <span className="prize-coupon-terms-green-title-text"
                                      style={{ fontSize: (printing) ? '18px' : '28px', lineHeight: (printing) ? '25px' : '33px' }}>
                                    Términos y condiciones
                                </span>
                            </div>
                            {
                                (printing)
                                    ? (
                                        <>
                                            <br />
                                            <span>Para poder canjear tu cupón necesitarás estar registrado. Puedes hacerlo desde el botón “Registrarme/Iniciar sesión” de la parte superior de la web https://www.mibp.es o desde la app miBP o en la web de Plan DinoBP (<a href="https://www.plandinobp.es/es/inicio" className="prize-coupon-terms-links">https://www.plandinobp.es/es/inicio</a>) </span>
                                            <span>Si repostas carburantes bp Ultimate con tecnología ACTIVE, podrás canjear el 100% de tu cupón carburante. </span>
                                            <span>Si repostas carburantes bp con tecnología ACTIVE, podrás canjear hasta el 20% del importe de la transacción. Es decir, si el importe de la transacción es de 25€, podrás redimir hasta 5€ en ahorro miBP en esa misma transacción. </span>
                                            <span>La validez de este cupón carburante es de 1 año desde su emisión. </span>
                                            <span>*Para más información y condiciones, visita <a href="https://www.bepear.com" className="prize-coupon-terms-links">www.bepear.com</a></span>
                                        </>
                                    )
                                    : (
                                       <>
                                            <p>Para poder canjear tu cupón necesitarás estar registrado. Puedes hacerlo desde el botón “Registrarme/Iniciar sesión” de la parte superior de la web https://www.mibp.es o desde la app miBP o en la web de Plan DinoBP (<a href="https://www.plandinobp.es/es/inicio" className="prize-coupon-terms-links">https://www.plandinobp.es/es/inicio</a>)</p>
                                            <p>Si repostas carburantes bp Ultimate con tecnología ACTIVE, podrás canjear el 100% de tu cupón carburante.</p>
                                            <p>Si repostas carburantes bp con tecnología ACTIVE, podrás canjear hasta el 20% del importe de la transacción. Es decir, si el importe de la transacción es de 25€, podrás redimir hasta 5€ en ahorro miBP en esa misma transacción.</p>
                                            <p>La validez de este cupón carburante es de 1 año desde su emisión.</p>
                                            <p>*Para más información y condiciones, visita <a href="https://www.bepear.com" className="prize-coupon-terms-links">www.bepear.com</a></p>
                                       </> 
                                    )
                            }
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    return (<></>)
}
