import { createSlice } from "@reduxjs/toolkit";


export const momentsSlice = createSlice({
  name: 'moments',
  initialState: {
    momentsSelectValue: '',
    momentsSearchInputValue: '',
    momentsDateBeforeValue: '',
    momentsDateAfterValue: ''
  },
  reducers: {
    setMomentsSelectValue: (state, action) => {
      state.momentsSelectValue = action.payload
    },
    setMomentsSearchInputValue: (state, action) => {
      state.momentsSearchInputValue = action.payload
    },
    setMomentsDateBeforeValue: (state, action) => {
      state.momentsDateBeforeValue = action.payload
    },
    setMomentsDateAfterValue: (state, action) => {
      state.momentsDateAfterValue = action.payload
    },
    resetMomentsValues: (state) => {
      state.momentsSelectValue = '';
      state.momentsSearchInputValue = '';
      state.momentsDateBeforeValue = '';
      state.momentsDateAfterValue = '';
    }
  }
});

export const { setMomentsSelectValue, setMomentsSearchInputValue, 
  setMomentsDateBeforeValue, setMomentsDateAfterValue, resetMomentsValues } = momentsSlice.actions;