import { createContext, useEffect, useReducer, useState } from "react"
import { GaReducer } from "./gaReducer"
import { CookieDataLayer, CookieDataLayerArray, CookieEventVirtualPage, DataLayerArray, DataLayerObj } from "../hooks/useGa"

export const initialState = []

type GaContextProps = {
    gaState : any[]
    pushInitialDataLayer: (initialDataLayer: DataLayerObj) => void; 
    addEventDataLayer: (eventDataLayer: DataLayerArray | CookieDataLayerArray | CookieDataLayer) => void; 
    addEventVirtualPageDataLayer: (eventDataLayer: CookieEventVirtualPage) => void; 
}

export const GACookiesContext = createContext({} as GaContextProps) 

export const GaProvider = ({ children } : any) => {

    const [state, dispatch] = useReducer(GaReducer, initialState)

    useEffect(() => {
         // @ts-ignore
        window.dataLayer = [];
    }, []);

    const pushInitialDataLayer =  ( initialDataLayer: DataLayerObj) => {
        dispatch({ type: 'initialPageDataLayer', payload: initialDataLayer });
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(initialDataLayer);
    }
    const addEventDataLayer = ( eventDataLayer: DataLayerArray | CookieDataLayerArray | CookieDataLayer) => {
        dispatch({ type: 'addEventDataLayer', payload: eventDataLayer })
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(eventDataLayer);
    }
    const addEventVirtualPageDataLayer = ( eventDataLayer: CookieEventVirtualPage) => {
        dispatch({ type: 'addEventVirtualPageDataLayer', payload: eventDataLayer })
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(eventDataLayer);
    }

  return (
    <GACookiesContext.Provider value={{
        gaState: state,
        pushInitialDataLayer,
        addEventDataLayer,
        addEventVirtualPageDataLayer
    }}>
        { children }
    </GACookiesContext.Provider>
  )
}

