import { useGa } from '../../hooks';
import './FooterMobile.css';
import { showPreferences } from 'vanilla-cookieconsent';

interface Props {
  result ?: string
}


export const FooterMobile = ({ result } : Props) => {

  const openCookiePopUp = () => showPreferences();
  const appVersion = (process.env.REACT_APP_VERSION) && process.env.REACT_APP_VERSION.toString();
  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();

  const { eventDataLayer } = useGa();

  return (
    <>
      {
        (result != undefined)
        ? (<div className="footer-mobile">
          <span className="spacer" />
          <p><a href="/cookies" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_politica de cookies')}>Política de Cookies</a></p>
          <p><a type='button' onClick={openCookiePopUp} className='pointer-link'>Configurar Cookies</a></p>
          <p><a href="/privacidad" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_declaracion de privacidad')}>Declaración de Privacidad</a></p>
          <p><a href="/condiciones" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_terminos y condiciones')}>Términos y Condiciones</a></p>
          <p><a href="https://https://www.bp.com/es_es/spain/home.html" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_bp españa')} rel="noreferrer">BP España</a></p>
          <p><a href="https://mibp.es/es/app-mi-bp" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_mibp.es')} rel="noreferrer">miBP.es</a></p>
          <p className='social-links-mobile'>
            <a href="https://https://www.instagram.com/bp_espana/" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'rrss_instagram')} rel="noreferrer">Instagram</a>
            <img src={`${appURL}/images/instagram.png`} alt="instagram" className='social-logo-mobile' />
            <span className="spacer">|</span>
            <a href="https://https://www.facebook.com/bpenEspana" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'rrss_facebook')} rel="noreferrer">Facebook</a>
            <img src={`${appURL}/images/instagram.png`} alt="instagram" className='social-logo-mobile' />
          </p>
          <div className="version">
            <p>Version: { appVersion }</p>
          </div>
        </div>)
        : (<div className="footer-mobile">
          <span className="spacer" />
          <p><a href="/cookies" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_politica de cookies')}>Política de Cookies</a></p>
          <p><a type='button' onClick={openCookiePopUp} className='pointer-link'>Configurar Cookies</a></p>
          <p><a href="/privacidad" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_declaracion de privacidad')}>Declaración de Privacidad</a></p>
          <p><a href="/condiciones" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_terminos y condiciones')}>Términos y Condiciones</a></p>
          <p><a href="https://https://www.bp.com/es_es/spain/home.html" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_bp españa')} rel="noreferrer">BP España</a></p>
          <p><a href="https://mibp.es/es/app-mi-bp" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_mibp.es')} rel="noreferrer">miBP.es</a></p>
          <p className='social-links-mobile'>
            <a href="https://https://www.instagram.com/bp_espana/" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'rrss_instagram')} rel="noreferrer">Instagram</a>
            <img src={`${appURL}/images/instagram.png`} alt="instagram" className='social-logo-mobile' />
            <span className="spacer">|</span>
            <a href="https://https://www.facebook.com/bpenEspana" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'rrss_facebook')} rel="noreferrer">Facebook</a>
            <img src={`${appURL}/images/instagram.png`} alt="instagram" className='social-logo-mobile' />
          </p>
          <div className="version">
            <p>Version: { appVersion }</p>
          </div>
        </div>)
      }
      
    </>
  )
}