import apiBuilder from "../api/apiBuilder"
import { MomentEntity } from "../models";
import { downloadCSV } from "../utils";


const baseURL = apiBuilder;

export const getAllMoments = async(filters: string, offset: number): Promise<MomentEntity | null> => {
  try {
    const { data } = await baseURL.get(`/moments?limit=30&offset=${offset}${filters}`);
    return data as Promise<MomentEntity>;
  }
  catch (error: any) {
    console.error('ERROR GETTING MOMENTS ', error);
    return null;
  }
}

export const exportMoments = async (filters: string) => {
  try {
    const { data } = await baseURL.post(`/moments/download?${filters}`);
    downloadCSV(data, 'moments');
  }
  catch (error: any) {
    console.log(error)
  }
}