import { useGa } from '../../hooks';
import './MainBannerMobile.css'
import { Col, Container, Row } from 'react-bootstrap';

export const MainBannerMobile = () => {

  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();
  const { eventDataLayer } = useGa();
  
  return (
    <Container fluid className='main-banner-container-mobile'>
      <Row className='left-container-mobile'>
        <Col>
          <h3 className='banner-text-mobile'>Canjear un código en esta web y ganar un viaje a Abu Dabi es de ser muy</h3>
        </Col>
        <Col>
        <img src={`${appURL}/images/bip.png`} alt="BP Important Person" className='bip-image-mobile' />
        </Col>
        <Col className='button-col-mobile'>
          <a href="#voucher-form-mobile">
            <button className='code-button-mobile' onClick={() => eventDataLayer('navigation', window.location.pathname, 'clic_boton', 'boton_canjea tu premio aqui')}>Canjea tu código <strong>aquí</strong></button>
          </a>
        </Col>
        <Col>
        <img src={`${appURL}/images/car.png`} alt="Alpine F1 car" className='main-car-image-mobile' />
        </Col>
        <Col>
        <img src={`${appURL}/images/bp-alpine-box.png`} alt="Bp's Partner Alpine" className='main-bp-partner-image-mobile' />
        </Col>
      </Row>
    </Container>
  )
}