import { useContext, useState } from 'react'
import { GACookiesContext } from '../context/GaContext'

export interface DataLayerArray {
    event: string,
    hierarchy: string,
    content_type: string,
    item_id: string
}

export interface CookieDataLayerArray {
    event: string;
    cookie_groups: string[];
}

export interface CookieEventVirtualPage {
    event: string
    pageUrl : string
    pageName : string,
    pageType : string,
    pageCategory : string, 
    pageContent: string,
    pageHierarchy: string
}


export interface CookieDataLayer {
    event: string;
    cookie_groups: string;
}

export interface DataLayerObj {
    pageUrl : string
    pageName : string,
    pageType : string,
    pageCategory : string, 
    pageContent: string,
    pageHierarchy: string
}

export const useGa = () => {

    const GA_COOKIE = '_ga';
    const { pushInitialDataLayer, addEventDataLayer, gaState, addEventVirtualPageDataLayer} =  useContext(GACookiesContext)


    const getPageType = (page: string) : string => {
        if (page.includes('check-prize')) {
            return 'concurso';
        } else {
            return 'home';
        }
    }

    const getPageCategory = (category: string) : string => {
        if (category.includes('check-prize')) {
            return category.replace("/", "");
        } else {
            return 'home';
        }
    }

    const getPageContent = (page: string, result : string) : string => {
        if (page.includes('check-prize')) {
            switch (result) {
                case 'no ganador':
                    return "no ganador";
                case 'ganador viaje':
                    return "ganador viaje";
                case 'cupon 5':
                    return "ganador cupon 5";
                case 'cupon 15':
                    return "ganador cupon 15";
                case 'cupon 50':
                    return "ganador cupon 50";
                case 'cupon 100':
                    return "ganador cupon 100";
                default:
                    return "no ganador";
            }
        } else {
            return "";
        }
    }

    const isAnaliticCookieActive = () : boolean => {
        //@ts-ignore
        if (window.dataLayer) {
            //@ts-ignore
            const datalayers = window.dataLayer
            for (var i = 0; i < datalayers.length; i++) {
                // ** Las cookies de analistics son las de C02 
              if (datalayers[i].event === 'CMPUpdated' && datalayers[i].cookie_groups.includes("C02")) {
                    return true
              }
            }
            return false
        }
        return false
    }
    const isExternalCookieActive = () : boolean => {
        //@ts-ignore
        if (window.dataLayer) {
            //@ts-ignore
            const datalayers = window.dataLayer
            for (var i = 0; i < datalayers.length; i++) {
                // ** Las cookies de terceros son las de C03 
              if (datalayers[i].event === 'CMPUpdated' && datalayers[i].cookie_groups.includes("C03")) {
                    return true
              }
            }
            return false
        }
        return false
    }

    const isAnaliticsActive = () : boolean => {
        return process.env.REACT_APP_SEND_ANALITICS === "1";   
    }


    const eventDataLayer = async (event : string,  page: string, content_type : string, item_id : string) => {
        if(isAnaliticsActive()){
            const obj : DataLayerArray = {
                event,
                hierarchy: getPageCategory(page),
                content_type,
                item_id
            }
            addEventDataLayer(obj)
        }
    }
    
    const cookieEventDataLayer = async (event: string,  cookies: string) => {
        if(isAnaliticsActive()){
            const obj : CookieDataLayer = {
                event,
                cookie_groups: cookies
            } 
            addEventDataLayer(obj)
        }
    }
    const virtualPageEventDataLayer = async (event: string, url : string, pageName : string, result : string) => {
        if(isAnaliticsActive()){
            const obj : CookieEventVirtualPage = {
                event,
                'pageUrl': url,
                'pageName': pageName.replace("/", ""),
                'pageType': getPageType(pageName),
                'pageCategory': getPageCategory(pageName),
                'pageContent': getPageContent(pageName, result),
                'pageHierarchy': (result.length == 0) ? getPageCategory(pageName) : getPageCategory(pageName) + "|" + getPageContent(pageName, result)
            } 
            addEventVirtualPageDataLayer(obj)
        }
    }

    const initialDataLayer = ( url : string, pageName : string, result : string) => {
        if(isAnaliticsActive()){
            const obj : DataLayerObj = {
                'pageUrl': url,
                'pageName': pageName.replace("/", ""),
                'pageType': getPageType(pageName),
                'pageCategory': getPageCategory(pageName),
                'pageContent': getPageContent(pageName, result),
                'pageHierarchy': (result.length == 0) ? getPageCategory(pageName) : getPageCategory(pageName) + "|" + getPageContent(pageName, result)
            }
            pushInitialDataLayer(obj)
        }
    }
    

  return {
    /* Elements */
    gaState,


    /* Functions */
    isExternalCookieActive, 
    initialDataLayer,
    eventDataLayer,
    cookieEventDataLayer, 
    virtualPageEventDataLayer
  }
}
