import { Navigate, Route, Routes } from "react-router-dom"
import { AdminLogin, Codes, FAQ, HomePage, CookiePage, LegalBases, Moments, PrivacyDeclarations, PrizeCheckPage, Prizes } from "../pages"
import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"


export const AppRouter = () => {

  const { authState } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<HomePage /> }/>
      <Route path="/check-prize" element={<PrizeCheckPage /> }/>
      <Route path="/condiciones" element={<LegalBases /> }/>
      <Route path="/privacidad" element={<PrivacyDeclarations /> }/>
      <Route path="/preguntas-frecuentes" element={<FAQ /> }/>
      <Route path="/cookies" element={<CookiePage /> }/>
      {
        (authState.status === 'authenticated')
        ? (
          <>
              <Route path="/lib" element={<Navigate to="/" /> }/>
              <Route path="/admin-17827bh671n7" element={<Codes /> }/>
              <Route path="/admin-17827bh671n7/*" element={<Codes /> }/>
              <Route path="/admin-17827bh671n7/codes" element={<Codes /> }/>
              {
                (authState.user?.role === 'admin') &&
                  <>
                    <Route path="/admin-17827bh671n7/moments" element={<Moments /> }/>
                    <Route path="/admin-17827bh671n7/prizes" element={<Prizes /> }/>
                  </>
              }
            </>
          )
          : (
            <>
              <Route path="/admin-17827bh671n7" element={<AdminLogin /> }/>
              <Route path="/admin-17827bh671n7/*" element={<AdminLogin /> }/>
            </>
          )
      }
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
