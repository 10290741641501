import apiBuilder from "../api/apiBuilder";
import { PrizeEntity } from "../models";
import { downloadCSV } from "../utils";



const baseURL = apiBuilder;

export const getPrizesValue = async (filters: string, offset: number): Promise<PrizeEntity | null> => {
  try {
    const { data } = await baseURL.get(`/prizes?limit=30&offset=${offset}${filters}`);
    return data as Promise<PrizeEntity>;
  }
  catch (error: any) {
    console.error('ERROR GETTING PRIZES ', error);
    return null; //Hacer un tipo para la respuesta
  }
}

export const exportPrizes = async (filters: string) => {
  try {
    const { data } = await baseURL.post(`/prizes/download?${filters}`);
    downloadCSV(data, 'prizes');
  }
  catch (error: any) {
    console.log(error)
  }
}