import './FormBannerMobile.css';
import { Container, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getVoucherPrizeValue, goPage } from '../../services';
import { AuthContext } from '../../context/AuthContext';
import { useGa } from '../../hooks';
import { HttpStatusCode } from 'axios';
import { CheckVoucherResponse } from '../../models';


export const FormBannerMobile = () => {

  const navigate = useNavigate();

  const { setIsLoading, setToken, authState: { isLoading, token }} = useContext(AuthContext);
  const { eventDataLayer } = useGa();

  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [code, setCode] = useState("");
  const [voucherError, setVoucherError] = useState("");

  const [retry, setRetry] = useState(false);

  const [formValid, setFormValid] = useState(false);


  const handleRecaptchaChange = (value : any) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (event: any) => {
   event.preventDefault();
    setVoucherError('');
    setIsLoading();

    if (code.length !== 10) {
      eventDataLayer('validacion_codigo', window.location.pathname, 'submit_boton', 'valida tu codigo_error_codigo erroneo');
      setTimeout(() => {
        setFormValid(false);
        setVoucherError("Código no válido");
        setIsLoading();
      }, 1500);
      return;
    }

    try {
      const timeoutPromise = new Promise<Response>((_, reject) =>
        setTimeout(() => reject(new Error("Request timed out")), 60000)
      );

      const response = await Promise.race([getVoucherPrizeValue(code), timeoutPromise]) as CheckVoucherResponse;
      if(response?.statusCode === HttpStatusCode.Ok || (response.statusCode === HttpStatusCode.BadRequest && response.type === "Not Winner")) {
        eventDataLayer('validacion_codigo', window.location.pathname, 'submit_boton', 'valida tu codigo_success');
        goPage({ response, navigate });
      }else{
        if ((response?.statusCode === HttpStatusCode.Unauthorized || response?.statusCode === HttpStatusCode.Forbidden) && !retry) {
            setRetry(true);
            const hasToken = await setToken();
            if (hasToken) {
              setIsLoading();
              await handleSubmit(event);
              return;
            } else {
              setVoucherError("Ha ocurrido un error, por favor refresque la página y vuelva a intentarlo");
            }
            setIsLoading();
            return;
          }

          if (response?.statusCode === HttpStatusCode.InternalServerError) {
            setVoucherError("Hubo un error inesperado, por favor inténtelo de nuevo");
            setIsLoading();
            return;
          }

          if (response?.error) {
              setVoucherError(response.message);
              // SEND GA FAILED EVENT
              if (response.type === "Invalid Code") {
                eventDataLayer('validacion_codigo', window.location.pathname, 'submit_boton', 'valida tu codigo_error_codigo erroneo');
              }
              if (response.type === "Code Already Used")  {
                eventDataLayer('validacion_codigo', window.location.pathname, 'submit_boton', 'valida tu codigo_error_codigo utilizado');
              }
          }
      }
    }
    catch (error: any) {
      setVoucherError("Hubo un error inesperado, por favor inténtelo de nuevo");
    }
    setIsLoading();
  };

  useEffect(() => {
    if(code.length > 0 && isChecked && recaptchaValue){
      setFormValid(true);
    }
  }, [code, isChecked, recaptchaValue]);

  const SITE_KEY = process.env.REACT_APP_SITE_KEY || '';

  return (
    <div className="form-banner-main-container-mobile">
      <Container fluid className="form-banner-main-fix-size">
      <div className="form-title-mobile">
        <div>
          <h3>Ha llegado el momento</h3>
        </div>
      </div>
      <div className="form-subtitle-mobile">
        <div>
          <h3>de descubrir qué premio has ganado</h3>
        </div>
      </div>
      <div className="form-mobile-container-box">
        <div className='form-container-mobile' id="voucher-form-mobile" >
          <form onSubmit={handleSubmit}>
            {
              (voucherError)
                ? (
                  <>
                    <input type='text' id='voucherCode' className='voucher-input-error' placeholder='Introduce tu código aquí' onChange={(event) => setCode(event.target.value)} value={code} />
                    <small style={{ textAlign: 'center', color: 'red', marginLeft: '15px'}}>{voucherError}</small>
                  </>
                )
                : (
                  <input type='text' id='voucherCode' className='voucher-input' placeholder='Introduce tu código aquí' onChange={(event) => setCode(event.target.value)} value={code} />
                )
            }
            <div className="checkbox-container-mobile my-4">
              <input type="checkbox" id="acceptTerms" className='terms-checkbox-mobile' name="checkboxform" checked={isChecked} onChange={(e ) => setIsChecked(e.target.checked)} />
              <label htmlFor="acceptTerms">Acepto todos los <a className='form-link' href="/condiciones" target='_blank'>Términos y Condiciones</a></label>
            </div>
            <div className="captcha-mobile">
              <ReCAPTCHA sitekey={SITE_KEY} onChange={handleRecaptchaChange} />
            </div>
            {
              isLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Spinner animation='border' role='status' style={{ marginTop: '15px', color: '#009640' }}></Spinner>
                </div>
              ) : (
                formValid
                  ? <button type='submit'className='form-submit-button'>Valida tu código</button>
                  : <button type='submit'className='form-submit-button-disabled' disabled>Valida tu código</button>
              )
            }
            <p className='privacy-text-mobile'>En nuestra <a className='form-link' href="/privacidad" target='_blank'>declaración de privacidad</a> proporcionamos información sobre cómo procesamos tus datos personales.</p>
          </form>
        </div>
      </div>
    </Container>
    </div>
  )
}