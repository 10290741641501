import { SliderImageContainer } from '../ui/SliderImageContainer';
import './SliderBanner.css';
import { Col, Container, Row } from "react-bootstrap";



export const SliderBanner = () => {

  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();

  return (
    <Container fluid className="main-container">
      <Row xl={12} className='top-text-container'>
        <Col>
          <h3>Poder ganar todos estos premios es de ser muy BIP</h3>
        </Col>
      </Row>
      <Row className='secondary-top-text-container'>
        <Col>
          <h6>Puedes participar hasta el 2 de septiembre de 2024</h6>
        </Col>
      </Row>
      <div className="image-slider-container">
        <div className='image-slider-fix-width'>
          <div className="image-slider-center-image">
          <img src={`${appURL}/images/slider-image-1.png`}className='imagen-test' /> 
          </div>
          <h5 className="image-slider-title">3 EXPERIENCIAS V.I.P</h5>
          <p className="image-slider-subtitle">en Abu Dabi para 4 personas cada una, valoradas en 16.000€, para vivir la gran final del motor.</p>
        </div>
        <div className='image-slider-fix-width'>
          <div className="image-slider-center-image">
          <img src={`${appURL}/images/slider-image-2.png`} className='imagen-test' /> 
          </div>
          <h5 className="image-slider-title">4.500 CUPONES DE CARBURANTE</h5>
        </div>
        <div className='image-slider-fix-width'>
          <div className="image-slider-center-image">
          <img src={`${appURL}/images/slider-image-3.png`} className='imagen-test' /> 
          </div>
          <h5 className="image-slider-title">UN SORTEO INCREÍBLE</h5>
          <p className="image-slider-subtitle">para vivir una experiencia motera V.I.P en Valencia.</p>
        </div>
      </div>
    </Container>
  )
}