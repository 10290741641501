import './AdminLogin.css';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const AdminLogin = () => {

  const appURL = process.env.REACT_APP_URL && process.env.REACT_APP_URL.toString();

  const emailRegex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \\t]|(\\[\\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\\t -Z^-~]*])"
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState(true);

  const [emailError, setEmailError] = useState('');
  const [formValid, setFormValid] = useState(true);
  const [loginError, setLoginError] = useState(false);

  const { login, setIsLoading, authState: { isLoading }} = useContext(AuthContext);

  useEffect(() => {
    const isValidEmail = emailRegex.test(email) && email.length > 0;
    setFormValid(isValidEmail);
    if (emailRegex.test(email) || email.length == 0) {
      setEmailError('');
    } else {
      setEmailError('Email no válido');
    }
  }, [email, password, formValid]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading();
    if (formValid) {
      if (!await login({ email, password })) {
        setLoginError(true);
        setTimeout(() => {
          setLoginError(false);
        }, 7500);
      }
    }
    setIsLoading();
  };

  return (
    <Container
      fluid
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#f3f4f6',
      }}
    >
      {
        (loginError) && 
          <Alert variant='danger' className='mb-5'>
            Usuario y/o contraseña incorrectos, por favor inténtelo de nuevo
          </Alert>
      }
      <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Col className="login-form-container" style={{ backgroundColor: '#ffffff' }}>
          <img src={`${appURL}/images/bp-logo.png`} alt="Logo de BP" className="main-login-logo" />
          <form onSubmit={handleSubmit}>
            <small style={{ textAlign: 'center', color: 'red', marginLeft: '15px' }}>{emailError}</small>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control login-form-input mb-3"
              placeholder="Inserte su email aquí"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="input-group">
              <input
                type={`${type ? 'password' : 'text'}`}
                id="password"
                name="password"
                className="form-control login-form-input"
                placeholder="Inserte su contraseña aquí"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <div className="input-group-append">
                <button
                  style={{
                    padding: '10px 20px',
                    height: '50px',
                    borderEndEndRadius: '70px',
                    borderStartEndRadius: '70px',
                  }}
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => setType(!type)}
                >
                  {type ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </div>
            {
              (isLoading)
                ? (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner animation="border" role="status" style={{ marginTop: '15px', color: '#009640' }}></Spinner>
                  </div>
                )
                : (
                  <button
                    type="submit"
                    className={formValid ? 'btn-login-submit' : 'btn-login-submit-disabled'}
                    disabled={!formValid}
                  >
                    Entrar
                  </button>
                )
            }
          </form>
        </Col>
      </Row>
    </Container>
  );
};
