import { createContext, useEffect, useReducer } from "react";
import { AuthState, LoginForm } from "../models";
import { authReducer } from "./authReducer";
import { getOAuthToken, getUserProfile } from '../services/AuthService';
import { getDecryptedItem, JWT_TOKEN_EXPIRATION_TIME, setEncryptedItem } from "../utils";
import { IpService } from "../services/IpService";


export const authInitialState: AuthState = {
  status: 'not-authenticated',
  user: null,
  ip: null,
  token: null,
  isLoading: false
}

type AuthContextProps = {
  authState: AuthState;
  setToken: () => Promise<boolean>;
  login: (loginForm: LoginForm) => Promise<boolean>;
  logout: () => void;
  setIsLoading: () => void;
}

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: any) => {

  useEffect(() => {
    setIp();
  }, []);

  useEffect(() => {
    hasAlreadyAuthenticated().then(
      (value) => {
        if (!value) {
          setToken();
        }
      }
    )
  }, []);

  const [state, dispatch] = useReducer(authReducer, authInitialState);

  useEffect(() => {
    if (state.token) {
      const timeout = setTimeout(() => {
        refreshToken();
      }, JWT_TOKEN_EXPIRATION_TIME - 60000);


      return () => clearTimeout(timeout);
    }
  }, [state.token]);

  const setIsLoading = () => dispatch({ type: 'setIsLoading' });

  const setToken = async () => {
    const token = await getOAuthToken();
    if (token) {
      dispatch({ type: 'setToken', payload: { token } });
      return true;
    }
    else {
      return false;
    }
  };

  const setIp = async () => {
    const clientData = await IpService.publicIPs()

    if (clientData?.[0]) {
      const ip = clientData?.[0];
      dispatch({ type: 'setIp', payload: { ip } });
    }
  };

  const refreshToken = async () => {
    const email = await getDecryptedItem('u-einf-18278912h712');
    const password = await getDecryptedItem('u-pinf-18278912h712');
    if (email && password) {
      const token = await getOAuthToken(email, password);
      dispatch({ type: 'setToken', payload: { token } });
    }
    else {
      const token = await getOAuthToken();
      dispatch({ type: 'setToken', payload: { token } });
    }
  }

  const logout = async () => {
    dispatch({ type: 'logOut' });
    sessionStorage.removeItem('u-einf-18278912h712');
    sessionStorage.removeItem('u-pinf-18278912h712');
    sessionStorage.removeItem('u-rinf-18278912h712');
    sessionStorage.removeItem('alreadyAuthenticated');
  };

  const login = async ({ email, password }: LoginForm) => {
    const token = await getOAuthToken(email, password);
    if (token) {
      dispatch({ type: 'logIn', payload: { token } });
      setTimeout(async() => {
        const role = await getUserProfile() as string;
        dispatch({ type: 'setUserInfo', payload: { email, password, role } })
        sessionStorage.setItem('alreadyAuthenticated', 'true');
        await setEncryptedItem('u-einf-18278912h712', email);
        await setEncryptedItem('u-pinf-18278912h712', password);
        await setEncryptedItem('u-rinf-18278912h712', role);
      }, 200); // SOLUCION TEMPORAL: es necesario esperar para el dispatch a que setee el nuevo token antes de realizar la peticion
      return true;
    }
    return false;
  };

  const hasAlreadyAuthenticated = async(): Promise<boolean> => {
    const alreadyAuth = sessionStorage.getItem('alreadyAuthenticated');
    if (alreadyAuth && alreadyAuth === 'true') {
      const email = await getDecryptedItem('u-einf-18278912h712');
      const password = await getDecryptedItem('u-pinf-18278912h712');
      if (email && password) {
        await login({ email, password });
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  return (
    <AuthContext.Provider value={{
      authState: state,
      setIsLoading,
      setToken,
      login,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  )

}