
import { Container } from 'react-bootstrap';
import { Footer, FooterMobile, NavbarHeader } from '../../components'
import { useDeviceType } from '../../hooks';
import './LegalBases.css'


export const LegalBases = () => {

  const isMobile = useDeviceType();

  return (
    <>
    <NavbarHeader />
    <div className='static-page-container'>
       {
        (isMobile)
        ? (<Container fluid className="static-fix-size">
        <h1 className='static-page-title'>Bases Legales</h1>
        <p className="static-page-text">Las presentes bases corresponden a la promoción “BP Ultimate Experience”, llevada a cabo por la mercantil bp ENERGÍA ESPAÑA, S.A.U., con domicilio a efectos de notificaciones en C/ Quintanadueñas, 6 (edificio Arqbórea) 2ª planta - 28050 Madrid, y CIF A-28135846 (en adelante bp), en las que se recogen las condiciones de la promoción y participación en la misma. Las bases de este sorteo se encuentran a disposición de los participantes a través de la página <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a></p>
        <p className="static-page-text">CHEQUE DEJEUNER ESPAÑA SA, sociedad domiciliada en Juan Ignacio Luca de Tena 14 4ª Planta, 28027 Madrid (España) (en adelante, el “Promotor”), con CIF A78887049, es la sociedad encargada únicamente de la gestión del sorteo (en adelante, el “Sorteo”).</p>

        <div className='static-page-content'>
            <ol className='static-page-ordered-list static-page-main-list'>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>Objeto:</li>
                <p className="static-page-text">BP organiza una acción promocional a nivel nacional y Andorra donde los participantes conseguirán códigos promocionales tras haber realizado compras de BP y BP Ultimate para registrarlos en la web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> y conocer si su código está premiado en uno de los momentos ganadores. Asimismo, todos los códigos registrados podrán participar en el sorteo final que más adelante se detalla.</p>
              </div>

              <div className="static-page-content-box">
                  <li className='static-page-list-title'>Período de participación</li>
                  <p className="static-page-text">El período de participación para registrar el código promocional comprenderá desde el día 17 de junio de 2024 a las 21:00h, hora peninsular española hasta el día 2 de septiembre de 2024 a las 23:59h, hora peninsular española. </p>
                  <p className="static-page-text">Todos los códigos registrados durante este periodo podrán participar en el sorteo ante Notario que se celebrará durante el 16 de septiembre de 2024.</p>
              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Legitimación para participar</li>
                <p className="static-page-text">Podrán participar en la presente promoción exclusivamente los titulares de las tarjetas Mi BP /usuarios registrados Mi BP válidas (personas físicas, mayores de edad y con residencia fiscal en el territorio nacional o Andorra) o titulares de una tarjeta Plan DinoBP, en adelante los participantes, que realicen un consumo mínimo de 30 litros en las estaciones de servicio BP y que inserten el código obtenido en el ticket de compra de la estación de servicio en la página web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a>. Queda excluídos de la promoción los clientes con una tarjeta Routex.</p>

              </div>


              <div className="static-page-content-box">
                <li className='static-page-list-title'>Ámbito territorial</li>
                <p className="static-page-text">La presente promoción tendrá su desarrollo en todo el territorio nacional español y en Andorra. La participación es gratuita y no supone un incremento del precio de los productos y servicios ofrecidos por BP. </p>

              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Mecánica</li>
                <p className="static-page-text">Participarán en la presente promoción aquellos clientes de BP que durante el período de participación indicados en el punto 2 de las presentes Bases Legales:</p>

                <ol className='static-page-list-small-margin static-page-list-margin-bottom'>
                  <li className='static-page-list-text'>Ser titular de una tarjeta miBP.</li>
                  <li className='static-page-list-text'>Hayan obtenido un código en su ticket de compra en la estación de servicio para participar en la promoción tras haber repostado 30 litros o más de carburante BP. Si el repostaje fuera de BP Ultimate, recibirían 2 códigos únicos en el ticket de compra.</li>
                  <li className='static-page-list-text'>El participante que reúna los requisitos anteriores podrá acceder a la web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a>, aceptar las condiciones de las presentes bases legales y comprobar si su código está premiado con uno de los momentos ganadores diarios.</li>
                  <li className='static-page-list-text'>Será necesario clicar el botón “Comprobar Premio” que se encontrará en la página de inicio del servicio para verificar qué premio ha obtenido.</li>
                  <li className='static-page-list-text'>Serán válidas las diez primeras participaciones de cada cliente de forma diaria desde la misma IP. Las participaciones que excedan dicho número serán desestimadas como participación en el sorteo y en los momentos ganadores.</li>
                  <li className='static-page-list-text'>Todos los participantes que registren su código promocional obtenido en su ticket de compra en la web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a>, tendrán una participación en el sorteo final del 16 de septiembre.</li>
                </ol>

                <p className="static-page-text">La mecánica de Momento Ganador se basa en un programa informático, que realiza sorteos preestablecidos (un número de sorteos n, con una periodicidad determinada) de forma aleatoria, durante el período de vigencia de la Promoción. Los Momentos Ganadores se atribuyen a un momento en el tiempo concreto (hora, minuto y segundo).</p>

                <p className="static-page-text">Se repartirán los 4.503 premios de los momentos ganadores entre los 77 días del periodo de participación.</p>

                <p className="static-page-text">Si no coincidiese la intervención de un participante con el Momento Ganador diario establecido por el programa informático, el premio correspondiente a ese Momento pasará al primer Participante que ingrese en la Promoción, de manera que no quedarán premios desiertos.</p>

                <p className="static-page-text">El usuario descubrirá en el mismo momento de la participación si ha resultado ganador de alguno de los premios del Momento Ganador y podrá descargar un vale en formato PDF en el que se incluirán todos los detalles para el canje del premio del Momento Ganador.</p>

                <p className="static-page-text">BP se reserva el derecho de modificar el procedimiento aquí establecido.</p>

                <p className="static-page-text">Al finalizar la promoción, todos los participantes que hayan registrado su código de participación podrán acceder a participar en el Sorteo Final (reflejado en el siguiente punto de las presentes bases legales) a excepción de los participantes premiados con un viaje a Abu Dhabi en los Momentos Ganadores.</p>

              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Descripción de los premios
                  <ol className='static-page-submain-list'>
                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Los Momentos Ganadores diarios se componen de los siguientes regalos:</li>
                      <ol className='static-page-list-mid-margin'>
                        <li className='static-page-list-text'>100 bonos de carburante de 100€ a repartir durante el periodo promocional.</li>
                        <li className='static-page-list-text'>150 bonos de carburante de 50€ a repartir durante el periodo promocional.</li>
                        <li className='static-page-list-text'>750 bonos de carburante de 15€ a repartir durante el periodo promocional. </li>
                        <li className='static-page-list-text'>3.500 bonos de carburante de 5€ a repartir durante el periodo promocional.</li>
                        <li className='static-page-list-text'>3 experiencias para 4 personas a Abu Dhabi que incluye:
                          <ul className='static-page-list-small-margin'>
                            <li className='static-page-list-text'>Vuelos de ida y vuelta desde Madrid, desplazamientos y transfers en destino.</li>
                            <li className='static-page-list-text'>6 noches de alojamiento en hotel de 4 estrellas, incluido el desayuno.</li>
                            <li className='static-page-list-text'>Experiencia VIP con el BWT Alpine F1 ® Team que incluye:</li>
                            <ul className='static-page-list-small-margin'>
                              <li className="static-page-list-text">Una experiencia de 3 días con BWT Alpine F1® Team en Abu Dhabi, que incluye un paquete de hospitalidad para ti y tus tres acompañantes.</li>
                              <li className="static-page-list-text">Experiencia VIP que incluirá al menos una de las siguientes: Tour del garaje del BWT Alpine F1® Team; o un paquete de merchandising del BWT Alpine F1® Team para ti y tus acompañantes; y o una experiencia privada de encuentro con al menos uno de los pilotos del BWT Alpine F1® Team antes del evento.</li>
                            </ul>
                            <li className='static-page-list-text'>Seguro de viaje.</li>
                            <li className='static-page-list-text'>Guía durante la estancia en Abu Dhabi. </li>
                            <li className='static-page-list-text'>No se incluyen desplazamientos hasta el aeropuerto de Madrid-Barajas.</li>
                          </ul>
                        </li>
                      </ol>
                    </div>

                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Sorteo Final</li>
                      <p className="static-page-text">El Sorteo Final consistirá en un viaje para 4 personas a Valencia para acudir al MotoGP de Cheste 2024 (en adelante el “Premio”), del jueves 14 de noviembre al lunes 18 de noviembre, que incluye:</p>
                      <ul className='static-page-list-mid-margin static-page-list-margin-bottom'>
                        <li className="static-page-list-text">AVE de ida y vuelta desde Madrid.</li>
                        <li className="static-page-list-text">4 entradas de acceso al MotoGP de Cheste.</li>
                        <li className="static-page-list-text">4 noches de alojamiento en hotel de 4 estrellas en 2 habitaciones dobles.</li>
                        <li className="static-page-list-text">Desplazamiento de la estación de AVE al hotel. </li>
                        <li className="static-page-list-text">No se incluyen desplazamientos hasta la estación de tren de Madrid-Chamartín.</li>
                      </ul>

                      <p className="static-page-text">Bp se reserva el derecho de modificar los premios si por causas ajenas a bp, no hubiera disponibilidad. En caso de cambios en los premios, éstos se sustituirían por premios no dinerarios, de similares características y de importe igual o superior, a elección de bp.</p>

                    </div>

                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Elección de los ganadores</li>
                      <p className="static-page-text">El sorteo se realizará el día 16 de septiembre de 2024 ante notario. Se elegirá al azar <b>UN (1) ganador.</b> Asimismo, se extraerán <b>VEINTE (20) suplentes</b> que sustituyan al ganador, para el caso de no localización o no cumplimiento de los requisitos exigidos en las presentes bases, entre todos los participantes de esta promoción. En caso de que el número de participantes en reserva fuera insuficiente, BP se reserva el derecho de solicitar al Notario la extracción de nuevos ganadores suplentes.</p>

                      <p className="static-page-text">La entrega efectiva del premio quedará condicionada a que el ganador cumpla con las condiciones especificadas en las presentes bases. BP se reserva el derecho de dar de baja y expulsar automáticamente y sin previo aviso a cualquier participante que estime que no está cumpliendo con las condiciones de participación o que está haciendo un mal uso o abuso de la promoción.</p>

                    </div>

                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Comunicación de premios</li>
                      <ol className="static-page-submain-list">
                        <li className="static-page-list-text">
                          <p><strong>Premios momentos ganadores:</strong> los Participantes que hayan resultado premiados en los Momentos Ganadores con un viaje a Abu Dhabi, dispondrán de un plazo de siete (7) días naturales desde la notificación de su condición de ganador para ponerse en contacto con BP a través del medio de contacto indicado en el voucher premiado y manifestar por escrito su aceptación del premio obtenido, momento en el cual se le informará del procedimiento para formalizar el mismo.</p>
                          <p>Para el caso de que el premiado, no forme parte del programa miBP, no cumpliera con los requisitos de las presentes Bases Legales o no pudiera contactarse con él por no estar registrado en el programa miBP, los diez (10) siguientes participantes registrados que cumplan con los requisitos, tendrán la consideración de suplentes en función de su orden de participación y dispondrán de siete (7) días naturales desde la notificación de su condición de ganador para ponerse en contacto con BP y manifestar por escrito su aceptación del premio obtenido, momento en el cual se le informará del procedimiento para formalizar el mismo.</p>
                        </li>

                        <li className="static-page-list-text">
                          <p><strong>Premio sorteo final:</strong> Se seleccionará a un (1) ganador del Premio del Sorteo Final, así como a otros diez (10) usuarios que tendrán la consideración de suplentes en función de su orden de participación, para el caso de que el Premiado, o en su caso, cada uno de los suplentes, no esté registrado en el programa miBP, no cumpliera con los requisitos de las presentes Bases Legales, no pudiera contactarse con él en los siete (7) días naturales a la dirección de email del Premiado o no aceptara el premio.</p>
                          <p>El Premiado dispondrá de un plazo de siete (7) días naturales desde la notificación de su condición de Premiado para ponerse en contacto con BP y manifestar por escrito su aceptación del Premio obtenido, momento en el cual se le informará del procedimiento para formalizar la entrega del Premio.</p>
                        </li>
                      </ol>

                    </div>

                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Privación del Premio</li>
                      <p className="static-page-text">El Premiado del sorteo final, los premiados de los viajes a Abu Dhabi de los Momentos Ganadores, así como los participantes incluidos en la lista de suplencia, podrán ser revocados de su condición de Premiado y/o suplente a entera discreción del Organizador si se aprecia en ellos, ya sea a través de redes sociales o cualquier medio a disposición del Organizador (i) manifestaciones racistas, xenófobas, machistas o que atenten contra cualquier sexo, raza, religión o ideología (ii) manifestaciones discriminatorias o vejatorias ante cualquier persona, entidad u organización (iii) manifestaciones de exaltación o cualquier tipo de apoyo a regímenes autoritarios (iv) encontrarse en situación de investigación y/o condena sobre los temas señalados en los puntos anteriores.</p>

                      <p className="static-page-text">En caso de producirse privación del premio al ganador y/o suplentes, podrá ser adjudicado a los suplentes restantes durante el mismo periodo promocional, o bien, a entera discreción del Organizador si todos los suplentes están inmersos en las causas anteriores, en sucesivos periodos a futuros concursantes que cumplan los requisitos de las Bases Legales.</p>

                      <p className="static-page-text">Quedan excluidos y, por tanto, no podrán tener la condición de participantes ni resultar agraciados: (i) Los empleados del Promotor, Organizador y empresas del grupo  a  los que pertenecen respectivamente; (ii) Los empleados de las empresas asociadas, agencias de publicidad o agencias de promociones o terceras empresas que estén involucradas de cualquier forma en la organización  y desarrollo del Evento, (iii) Los familiares directos (padres, hermanos e hijos) de las personas incluidas en los puntos i y ii anteriores; y (iv) Los cónyuges de todas las personas incluidas en los puntos i, ii y iii anteriores.</p>

                    </div>

                    <div className="static-page-content-small-box">

                      <li className='static-page-list-subtitle'>Condiciones de los premios</li>
                      <ul className='static-page-list-mid-margin'>
                        <li className="static-page-list-text">Si por cualquier razón ajena a BP no hubiera disponibilidad del premio previsto, BP se reserva el derecho de sustituirlos por otros, de igual o superior coste.</li>
                        <li className="static-page-list-text">El premio no es canjeable en metálico, ni por cualquier otro premio y es intransferible. Queda prohibida la comercialización y/o venta del premio o del derecho a su obtención.</li>
                        <li className="static-page-list-text">BP no se responsabiliza del uso que del premio promocional realice el agraciado.</li>
                        <li className="static-page-list-text">BP no será responsable de los servicios que terceras empresas deban prestar con motivo del premio de la presente promoción, ni de ningún accidente, ni incidencia producida durante el disfrute del mismo.</li>
                        <li className="static-page-list-text">BP no será responsable de las posibles pérdidas, deterioros, robos, sustracciones, retrasos o cualquier otra acción imputable a terceros que puedan sufrir los premios durante el envío o entrega al ganador.</li>
                        <li className="static-page-list-text">BP se reserva el derecho a declarar el premio desierto en el caso de que se tengan motivos razonables para creer que un participante ha infringido cualquiera de esos términos y condiciones.</li>
                        <li className="static-page-list-text">Será condición indispensable para poder efectuar la entrega, que el agraciado presente y acredite mediante DNI que el nombre y los apellidos del registro ganador coincidan con la persona que recibe el premio.</li>
                        <li className="static-page-list-text">En el caso de que, por cualquier circunstancia, el premiado no pudiese o quisiese aceptar el premio, o renunciase al mismo, BP procedería a designar al siguiente ganador en reserva. En el caso de que los demás ganadores en reserva a su vez no aceptasen o renunciasen al premio, éste podrá ser declarado desierto.</li>

                      </ul>

                    </div>

                    <div className="static-page-content-small-box">

                    </div>
                    <li className='static-page-list-subtitle'>Fiscalidad de los premios</li>
                    <p className="static-page-text">Todos los  gastos  motivados por demora o negligencia por parte del ganador, serán de cuenta de éste, salvo que de otra forma, se hubiera establecido expresamente en las presentes bases. Serán de cuenta de la persona premiada todos los gastos referentes a mejoras y extras que no correspondan con el premio detallado en las presentes bases.</p>

                    <p className="static-page-text">De conformidad con la normativa del Impuesto sobre la Renta de las Personas Físicas (en adelante IRPF), los premios concedidos por la participación en juegos, concursos, rifas, o combinaciones aleatorias vinculadas a la venta o promoción de bienes o servicios están sujetos a retención o ingreso a cuenta siempre que el valor del premio sea superior a 300 Euros. En todo caso corresponderá a la entidad mercantil BP ENERGÍA ESPAÑA, S.A.U. la realización del ingreso a cuenta o la práctica de la retención procedente, la cual será asumida por esta entidad y realizada en nombre del premiado, debiendo éste último declarar la ganancia patrimonial correspondiente en su IRPF. Además, será necesario para realizar la entrega de cualquier premio con cuantía superior a 300 Euros, que el premiado firme la comunicación remitida por BP en la que conste el importe del premio y de la retención o ingreso a cuenta correspondiente y cualquier otra obligación que la Administración Tributaria imponga. La negativa a firmar dicho documento o a cumplimentar dichas obligaciones, supondrá la pérdida del premio para el premiado, quedando éste desierto.</p>

                    <p className="static-page-text">En el caso en que el premiado sea residente en Andorra, la entidad mercantil BP ENERGÍA ESPAÑA, S.A.U. procederá a la realización del ingreso a cuenta o la práctica de la retención procedente, a cual será asumida por esta entidad y realizada en nombre del premiado, debiendo éste último acreditar la ganancia patrimonial generada, conforme a la normativa aplicable en este país.</p>

                    <p className="static-page-text">A tal efecto, BP remitirá al premiado que haya aceptado el premio, la documentación tributaria correspondiente, con la obligación del premiado, de remitirla, debidamente cumplimentada, a BP, en un plazo de quince días. El incumplimiento de esta obligación determina la pérdida del derecho al premio.</p>

                    <p className="static-page-text">Para el caso de que la citada documentación no fuese entregada por circunstancias no imputables al premiado en el momento de la recogida del premio (de forma que no perdiese el derecho a premio), BP volverá a reenviarla al premiado, quién deberá remitir la misma dentro de los 15 días siguientes, debidamente cumplimentada.</p>

                    <p className="static-page-text">En todo caso, si dicha comunicación fuera recibida por BP en un período impositivo diferente al de la entrega del premio, y el premiado no hubiese perdido su derecho al mismo, la retención o ingreso a cuenta, se practicará en el periodo impositivo en que BP reciba la comunicación firmada, de forma que el premiado quedará obligado a declarar el premio obtenido en su declaración del IRPF de dicho ejercicio.</p>
                    </ol>
                  </li>

                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>Reserva de derechos</li>
                  <p className="static-page-text">BP se reserva el derecho de dar de baja a aquellos participantes que estén haciendo un mal uso de la promoción, realicen actos fraudulentos o que perjudiquen a otros participantes. En caso de que BP o cualquier entidad que esté ligada profesionalmente a esta promoción detecten cualquier anomalía o sospechen que un participante esté impidiendo el normal desarrollo de la promoción, o llevando a cabo cualesquiera actos fraudulentos que contravengan la transparencia de la promoción, BP se reserva el derecho de dar de baja e incluso de retirar el premio de forma automática y sin explicación de ningún tipo, a todos aquellos participantes que se hayan beneficiado de forma directa o indirecta de este tipo de actuaciones fraudulentas, pudiendo además ejercer todas las acciones civiles o penales que pudieran corresponder.
                  </p>

                  <p className="static-page-text">En este sentido, BP declara que ha habilitado los mecanismos y soportes tecnológicos idóneos para detectar cualquier posible actuación fraudulenta, anómala o dolosa que pretenda alterar la participación normal en la presente promoción.</p>

                  <p className="static-page-text">BP se reserva el derecho a seleccionar ganador alternativo en el caso de que se tengan motivos razonables de creer que un participante ha infringido cualquiera de esos términos y condiciones. En caso de que esta promoción no pudiera realizarse, bien por fraudes detectados, errores técnicos, o cualquier otro motivo grave, ajeno a su voluntad, que altere las circunstancias que motivaron la promoción, y que afecte al normal desarrollo de la misma.</p>
                  <p className="static-page-text">BP se reserva el derecho a cancelar, modificar, o suspenderla, sin que los participantes puedan exigir responsabilidad alguna al promotor. BP no se responsabiliza del retraso, deterioro o pérdida de la entrega del premio por cualquier causa no imputable directamente a aquel.</p>

                </div>

                <div className="static-page-content-box">

                <li className='static-page-list-title'>Política de privacidad</li>
                <p className="static-page-text">Los datos de carácter personal facilitados serán incorporados a un fichero responsabilidad bp con la finalidad de gestionar la promoción de conformidad con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales (LOPDGDD).</p>

                <p className="static-page-text">Con la participación en la presente promoción, los participantes prestan su consentimiento a que sus datos personales incluida la imagen, de resultar ganador, sean publicados por bp en sus diferentes canales de comunicación (página web, app Mibp, estaciones de servicio…) con la finalidad de gestionar la promoción e identificar a los ganadores: así como para ponernos en contacto con Ud. por vía telefónica o correo electrónico de ser necesario y para cumplir el mismo fin. En cualquier caso, la cesión de imágenes no conllevará retribución alguna.</p>

                <p className="static-page-text">Nos reservamos el derecho a divulgar su información personal como lo exige la ley, o cuando creamos que la divulgación es necesaria para proteger nuestros derechos y/o cumplir con los requisitos de un procedimiento judicial, mandato judicial, solicitud de un regulador o cualquier otro proceso legal que afecte a BP. En el momento en que BP esté sujeta a compra, liquidación o adquisición, podemos divulgar su información personal al nuevo propietario de la empresa.</p>

                <p className="static-page-text">No tomaremos decisiones automatizadas en base a dicho perfil en base a los datos proporcionados.</p>

                <p className="static-page-text">Los datos personales serán conservados durante no más tiempo del necesario para los fines del tratamiento, con un plazo máximo de 1 año.</p>

                <p className="static-page-text">Puesto que BP opera globalmente, sus datos serán accesibles/compartidos con otras compañías del grupo BP involucradas en este proceso comercial, con la única finalidad de la gestión del mismo. Cualquier transferencia interna de datos del grupo BP está regida y legitimada por las Reglas Corporativas Vinculantes de BP, garantizando un nivel adecuado de protección de datos a través del grupo BP.</p>

                <p className="static-page-text">LEl suministro de los datos por los participantes tiene carácter obligatorio, por lo que la negativa a suministrar los mismos supone la imposibilidad de participar en la presente promoción. </p>

                <p className="static-page-text">En cualquier momento, podrá modificar sus preferencias de comunicación, así como ejercer los siguientes derechos:</p>

                <ul className='static-page-list-mid-margin static-page-list-margin-bottom'>
                  <li className="static-page-list-text">Derecho de Acceso, podrá consultar sus datos personales.</li>
                  <li className="static-page-list-text">Derecho de Rectificación, podrá modificar sus datos personales cuando sean inexactos.</li>
                  <li className="static-page-list-text">Derecho de Supresión, podrá solicitar la eliminación de sus datos personales asociados al servicio.</li>
                  <li className="static-page-list-text">Derecho de Oposición, podrá solicitar que no se traten sus datos personales.</li>
                  <li className="static-page-list-text">Derecho de Limitación del tratamiento, podrá solicitar la limitación al tratamiento de sus datos.</li>
                  <li className="static-page-list-text">Derecho de Portabilidad, podrá recibir, en formato electrónico, los datos personales que haya facilitado, así como a transmitirlos a otra entidad.</li>
                  <li className="static-page-list-text">Derecho de Revocación del consentimiento, tendrá derecho a retirar el consentimiento otorgado para el tratamiento de los datos personales, en cualquier momento, y a través de cualquiera de los canales que se indican a continuación, sin que ello afecte a la licitud de los tratamientos realizados con carácter previo a su retirada.</li>
                </ul>

                  <p className="static-page-text">Podrá ejercer estos derechos mediante cualquier medio, que permita acreditar el envío y recepción de su solicitud que habrá de dirigirse a BP ENERGÍA España S.A., en C/ Quintanadueñas, 6 (edificio Arqbórea) 2ª planta - 28050 Madrid, o bien mediante correo electrónico dirigido a <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a> . Si está en la UE, podrá presentar reclamaciones ante la Autoridad de Protección de Datos del Estado miembro correspondiente.</p>

                  <p className="static-page-text">Para más información sobre nuestra Política de Privacidad: <a target="_blank" href='https://www.bp.com/es_es/spain/home/politica-privacidad.html' className='static-page-links' rel="noreferrer">https://www.bp.com/es_es/spain/home/politica-privacidad.html</a></p>


              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Legislación aplicable</li>
                <p className="static-page-text">La presente promoción se rige por la legislación común española vigente.</p>

              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Aceptación de las bases y Fuero.</li>
                <p className="static-page-text">La participación en la promoción supone la aceptación íntegra de las presentes bases. Se podrá pedir al ganador un documento escrito aceptando todos los términos y condiciones del concurso. Todos los conflictos que se susciten en aplicación de las presentes bases se resolverán por los Juzgados y Tribunales que por fuero corresponda.</p>

              </div>

            </ol>
            </div>
          </Container>)
        : (<Container className="static-fix-size">
          <h1 className='static-page-title'>Bases Legales</h1>
        <p className="static-page-text">Las presentes bases corresponden a la promoción “BP Ultimate Experience”, llevada a cabo por la mercantil bp ENERGÍA ESPAÑA, S.A.U., con domicilio a efectos de notificaciones en C/ Quintanadueñas, 6 (edificio Arqbórea) 2ª planta - 28050 Madrid, y CIF A-28135846 (en adelante bp), en las que se recogen las condiciones de la promoción y participación en la misma. Las bases de este sorteo se encuentran a disposición de los participantes a través de la página <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a></p>

        <p className="static-page-text">CHEQUE DEJEUNER ESPAÑA SA, sociedad domiciliada en Juan Ignacio Luca de Tena 14 4ª Planta, 28027 Madrid (España) (en adelante, el “Promotor”), con CIF A78887049, es la sociedad encargada únicamente de la gestión del sorteo (en adelante, el “Sorteo”).
        </p>

        <div className='static-page-content'>
            <ol className='static-page-ordered-list static-page-main-list'>
              <div className="static-page-content-box">
                <li className='static-page-list-title'>Objeto:</li>
                <p className="static-page-text">BP organiza una acción promocional a nivel nacional y Andorra donde los participantes conseguirán códigos promocionales tras haber realizado compras de BP y BP Ultimate para registrarlos en la web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> y conocer si su código está premiado en uno de los momentos ganadores. Asimismo, todos los códigos registrados podrán participar en el sorteo final que más adelante se detalla.</p>
              </div>

              <div className="static-page-content-box">
                  <li className='static-page-list-title'>Período de participación</li>
                  <p className="static-page-text">El período de participación para registrar el código promocional comprenderá desde el día 17 de junio de 2024 a las 21:00h, hora peninsular española hasta el día 2 de septiembre de 2024 a las 23:59h, hora peninsular española. </p>
                  <p className="static-page-text">Todos los códigos registrados durante este periodo podrán participar en el sorteo ante Notario que se celebrará durante el 16 de septiembre de 2024.</p>
              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Legitimación para participar</li>
                <p className="static-page-text">Podrán participar en la presente promoción exclusivamente los titulares de las tarjetas Mi BP /usuarios registrados Mi BP válidas (personas físicas, mayores de edad y con residencia fiscal en el territorio nacional o Andorra) o titulares de una tarjeta Plan DinoBP, en adelante los participantes, que realicen un consumo mínimo de 30 litros en las estaciones de servicio BP y que inserten el código obtenido en el ticket de compra de la estación de servicio en la página web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a>. Queda excluídos de la promoción los clientes con una tarjeta Routex.</p>

              </div>


              <div className="static-page-content-box">
                <li className='static-page-list-title'>Ámbito territorial</li>
                <p className="static-page-text">La presente promoción tendrá su desarrollo en todo el territorio nacional español y en Andorra. La participación es gratuita y no supone un incremento del precio de los productos y servicios ofrecidos por BP. </p>

              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Mecánica</li>
                <p className="static-page-text">Participarán en la presente promoción aquellos clientes de BP que durante el período de participación indicados en el punto 2 de las presentes Bases Legales:</p>

                <ol className='static-page-list-small-margin static-page-list-margin-bottom'>
                  <li className='static-page-list-text'>Ser titular de una tarjeta miBP.</li>
                  <li className='static-page-list-text'>Hayan obtenido un código en su ticket de compra en la estación de servicio para participar en la promoción tras haber repostado 30 litros o más de carburante BP. Si el repostaje fuera de BP Ultimate, recibirían 2 códigos únicos en el ticket de compra.</li>
                  <li className='static-page-list-text'>El participante que reúna los requisitos anteriores podrá acceder a la web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a>, aceptar las condiciones de las presentes bases legales y comprobar si su código está premiado con uno de los momentos ganadores diarios.</li>
                  <li className='static-page-list-text'>Será necesario clicar el botón “Comprobar Premio” que se encontrará en la página de inicio del servicio para verificar qué premio ha obtenido.</li>
                  <li className='static-page-list-text'>Serán válidas las diez primeras participaciones de cada cliente de forma diaria desde la misma IP. Las participaciones que excedan dicho número serán desestimadas como participación en el sorteo y en los momentos ganadores.</li>
                  <li className='static-page-list-text'>Todos los participantes que registren su código promocional obtenido en su ticket de compra en la web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a>, tendrán una participación en el sorteo final del 16 de septiembre.</li>
                </ol>

                <p className="static-page-text">La mecánica de Momento Ganador se basa en un programa informático, que realiza sorteos preestablecidos (un número de sorteos n, con una periodicidad determinada) de forma aleatoria, durante el período de vigencia de la Promoción. Los Momentos Ganadores se atribuyen a un momento en el tiempo concreto (hora, minuto y segundo).</p>

                <p className="static-page-text">Se repartirán los 4.503 premios de los momentos ganadores entre los 77 días del periodo de participación.</p>

                <p className="static-page-text">Si no coincidiese la intervención de un participante con el Momento Ganador diario establecido por el programa informático, el premio correspondiente a ese Momento pasará al primer Participante que ingrese en la Promoción, de manera que no quedarán premios desiertos.</p>

                <p className="static-page-text">El usuario descubrirá en el mismo momento de la participación si ha resultado ganador de alguno de los premios del Momento Ganador y podrá descargar un vale en formato PDF en el que se incluirán todos los detalles para el canje del premio del Momento Ganador.</p>

                <p className="static-page-text">BP se reserva el derecho de modificar el procedimiento aquí establecido.</p>

                <p className="static-page-text">Al finalizar la promoción, todos los participantes que hayan registrado su código de participación podrán acceder a participar en el Sorteo Final (reflejado en el siguiente punto de las presentes bases legales) a excepción de los participantes premiados con un viaje a Abu Dhabi en los Momentos Ganadores.</p>

              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Descripción de los premios
                  <ol className='static-page-submain-list'>
                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Los Momentos Ganadores diarios se componen de los siguientes regalos:</li>
                      <ol className='static-page-list-mid-margin'>
                        <li className='static-page-list-text'>100 bonos de carburante de 100€ a repartir durante el periodo promocional.</li>
                        <li className='static-page-list-text'>150 bonos de carburante de 50€ a repartir durante el periodo promocional.</li>
                        <li className='static-page-list-text'>750 bonos de carburante de 15€ a repartir durante el periodo promocional. </li>
                        <li className='static-page-list-text'>3.500 bonos de carburante de 5€ a repartir durante el periodo promocional.</li>
                        <li className='static-page-list-text'>3 experiencias para 4 personas a Abu Dhabi que incluye:
                          <ul className='static-page-list-small-margin'>
                            <li className='static-page-list-text'>Vuelos de ida y vuelta desde Madrid, desplazamientos y transfers en destino.</li>
                            <li className='static-page-list-text'>6 noches de alojamiento en hotel de 4 estrellas, incluido el desayuno.</li>
                            <li className='static-page-list-text'>Experiencia VIP con el BWT Alpine F1 ® Team que incluye:</li>
                            <ul className='static-page-list-small-margin'>
                              <li className="static-page-list-text">Una experiencia de 3 días con BWT Alpine F1® Team en Abu Dhabi, que incluye un paquete de hospitalidad para ti y tus tres acompañantes.</li>
                              <li className="static-page-list-text">Experiencia VIP que incluirá al menos una de las siguientes: Tour del garaje del BWT Alpine F1® Team; o un paquete de merchandising del BWT Alpine F1® Team para ti y tus acompañantes; y o una experiencia privada de encuentro con al menos uno de los pilotos del BWT Alpine F1® Team antes del evento.</li>
                            </ul>
                            <li className='static-page-list-text'>Seguro de viaje.</li>
                            <li className='static-page-list-text'>Guía durante la estancia en Abu Dhabi. </li>
                            <li className='static-page-list-text'>No se incluyen desplazamientos hasta el aeropuerto de Madrid-Barajas.</li>
                          </ul>
                        </li>
                      </ol>
                    </div>

                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Sorteo Final</li>
                      <p className="static-page-text">El Sorteo Final consistirá en un viaje para 4 personas a Valencia, donde serán recibidos en el paddock por el equipo Aprilia Racing MotoGP™ (en adelante, el “Premio”), desde el jueves 14 de noviembre hasta el lunes 18 de noviembre. Incluye una visita guiada al paddock y al garaje, viaje de ida y vuelta en AVE desde Madrid, 4 noches de alojamiento en un hotel de 4 estrellas en 2 habitaciones dobles, y traslados desde la estación de AVE hasta el hotel. No se incluye el traslado a la estación de tren Madrid-Chamartín.</p>

                      <p className="static-page-text">Bp se reserva el derecho de modificar los premios si por causas ajenas a bp, no hubiera disponibilidad. En caso de cambios en los premios, éstos se sustituirían por premios no dinerarios, de similares características y de importe igual o superior, a elección de bp.</p>

                    </div>

                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Elección de los ganadores</li>
                      <p className="static-page-text">El sorteo se realizará el día 16 de septiembre de 2024 ante notario. Se elegirá al azar <b>UN (1) ganador.</b> Asimismo, se extraerán <b>VEINTE (20) suplentes</b> que sustituyan al ganador, para el caso de no localización o no cumplimiento de los requisitos exigidos en las presentes bases, entre todos los participantes de esta promoción. En caso de que el número de participantes en reserva fuera insuficiente, BP se reserva el derecho de solicitar al Notario la extracción de nuevos ganadores suplentes.</p>

                      <p className="static-page-text">La entrega efectiva del premio quedará condicionada a que el ganador cumpla con las condiciones especificadas en las presentes bases. BP se reserva el derecho de dar de baja y expulsar automáticamente y sin previo aviso a cualquier participante que estime que no está cumpliendo con las condiciones de participación o que está haciendo un mal uso o abuso de la promoción.</p>

                    </div>

                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Comunicación de premios</li>
                      <ol className="static-page-submain-list">
                        <li className="static-page-list-text">
                          <p><strong>Premios momentos ganadores:</strong> los Participantes que hayan resultado premiados en los Momentos Ganadores con un viaje a Abu Dhabi, dispondrán de un plazo de siete (7) días naturales desde la notificación de su condición de ganador para ponerse en contacto con BP a través del medio de contacto indicado en el voucher premiado y manifestar por escrito su aceptación del premio obtenido, momento en el cual se le informará del procedimiento para formalizar el mismo.</p>
                          <p>Para el caso de que el premiado, no forme parte del programa miBP, no cumpliera con los requisitos de las presentes Bases Legales o no pudiera contactarse con él por no estar registrado en el programa miBP, los diez (10) siguientes participantes registrados que cumplan con los requisitos, tendrán la consideración de suplentes en función de su orden de participación y dispondrán de siete (7) días naturales desde la notificación de su condición de ganador para ponerse en contacto con BP y manifestar por escrito su aceptación del premio obtenido, momento en el cual se le informará del procedimiento para formalizar el mismo.</p>
                        </li>

                        <li className="static-page-list-text">
                          <p><strong>Premio sorteo final:</strong> Se seleccionará a un (1) ganador del Premio del Sorteo Final, así como a otros diez (10) usuarios que tendrán la consideración de suplentes en función de su orden de participación, para el caso de que el Premiado, o en su caso, cada uno de los suplentes, no esté registrado en el programa miBP, no cumpliera con los requisitos de las presentes Bases Legales, no pudiera contactarse con él en los siete (7) días naturales a la dirección de email del Premiado o no aceptara el premio.</p>
                          <p>El Premiado dispondrá de un plazo de siete (7) días naturales desde la notificación de su condición de Premiado para ponerse en contacto con BP y manifestar por escrito su aceptación del Premio obtenido, momento en el cual se le informará del procedimiento para formalizar la entrega del Premio.</p>
                        </li>
                      </ol>

                    </div>

                    <div className="static-page-content-small-box">
                      <li className='static-page-list-subtitle'>Privación del Premio</li>
                      <p className="static-page-text">El Premiado del sorteo final, los premiados de los viajes a Abu Dhabi de los Momentos Ganadores, así como los participantes incluidos en la lista de suplencia, podrán ser revocados de su condición de Premiado y/o suplente a entera discreción del Organizador si se aprecia en ellos, ya sea a través de redes sociales o cualquier medio a disposición del Organizador (i) manifestaciones racistas, xenófobas, machistas o que atenten contra cualquier sexo, raza, religión o ideología (ii) manifestaciones discriminatorias o vejatorias ante cualquier persona, entidad u organización (iii) manifestaciones de exaltación o cualquier tipo de apoyo a regímenes autoritarios (iv) encontrarse en situación de investigación y/o condena sobre los temas señalados en los puntos anteriores.</p>

                      <p className="static-page-text">En caso de producirse privación del premio al ganador y/o suplentes, podrá ser adjudicado a los suplentes restantes durante el mismo periodo promocional, o bien, a entera discreción del Organizador si todos los suplentes están inmersos en las causas anteriores, en sucesivos periodos a futuros concursantes que cumplan los requisitos de las Bases Legales.</p>

                      <p className="static-page-text">Quedan excluidos y, por tanto, no podrán tener la condición de participantes ni resultar agraciados: (i) Los empleados del Promotor, Organizador y empresas del grupo  a  los que pertenecen respectivamente; (ii) Los empleados de las empresas asociadas, agencias de publicidad o agencias de promociones o terceras empresas que estén involucradas de cualquier forma en la organización  y desarrollo del Evento, (iii) Los familiares directos (padres, hermanos e hijos) de las personas incluidas en los puntos i y ii anteriores; y (iv) Los cónyuges de todas las personas incluidas en los puntos i, ii y iii anteriores.</p>

                    </div>

                    <div className="static-page-content-small-box">

                      <li className='static-page-list-subtitle'>Condiciones de los premios</li>
                      <ul className='static-page-list-mid-margin'>
                        <li className="static-page-list-text">Si por cualquier razón ajena a BP no hubiera disponibilidad del premio previsto, BP se reserva el derecho de sustituirlos por otros, de igual o superior coste.</li>
                        <li className="static-page-list-text">El premio no es canjeable en metálico, ni por cualquier otro premio y es intransferible. Queda prohibida la comercialización y/o venta del premio o del derecho a su obtención.</li>
                        <li className="static-page-list-text">BP no se responsabiliza del uso que del premio promocional realice el agraciado.</li>
                        <li className="static-page-list-text">BP no será responsable de los servicios que terceras empresas deban prestar con motivo del premio de la presente promoción, ni de ningún accidente, ni incidencia producida durante el disfrute del mismo.</li>
                        <li className="static-page-list-text">BP no será responsable de las posibles pérdidas, deterioros, robos, sustracciones, retrasos o cualquier otra acción imputable a terceros que puedan sufrir los premios durante el envío o entrega al ganador.</li>
                        <li className="static-page-list-text">BP se reserva el derecho a declarar el premio desierto en el caso de que se tengan motivos razonables para creer que un participante ha infringido cualquiera de esos términos y condiciones.</li>
                        <li className="static-page-list-text">Será condición indispensable para poder efectuar la entrega, que el agraciado presente y acredite mediante DNI que el nombre y los apellidos del registro ganador coincidan con la persona que recibe el premio.</li>
                        <li className="static-page-list-text">En el caso de que, por cualquier circunstancia, el premiado no pudiese o quisiese aceptar el premio, o renunciase al mismo, BP procedería a designar al siguiente ganador en reserva. En el caso de que los demás ganadores en reserva a su vez no aceptasen o renunciasen al premio, éste podrá ser declarado desierto.</li>

                      </ul>

                    </div>

                    <div className="static-page-content-small-box">

                    </div>
                    <li className='static-page-list-subtitle'>Fiscalidad de los premios</li>
                    <p className="static-page-text">Todos los  gastos  motivados por demora o negligencia por parte del ganador, serán de cuenta de éste, salvo que de otra forma, se hubiera establecido expresamente en las presentes bases. Serán de cuenta de la persona premiada todos los gastos referentes a mejoras y extras que no correspondan con el premio detallado en las presentes bases.</p>

                    <p className="static-page-text">De conformidad con la normativa del Impuesto sobre la Renta de las Personas Físicas (en adelante IRPF), los premios concedidos por la participación en juegos, concursos, rifas, o combinaciones aleatorias vinculadas a la venta o promoción de bienes o servicios están sujetos a retención o ingreso a cuenta siempre que el valor del premio sea superior a 300 Euros. En todo caso corresponderá a la entidad mercantil BP ENERGÍA ESPAÑA, S.A.U. la realización del ingreso a cuenta o la práctica de la retención procedente, la cual será asumida por esta entidad y realizada en nombre del premiado, debiendo éste último declarar la ganancia patrimonial correspondiente en su IRPF. Además, será necesario para realizar la entrega de cualquier premio con cuantía superior a 300 Euros, que el premiado firme la comunicación remitida por BP en la que conste el importe del premio y de la retención o ingreso a cuenta correspondiente y cualquier otra obligación que la Administración Tributaria imponga. La negativa a firmar dicho documento o a cumplimentar dichas obligaciones, supondrá la pérdida del premio para el premiado, quedando éste desierto.</p>

                    <p className="static-page-text">En el caso en que el premiado sea residente en Andorra, la entidad mercantil BP ENERGÍA ESPAÑA, S.A.U. procederá a la realización del ingreso a cuenta o la práctica de la retención procedente, a cual será asumida por esta entidad y realizada en nombre del premiado, debiendo éste último acreditar la ganancia patrimonial generada, conforme a la normativa aplicable en este país.</p>

                    <p className="static-page-text">A tal efecto, BP remitirá al premiado que haya aceptado el premio, la documentación tributaria correspondiente, con la obligación del premiado, de remitirla, debidamente cumplimentada, a BP, en un plazo de quince días. El incumplimiento de esta obligación determina la pérdida del derecho al premio.</p>

                    <p className="static-page-text">Para el caso de que la citada documentación no fuese entregada por circunstancias no imputables al premiado en el momento de la recogida del premio (de forma que no perdiese el derecho a premio), BP volverá a reenviarla al premiado, quién deberá remitir la misma dentro de los 15 días siguientes, debidamente cumplimentada.</p>

                    <p className="static-page-text">En todo caso, si dicha comunicación fuera recibida por BP en un período impositivo diferente al de la entrega del premio, y el premiado no hubiese perdido su derecho al mismo, la retención o ingreso a cuenta, se practicará en el periodo impositivo en que BP reciba la comunicación firmada, de forma que el premiado quedará obligado a declarar el premio obtenido en su declaración del IRPF de dicho ejercicio.</p>
                    </ol>
                  </li>

                </div>

                <div className="static-page-content-box">
                  <li className='static-page-list-title'>Reserva de derechos</li>
                  <p className="static-page-text">BP se reserva el derecho de dar de baja a aquellos participantes que estén haciendo un mal uso de la promoción, realicen actos fraudulentos o que perjudiquen a otros participantes. En caso de que BP o cualquier entidad que esté ligada profesionalmente a esta promoción detecten cualquier anomalía o sospechen que un participante esté impidiendo el normal desarrollo de la promoción, o llevando a cabo cualesquiera actos fraudulentos que contravengan la transparencia de la promoción, BP se reserva el derecho de dar de baja e incluso de retirar el premio de forma automática y sin explicación de ningún tipo, a todos aquellos participantes que se hayan beneficiado de forma directa o indirecta de este tipo de actuaciones fraudulentas, pudiendo además ejercer todas las acciones civiles o penales que pudieran corresponder.
                  </p>

                  <p className="static-page-text">En este sentido, BP declara que ha habilitado los mecanismos y soportes tecnológicos idóneos para detectar cualquier posible actuación fraudulenta, anómala o dolosa que pretenda alterar la participación normal en la presente promoción.</p>

                  <p className="static-page-text">BP se reserva el derecho a seleccionar ganador alternativo en el caso de que se tengan motivos razonables de creer que un participante ha infringido cualquiera de esos términos y condiciones. En caso de que esta promoción no pudiera realizarse, bien por fraudes detectados, errores técnicos, o cualquier otro motivo grave, ajeno a su voluntad, que altere las circunstancias que motivaron la promoción, y que afecte al normal desarrollo de la misma.</p>
                  <p className="static-page-text">BP se reserva el derecho a cancelar, modificar, o suspenderla, sin que los participantes puedan exigir responsabilidad alguna al promotor. BP no se responsabiliza del retraso, deterioro o pérdida de la entrega del premio por cualquier causa no imputable directamente a aquel.</p>

                </div>

                <div className="static-page-content-box">

                <li className='static-page-list-title'>Política de privacidad</li>
                <p className="static-page-text">Los datos de carácter personal facilitados serán incorporados a un fichero responsabilidad bp con la finalidad de gestionar la promoción de conformidad con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales (LOPDGDD).</p>

                <p className="static-page-text">Con la participación en la presente promoción, los participantes prestan su consentimiento a que sus datos personales incluida la imagen, de resultar ganador, sean publicados por bp en sus diferentes canales de comunicación (página web, app Mibp, estaciones de servicio…) con la finalidad de gestionar la promoción e identificar a los ganadores: así como para ponernos en contacto con Ud. por vía telefónica o correo electrónico de ser necesario y para cumplir el mismo fin. En cualquier caso, la cesión de imágenes no conllevará retribución alguna.</p>

                <p className="static-page-text">Nos reservamos el derecho a divulgar su información personal como lo exige la ley, o cuando creamos que la divulgación es necesaria para proteger nuestros derechos y/o cumplir con los requisitos de un procedimiento judicial, mandato judicial, solicitud de un regulador o cualquier otro proceso legal que afecte a BP. En el momento en que BP esté sujeta a compra, liquidación o adquisición, podemos divulgar su información personal al nuevo propietario de la empresa.</p>

                <p className="static-page-text">No tomaremos decisiones automatizadas en base a dicho perfil en base a los datos proporcionados.</p>

                <p className="static-page-text">Los datos personales serán conservados durante no más tiempo del necesario para los fines del tratamiento, con un plazo máximo de 1 año.</p>

                <p className="static-page-text">Puesto que BP opera globalmente, sus datos serán accesibles/compartidos con otras compañías del grupo BP involucradas en este proceso comercial, con la única finalidad de la gestión del mismo. Cualquier transferencia interna de datos del grupo BP está regida y legitimada por las Reglas Corporativas Vinculantes de BP, garantizando un nivel adecuado de protección de datos a través del grupo BP.</p>

                <p className="static-page-text">LEl suministro de los datos por los participantes tiene carácter obligatorio, por lo que la negativa a suministrar los mismos supone la imposibilidad de participar en la presente promoción. </p>

                <p className="static-page-text">En cualquier momento, podrá modificar sus preferencias de comunicación, así como ejercer los siguientes derechos:</p>

                <ul className='static-page-list-mid-margin static-page-list-margin-bottom'>
                  <li className="static-page-list-text">Derecho de Acceso, podrá consultar sus datos personales.</li>
                  <li className="static-page-list-text">Derecho de Rectificación, podrá modificar sus datos personales cuando sean inexactos.</li>
                  <li className="static-page-list-text">Derecho de Supresión, podrá solicitar la eliminación de sus datos personales asociados al servicio.</li>
                  <li className="static-page-list-text">Derecho de Oposición, podrá solicitar que no se traten sus datos personales.</li>
                  <li className="static-page-list-text">Derecho de Limitación del tratamiento, podrá solicitar la limitación al tratamiento de sus datos.</li>
                  <li className="static-page-list-text">Derecho de Portabilidad, podrá recibir, en formato electrónico, los datos personales que haya facilitado, así como a transmitirlos a otra entidad.</li>
                  <li className="static-page-list-text">Derecho de Revocación del consentimiento, tendrá derecho a retirar el consentimiento otorgado para el tratamiento de los datos personales, en cualquier momento, y a través de cualquiera de los canales que se indican a continuación, sin que ello afecte a la licitud de los tratamientos realizados con carácter previo a su retirada.</li>
                </ul>

                  <p className="static-page-text">Podrá ejercer estos derechos mediante cualquier medio, que permita acreditar el envío y recepción de su solicitud que habrá de dirigirse a BP ENERGÍA España S.A., en C/ Quintanadueñas, 6 (edificio Arqbórea) 2ª planta - 28050 Madrid, o bien mediante correo electrónico dirigido a <a href='mailto:atencionalcliente@mibp.es' className='static-page-links'>atencionalcliente@mibp.es</a> . Si está en la UE, podrá presentar reclamaciones ante la Autoridad de Protección de Datos del Estado miembro correspondiente.</p>

                  <p className="static-page-text">Para más información sobre nuestra Política de Privacidad: <a target="_blank" href='https://www.bp.com/es_es/spain/home/politica-privacidad.html' className='static-page-links' rel="noreferrer">https://www.bp.com/es_es/spain/home/politica-privacidad.html</a></p>


              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Legislación aplicable</li>
                <p className="static-page-text">La presente promoción se rige por la legislación común española vigente.</p>

              </div>

              <div className="static-page-content-box">
                <li className='static-page-list-title'>Aceptación de las bases y Fuero.</li>
                <p className="static-page-text">La participación en la promoción supone la aceptación íntegra de las presentes bases. Se podrá pedir al ganador un documento escrito aceptando todos los términos y condiciones del concurso. Todos los conflictos que se susciten en aplicación de las presentes bases se resolverán por los Juzgados y Tribunales que por fuero corresponda.</p>

              </div>

            </ol>
            </div>
          </Container>)
       }

      </div>
        <footer>
              {
                (isMobile) ? <FooterMobile /> : <Footer />
              }
        </footer>
    </>
  )
}
