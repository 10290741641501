import { UUID } from "crypto";

export interface AuthState {
  status:      'checking' | 'authenticated' | 'not-authenticated';
  user:        User | null;
  token:       string | null;
  ip:       string | null;
  isLoading:   boolean;
}

export interface User {
  id?:              number;
  email:            string;
  plainPassword:    string;
  role:             string;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface AbstractApiListResponse {
  limit:  number;
  offset: number;
  total:  number
}

export interface AbstractFilterItem {
  limit: number;
  offset: number;
}

export interface Prizeid {
  created_at: string;
  updated_at: string;
  deleted_at: null;
  id: string;
  name: string;
  description: string;
  coupon: string;
}

export interface CheckVoucherResponse {
  code?: string;
  id?: string;
  ip?: string;
  message: string;
  type?: string;
  error?: string;
  statusCode?: number;
  prize_id?: Prizeid;
}

export interface CheckVoucherResponseError {
  message?: string;
  ip?: string;
  type?: string;
  code?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: null;
  id?: string;
}

export enum PrizeType {
  ONE = '5€',
  TWO = '50€',
  THREE = '15€',
  FOUR = '100€',
  SPECIAL = 'special',
}

export enum CodeErrors {
  DAILY_LIMIT = 'Daily Limit',
  INVALID_CODE = 'Invalid Code',
  CODE_ALREADY_USED = 'Code Already Used',
  NOT_WINNER = 'Not Winner',
}


export interface PaginationDto {
  limit?: number;
  offset?: number;
  query?: FindManyOptions;
}

export interface FindManyOptions {
  skip?: number;
  take?: number;
}

export interface AbstractDateEntity {
  created_at: Date
  updated_at: Date
  deleted_at: Date
}


export interface AbstractBaseEntity extends AbstractDateEntity {
  id: UUID
}
export interface CodeEntity extends AbstractBaseEntity {
  total: number,
  limit: number,
  data: CodeResults[],
  offset: number
}
export interface CodeResults extends AbstractBaseEntity {

  code: string,
  ip?: IpEntity
  used_at: string
  moment_id?: MomentResults

}

export interface CodeApiListResponse extends AbstractApiListResponse {
  data: CodeEntity[];
}

export interface IpEntity extends AbstractDateEntity {
  id: string;
  user_id: UserEntity;
  access_token: string;
  refresh_token: string;
}

export interface MomentEntity extends AbstractBaseEntity {
  data : MomentResults[],
  limit: number
  offset: number
  total: number
}
export interface MomentResults extends AbstractBaseEntity {
  code?: string;
  moment_at: string;
  prize_id: PrizeResults;
}

export interface MomentApiListResponse extends AbstractApiListResponse {
  data: MomentEntity[];
}

export interface PrizeEntity extends AbstractBaseEntity {
  data: PrizeResults[],
  limit: number,
  offset: number,
  total: number
}
export interface PrizeResults extends AbstractBaseEntity {
  name: string;
  description: string;
  coupon: string;
}

export interface PirzeApiListResponse extends AbstractApiListResponse {
  data: PrizeEntity[];
}

export interface UserEntity extends AbstractBaseEntity {
  email: string;
  password: string;
  fullName: string;
  role: RoleEnum;
}

export enum RoleEnum {
  Admin = 'admin',
  Maintainer = 'maintainer',
  User = 'user',
}

// export interface CodeFilterItem extends AbstractFilterItem {
//   "findBy[]"  : CodeFilter;
//   "orderBy[]" : CodeFilter
// }

// export interface CodeFilter {
//   "code"                           : string;
//   "used_at"                        : string;
//   "moment_id.moment_at"            : string;
//   "moment_id.prize_id.description" : string;
// }
