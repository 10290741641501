import './PrizeCouponImg.css'


interface Props {
  winner: boolean
  bestPrize ?: boolean
  printing: boolean;
}

export const PrizeCouponImg = ({ winner, bestPrize, printing} : Props) => {
  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();
    
  return (
    <div className="prize-coupon-image-container" style={{ alignItems: (!printing) ? 'center' : 'flex-start' }}>
    {
      (winner) 
      ? (bestPrize)
        ? <img src={`${appURL}/images/best-prize.png`} className="prize-img" alt='best prize img' />
        : <img src={`${appURL}/images/winner_img.png`} className="prize-img" alt='winner prize img' />
      : <img src={`${appURL}/images/non_winner_img.png`} className="prize-img" alt='no winner prize img' />
    }
    </div>
  )
}
