import { Container } from 'react-bootstrap';
import { Footer, FooterMobile, NavbarHeader } from '../../components'
import { useDeviceType } from '../../hooks';
import './PrivacyDeclarations.css'

export const PrivacyDeclarations = () => {
  const isMobile = useDeviceType();

  return (
    <>
     <NavbarHeader />
    <div className='static-page-container'>
        {
          (isMobile)
          ? (<Container fluid className='static-fix-size'>
            <h1 className='static-page-title'>Política de Privacidad</h1>

            <div className='static-page-content'>
                <ol className='static-page-ordered-list static-page-main-list'>
                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Objeto de la Política de Privacidad</li>

                    <p className="static-page-text">La política de privacidad tiene por objeto informar sobre el modo en que bp obtiene, trata y protege los datos personales que facilite el Usuario a través del sitio web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> y el servicio de atención al cliente.</p>

                    <p className="static-page-text">Es importante que el Usuario lea esta política en cada una de las ocasiones en que utilice cualquiera de nuestros canales, ya que ésta puede sufrir modificaciones.</p>
                  </div>

                  <div className="static-page-content-box">
                      <li className='static-page-list-title'>Responsable del tratamiento de datos personales</li>

                      <p className="static-page-text">Bp Energía España, S.A.U. con domicilio social en C/ Quintanadueñas, 6 (edificio Arqbórea), 2ª planta - 28050 Madrid, es el Responsable del tratamiento de los datos personales del Usuario y le informa que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (RGPD) relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos.</p>

                  </div>
                  <div className="static-page-content-box">
                      <li className='static-page-list-title'>Tratamiento de los datos personales</li>

                      <p className="static-page-text">En cumplimiento de lo establecido en el RGPD, los datos personales recabados a través del Sitio Web y /o cualquier otro medio de recogida de datos, serán tratados de manera lícita, leal y transparente.</p>
                      <p className="static-page-text">El Usuario declara que la información y los datos facilitados son exactos y veraces; igualmente, deberá comunicar, a la mayor brevedad posible, las modificaciones y rectificaciones de sus datos de carácter personal que se vayan produciendo.</p>
                      <p className="static-page-text">Los datos personales del Usuario serán tratados con las siguientes finalidades y base jurídica.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Finalidades del tratamiento</li>
					<p className="static-page-text">Sus datos personales serán utilizados exclusivamente para las finalidades siguientes:</p>

                    <ol className='static-page-list-small-margin'>
                      <li className="static-page-list-text">Gestión del Programa <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a>, incluida la adjudicación de códigos promocionales, sorteos y redención de premios.</li>
                      <li className="static-page-list-text">Utilización de los datos facilitados, en aquellos casos que sea necesario, para ponerse en contacto con los premiados y poder entregar el premio, así como el uso de la imagen la para publicitar la Promoción en diferentes medios (página web, app mibp, estaciones de servicio…). </li>
                      <li className="static-page-list-text">Análisis sobre los consumos realizados y perfiles de compra, con el fin de mejorar la oferta de productos, la experiencia de compra del Usuario y realizar acciones promocionales que se ajusten a sus hábitos de consumo (elaboración de perfiles).</li>
                    </ol>

                  </div>


                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Base jurídica del tratamiento</li>

                    <p className="static-page-text">Dichos tratamientos se realizan en base a la relación contractual que aparecen en los términos de uso del programa, y al interés legítimo de BP para ofrecer al Usuario las mejores ofertas.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Confidencialidad y seguridad</li>

                    <p className="static-page-text">Los datos obtenidos a través del Sitio Web y/o cualquier otro medio, serán tratados por bp con absoluta confidencialidad, comprometiéndose a guardar secreto respecto de los mismos.</p>

                    <p className="static-page-text">Los datos obtenidos a través del Sitio Web y/o cualquier otro medio, serán tratados por bp con absoluta confidencialidad, comprometiéndose a guardar secreto respecto de los mismos.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Llevar a cabo investigaciones / encuestas de clientes para mejorar nuestros servicios</li>

                    <p className="static-page-text">También analizaremos y utilizaremos sus datos (a nivel agregado) para llevar a cabo investigaciones sobre cómo los clientes interactúan con bp y el programa de fidelización para que podamos mejorar nuestros productos, servicios y comunicaciones con los clientes.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Conservación de los datos</li>

                    <p className="static-page-text">Los datos personales serán conservados durante no más tiempo del necesario para los fines del tratamiento, con un plazo máximo de 24 meses, a partir de la última transacción realizada con cualquiera de las tarjetas asociadas al cliente.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Comunicación de datos a terceros</li>

                    <p className="static-page-text">Los datos del Usuario se cederán a entidades del mismo grupo empresarial de bp en aquellos casos que sea necesario para conseguir las finalidades objeto del tratamiento. bp no cederá datos personales a terceras empresas, salvo los terceros proveedores necesarios para la gestión de la Promoción u obligación legal.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Cookies</li>

                    <p className="static-page-text">En el Sitio Web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> utilizamos una tecnología denominada “cookies”. Para más información detallada sobre cómo utiliza <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> las cookies, consulte la <a href='https://bepear.es/cookies' className='static-page-links'>Política de Cookies.</a></p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Enlaces a otras páginas web</li>

                    <p className="static-page-text">El Sitio Web puede contener enlaces a otras páginas web. bp no es responsable de la privacidad ni del tratamiento de datos personales de otras páginas web. Este documento de Política de Protección de Datos Personales se aplica exclusivamente a la información que se recaba en el Sitio Web de <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> y Servicio de Atención al Cliente</p>

                    <p className="static-page-text">Se recomienda al Usuario que lea las políticas de tratamiento de datos personales de otras páginas web con las que enlace desde nuestro Sitio Web.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Derechos</li>

                    <p className="static-page-text">En cualquier momento el Usuario podrá modificar sus preferencias de comunicación, así como ejercer los siguientes derechos:</p>

                    <ul className='static-page-list-mid-margin static-page-list-margin-bottom'>
                      <li className="static-page-list-text">Derecho de Acceso, el Usuario podrá consultar sus datos personales</li>
                      <li className="static-page-list-text">Derecho de Rectificación, el Usuario podrá modificar sus datos personales cuando sean inexactos.</li>
                      <li className="static-page-list-text">Derecho de Supresión, el Usuario podrá solicitar la eliminación de sus datos personales asociados al Programa <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a></li>
                      <li className="static-page-list-text">Derecho de Oposición, el Usuario podrá solicitar que no se traten sus datos personales.</li>
                      <li className="static-page-list-text">Derecho de Limitación del tratamiento, el Usuario podrá solicitar la limitación al tratamiento de sus datos en los siguientes casos:</li>
                        <ul className='static-page-list-mid-margin static-page-list-margin-bottom'>
                          <li className="static-page-list-text">Mientras se comprueba la impugnación de la exactitud de tus datos.</li>
                          <li className="static-page-list-text">Cuando el tratamiento es ilícito, pero el Usuario se opone a la supresión de sus datos.</li>
                          <li className="static-page-list-text">Cuando BP no necesite tratar sus datos, pero el Usuario los necesite para el ejercicio o la defensa de reclamaciones.</li>
                          <li className="static-page-list-text">Cuando el Usuario se haya opuesto al tratamiento de tus datos para el cumplimiento de interés legítimo por parte de BP, mientras se verifica si los motivos legítimos para el tratamiento prevalecen sobre los suyos.</li>
                        </ul>

                      <li className="static-page-list-text">Derecho de Portabilidad, el Usuario podrá recibir, en formato electrónico, los datos personales que haya facilitado, así como a transmitirlos a otra entidad.</li>
                      <li className="static-page-list-text">Derecho de Revocación del consentimiento, el Usuario tendrá derecho a retirar el consentimiento otorgado para el tratamiento de los datos personales, en cualquier momento, y a través de cualquiera de los canales que se indican a continuación, sin que ello afecte a la licitud de los tratamientos realizados con carácter previo a su retirada</li>
                    </ul>

                    <p className="static-page-text">El Usuario tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (<a target="_blank" href='https://ww.agpd.es' className='static-page-links' rel="noreferrer">www.agpd.es</a>).</p>

                    <p className="static-page-text">Nos comprometemos a garantizar que sus datos personales se mantengan precisos y actualizados. Es su responsabilidad informarnos si hay inexactitudes o cambios en sus datos personales.</p>

                    <p className="static-page-text">Los canales de contacto para sus solicitudes o reclamaciones son los siguientes (por favor, indique “Protección de Datos” en el asunto):</p>

                    <p className="static-page-text">Email: <a href="mailto:atencionalcliente@mibp.es" className='static-page-links'>atencionalcliente@mibp.es</a></p>
                    <p className="static-page-text">Servicio de atención al cliente: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>
                    <p className="static-page-text">Dirección Postal: bp Energía ESPAÑA. C/ Quintanadueñas, 6 (edificio Arqbórea), 2ª planta - 28050 Madrid</p>


                  </div>
                </ol>
            </div>
            </Container>)
          : (<Container className='static-fix-size'>
            <h1 className='static-page-title'>Política de Privacidad</h1>

            <div className='static-page-content'>
                <ol className='static-page-ordered-list static-page-main-list'>
                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Objeto de la Política de Privacidad</li>

                    <p className="static-page-text">La política de privacidad tiene por objeto informar sobre el modo en que bp obtiene, trata y protege los datos personales que facilite el Usuario a través del sitio web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> y el servicio de atención al cliente.</p>

                    <p className="static-page-text">Es importante que el Usuario lea esta política en cada una de las ocasiones en que utilice cualquiera de nuestros canales, ya que ésta puede sufrir modificaciones.</p>
                  </div>

                  <div className="static-page-content-box">
                      <li className='static-page-list-title'>Responsable del tratamiento de datos personales</li>

                      <p className="static-page-text">Bp Energía España, S.A.U. con domicilio social en C/ Quintanadueñas, 6 (edificio Arqbórea), 2ª planta - 28050 Madrid, es el Responsable del tratamiento de los datos personales del Usuario y le informa que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (RGPD) relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos.</p>

                  </div>
                  <div className="static-page-content-box">
                      <li className='static-page-list-title'>Tratamiento de los datos personales</li>

                      <p className="static-page-text">En cumplimiento de lo establecido en el RGPD, los datos personales recabados a través del Sitio Web y /o cualquier otro medio de recogida de datos, serán tratados de manera lícita, leal y transparente.</p>
                      <p className="static-page-text">El Usuario declara que la información y los datos facilitados son exactos y veraces; igualmente, deberá comunicar, a la mayor brevedad posible, las modificaciones y rectificaciones de sus datos de carácter personal que se vayan produciendo.</p>
                      <p className="static-page-text">Los datos personales del Usuario serán tratados con las siguientes finalidades y base jurídica.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Finalidades del tratamiento</li>
					<p className="static-page-text">Sus datos personales serán utilizados exclusivamente para las finalidades siguientes:</p>

                    <ol className='static-page-list-small-margin'>
                      <li className="static-page-list-text">Gestión del Programa <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a>, incluida la adjudicación de códigos promocionales, sorteos y redención de premios.</li>
                      <li className="static-page-list-text">Utilización de los datos facilitados, en aquellos casos que sea necesario, para ponerse en contacto con los premiados y poder entregar el premio, así como el uso de la imagen la para publicitar la Promoción en diferentes medios (página web, app mibp, estaciones de servicio…). </li>
                      <li className="static-page-list-text">Análisis sobre los consumos realizados y perfiles de compra, con el fin de mejorar la oferta de productos, la experiencia de compra del Usuario y realizar acciones promocionales que se ajusten a sus hábitos de consumo (elaboración de perfiles).</li>
                    </ol>
                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Base jurídica del tratamiento</li>
                    <p className="static-page-text">Dichos tratamientos se realizan en base a la relación contractual que aparecen en los términos de uso del programa, y al interés legítimo de BP para ofrecer al Usuario las mejores ofertas.</p>
                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Confidencialidad y seguridad</li>
                    <p className="static-page-text">Los datos obtenidos a través del Sitio Web y/o cualquier otro medio, serán tratados por bp con absoluta confidencialidad, comprometiéndose a guardar secreto respecto de los mismos.</p>

                    <p className="static-page-text">Los datos obtenidos a través del Sitio Web y/o cualquier otro medio, serán tratados por bp con absoluta confidencialidad, comprometiéndose a guardar secreto respecto de los mismos.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Llevar a cabo investigaciones / encuestas de clientes para mejorar nuestros servicios</li>

                    <p className="static-page-text">También analizaremos y utilizaremos sus datos (a nivel agregado) para llevar a cabo investigaciones sobre cómo los clientes interactúan con bp y el programa de fidelización para que podamos mejorar nuestros productos, servicios y comunicaciones con los clientes.</p>
                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Conservación de los datos</li>

                    <p className="static-page-text">Los datos personales serán conservados durante no más tiempo del necesario para los fines del tratamiento, con un plazo máximo de 24 meses, a partir de la última transacción realizada con cualquiera de las tarjetas asociadas al cliente.</p>
                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Comunicación de datos a terceros</li>

                    <p className="static-page-text">Los datos del Usuario se cederán a entidades del mismo grupo empresarial de bp en aquellos casos que sea necesario para conseguir las finalidades objeto del tratamiento. bp no cederá datos personales a terceras empresas, salvo los terceros proveedores necesarios para la gestión de la Promoción u obligación legal.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Cookies</li>

                    <p className="static-page-text">En el Sitio Web <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> utilizamos una tecnología denominada “cookies”. Para más información detallada sobre cómo utiliza <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> las cookies, consulte la <a href='https://bepear.es/cookies' className='static-page-links'>Política de Cookies.</a></p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Enlaces a otras páginas web</li>

                    <p className="static-page-text">El Sitio Web puede contener enlaces a otras páginas web. bp no es responsable de la privacidad ni del tratamiento de datos personales de otras páginas web. Este documento de Política de Protección de Datos Personales se aplica exclusivamente a la información que se recaba en el Sitio Web de <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a> y Servicio de Atención al Cliente</p>

                    <p className="static-page-text">Se recomienda al Usuario que lea las políticas de tratamiento de datos personales de otras páginas web con las que enlace desde nuestro Sitio Web.</p>

                  </div>

                  <div className="static-page-content-box">
                    <li className='static-page-list-title'>Derechos</li>

                    <p className="static-page-text">En cualquier momento el Usuario podrá modificar sus preferencias de comunicación, así como ejercer los siguientes derechos:</p>

                    <ul className='static-page-list-mid-margin static-page-list-margin-bottom'>
                      <li className="static-page-list-text">Derecho de Acceso, el Usuario podrá consultar sus datos personales</li>
                      <li className="static-page-list-text">Derecho de Rectificación, el Usuario podrá modificar sus datos personales cuando sean inexactos.</li>
                      <li className="static-page-list-text">Derecho de Supresión, el Usuario podrá solicitar la eliminación de sus datos personales asociados al Programa <a target="_blank" href='https://bepear.es' className='static-page-links' rel="noreferrer">bepear.es</a></li>
                      <li className="static-page-list-text">Derecho de Oposición, el Usuario podrá solicitar que no se traten sus datos personales.</li>
                      <li className="static-page-list-text">Derecho de Limitación del tratamiento, el Usuario podrá solicitar la limitación al tratamiento de sus datos en los siguientes casos:</li>
                        <ul className='static-page-list-mid-margin static-page-list-margin-bottom'>
                          <li className="static-page-list-text">Mientras se comprueba la impugnación de la exactitud de tus datos.</li>
                          <li className="static-page-list-text">Cuando el tratamiento es ilícito, pero el Usuario se opone a la supresión de sus datos.</li>
                          <li className="static-page-list-text">Cuando BP no necesite tratar sus datos, pero el Usuario los necesite para el ejercicio o la defensa de reclamaciones.</li>
                          <li className="static-page-list-text">Cuando el Usuario se haya opuesto al tratamiento de tus datos para el cumplimiento de interés legítimo por parte de BP, mientras se verifica si los motivos legítimos para el tratamiento prevalecen sobre los suyos.</li>
                        </ul>

                      <li className="static-page-list-text">Derecho de Portabilidad, el Usuario podrá recibir, en formato electrónico, los datos personales que haya facilitado, así como a transmitirlos a otra entidad.</li>
                      <li className="static-page-list-text">Derecho de Revocación del consentimiento, el Usuario tendrá derecho a retirar el consentimiento otorgado para el tratamiento de los datos personales, en cualquier momento, y a través de cualquiera de los canales que se indican a continuación, sin que ello afecte a la licitud de los tratamientos realizados con carácter previo a su retirada</li>
                    </ul>

                    <p className="static-page-text">El Usuario tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (<a target="_blank" href='https://ww.agpd.es' className='static-page-links' rel="noreferrer">www.agpd.es</a>).</p>

                    <p className="static-page-text">Nos comprometemos a garantizar que sus datos personales se mantengan precisos y actualizados. Es su responsabilidad informarnos si hay inexactitudes o cambios en sus datos personales.</p>

                    <p className="static-page-text">Los canales de contacto para sus solicitudes o reclamaciones son los siguientes (por favor, indique “Protección de Datos” en el asunto):</p>

                    <p className="static-page-text">Email: <a href="mailto:atencionalcliente@mibp.es" className='static-page-links'>atencionalcliente@mibp.es</a></p>
                    <p className="static-page-text">Servicio de atención al cliente: <a href='tel:911799166' className='static-page-links'>911 799 166</a></p>
                    <p className="static-page-text">Dirección Postal: bp Energía ESPAÑA. C/ Quintanadueñas, 6 (edificio Arqbórea), 2ª planta - 28050 Madrid</p>
                  </div>
                </ol>
            </div>
            </Container>)
        }
      </div>
      <footer>
        {
          (isMobile) ? <FooterMobile /> : <Footer />
        }
      </footer>
    </>
  )
}
