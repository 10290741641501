import { configureStore } from "@reduxjs/toolkit";
import { prizesSlice } from "./prizes";
import { codesSlice } from "./codes";
import { momentsSlice } from "./moments";


export const store = configureStore({
  reducer: {
      codes: codesSlice.reducer,
      moments: momentsSlice.reducer,
      prizes: prizesSlice.reducer
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;