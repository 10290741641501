import { AuthState } from "../models";


type AuthAction =
    | { type: 'logIn', payload: { token: string }}
    | { type: 'logOut' }
    | { type: 'setIsLoading'}
    | { type: 'setToken', payload: { token: string }}
    | { type: 'setIp', payload: { ip: string }}
    | { type: 'setUserInfo', payload: { email: string, password: string, role: string }}


export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'logIn':
      return {
        ...state,
        status: 'authenticated',
        token: action.payload.token,
      };
    case 'logOut':
      return {
        ...state,
        status: 'not-authenticated',
        user: null,
        token: null,
      };
    case 'setIsLoading':
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case 'setToken':
      return {
        ...state,
        token: action.payload.token,
      };
    case 'setIp':
      return {
        ...state,
        ip: action.payload.ip,
      };
    case 'setUserInfo':
      return {
        ...state,
        user: {
          email: action.payload.email,
          plainPassword: action.payload.password,
          role: action.payload.role,
        },
      };
    default:
      return state;
  }
}