import { useEffect } from "react"
import * as CookieConsent from "vanilla-cookieconsent";
import { useGa } from "../../hooks"


interface Props {
    children: React.ReactElement<any, any>
}


export const CookiesTracking = ({ children } : Props) => {

  const { cookieEventDataLayer, virtualPageEventDataLayer} = useGa()
  const cookiesLocales = document.cookie.includes("cc_cookie=")
  

  useEffect(() => {

    CookieConsent.run({
        onConsent: ({cookie}) => {
          if(cookiesLocales){
            const cookiesGA : string[] = []
                  cookie.categories.forEach( element => {
                      switch( element ){
                          case "tecnicas": 
                              cookiesGA.push("C01")
                          break; 
                          case "rendimiento":
                              cookiesGA.push("C02")
                          break; 
                          case "terceros":
                              cookiesGA.push("C03")
                          break;
                          default:
                          break;
                      }
                      cookieEventDataLayer("CMPUpdated", cookiesGA.join(","))
                  })
            }
                
        },
        onFirstConsent({cookie}) {
          if(!cookiesLocales){
            const cookiesGA : string[] = []
            cookie.categories.forEach( element => {
                switch( element ){
                    case "tecnicas": 
                        cookiesGA.push("C01")
                    break; 
                    case "rendimiento":
                        cookiesGA.push("C02")
                        break; 
                    case "terceros":
                        cookiesGA.push("C03")
                        break;
                        default:
                          break;
                        }
                      })
                      cookieEventDataLayer("CMPUpdated", cookiesGA.join(","))
                      virtualPageEventDataLayer("virtual_page", window.location.href, window.location.pathname, ""); 
          }
        },
        onChange: ({cookie, changedCategories, changedServices}) => {
                const cookiesGA : string[] = []
                

                cookie.categories.forEach( element => {
                    switch( element ){
                        case "tecnicas": 
                            cookiesGA.push("C01")
                        break; 
                        case "rendimiento":
                            cookiesGA.push("C02")
                        break; 
                        case "terceros":
                            cookiesGA.push("C03")
                        break;
                        default:
                        break;
                    }
                })

                cookieEventDataLayer("CMPUpdated", cookiesGA.join(","))
                window.location.reload();
                
        },
        onModalShow: ({modalName}) => {
          cookieEventDataLayer("CMPLoaded", "")
      }, 
      categories: {
        tecnicas: {
          enabled: true,
          readOnly: true,
          services: {
            tecnicas: {
              label: 'Cookies técnicas',
              cookies: [
                { name: 'cc_cookie' },
                { name: 'eid_settings' },
                { name: 'myAwesomeCookieConsent' },
                { name: 'videoid' },
                { name: '_GRECAPTCHA' },
                { name: '__Secure-1PAPISID'},
                { name: '__Secure-1PSID'},
                { name: '__Secure-1PSIDCC'},
                { name: '__Secure-1PSIDTS'},
                { name: '__Secure-3PAPISID'},
                { name: '__Secure-3PSID'},
                { name: '__Secure-3PSIDCC'},
                { name: '__Secure-3PSIDTS'},
                { name: '__Secure-ENID' }
              ]
            }
          }
        },
        rendimiento: {
          enabled: true,
          services: {
            tecnicas: {
              label: 'Cookies de rendimiento',
              cookies: [
                { name: 'AEC' },
                { name: 'APISID' },
                { name: 'HSID' },
                { name: 'NID' },
                { name: 'OTZ' },
                { name: 'SAPISID' },
                { name: 'SID' },
                { name: 'SIDCC' },
                { name: 'SOCS' },
                { name: 'SSID' }
              ]
            }
          }
        },
        terceros: {
          services: {
            analiticas: {
              cookies: [
                {name: 'aam_uuid'},
                {name: '_ga'},
                {name: '_gid'},
                {name: '_gat'},
                {name: '_utmc'},
                {name: '_utma'},
                {name: '_utmb'},
                {name: '_utmz'},
                {name: '_fpb'},
                {name: '_fr'},
                {name: '_tr'},
                {name: 'ebOptUut'},
                {name: 'A1, B4, C4, D1, F1'},
                {name: 'bs.serving.sys'},
                {name: 'u2'},
                {name: 'TargetingInfo, g1'},
              ]
            }
          }
        }
      },
      language: {
        default: 'es',
        translations: {
          'es': {
            consentModal: {
              label: 'Cookie Consent',
              title: 'Información de las cookies',
              description: 'Utilizamos cookies para recopilar y analizar información sobre el rendimiento de nuestro sitio y para permitir que el sitio funcione. Las cookies también nos permiten a nosotros y a nuestros socios mostrarle anuncios relevantes cuando visita nuestro sitio y otros sitios web de terceros, incluidas las redes sociales. Puede elegir permitir todas las cookies haciendo clic en "Permitir todo" o administrarlas individualmente haciendo clic en "Administrar preferencias de cookies", donde también encontrará más información.',
              acceptAllBtn: 'Permitir todo',
              acceptNecessaryBtn: 'Permitir solo necesarias',
              showPreferencesBtn: 'Administrar preferencias de cookies'
            },
            preferencesModal: {
              title: 'Configuración de cookies',
              acceptAllBtn: 'Permitir todo',
              savePreferencesBtn: 'Aceptar cookies seleccionadas',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Cookies técnicas',
                  description: 'Estas cookies permiten que nuestros Servicios en línea recuerden las elecciones que realiza y proporcionen funciones mejoradas y personalizadas. Recopilan información (como su IP, preferencias y opciones, páginas navegadas o productos vistos) para que podamos brindarle una mejor experiencia, mejorar nuestros Servicios en línea y desarrollar nuevos servicios.  Por ejemplo, cuando inicia sesión en su cuenta para utilizar los Servicios en línea, utilizamos estas cookies para almacenar su información de inicio de sesión.  Esto permite que nuestros Servicios en línea recuerden sus preferencias de usuario y le brinden una experiencia más personalizada basada en esas preferencias.  También le permite utilizar los Servicios en línea sin tener que iniciar sesión cada vez que regrese a un Servicio en línea. Estas cookies generalmente se eliminan cuando finaliza su sesión de navegación o dentro de un período de tiempo posterior.  Si no permite estas cookies, es posible que no pueda aprovechar al máximo nuestros servicios en línea.',
                  linkedCategory: 'tecnicas'
                },
                {
                  title: 'Cookies analíticas o de rendimiento',
                  description: 'Utilizamos estas cookies para ayudarnos a analizar cómo se utilizan nuestros Servicios en línea y mejorar su funcionamiento, por ejemplo, para mejorar el rendimiento y hacer que nuestros Servicios en línea sean más fáciles de usar.  Estas cookies recopilan información sobre cómo los usuarios interactúan con nuestros servicios en línea, por ejemplo, sobre qué funciones de los servicios en línea se utilizan, durante cuánto tiempo se utilizan y si se ha producido algún error al utilizarlas. Estas cookies también nos ayudan a contar el número de usuarios únicos de un servicio en línea en particular y a medir el rendimiento y la eficacia del contenido que ponemos a disposición a través de nuestros servicios en línea.  La información obtenida a través de estas cookies puede ser divulgada o recopilada directamente por servicios de análisis de terceros.',
                  linkedCategory: 'rendimiento'
                },
                {
                  title: 'Cookies de terceros',
                  description: 'A veces trabajamos en colaboración con redes sociales como Facebook o con plataformas de publicidad online que dejan cookies en su dispositivo cuando visita nuestra página con el fin de recordar que usted la ha visitado o ha mostrado interés en nuestros productos ("Cookies de terceros"). Esto significa que, cuando vuelva a visitar estas páginas de terceros o visite una web que ha acordado alojar anuncios para nuestra plataforma publicitaria online, los anuncios que se le mostrarán se adaptarán más a sus preferencias. Si hace clic en estos anuncios, le redirigirán a nuestra página. Nuestros socios externos pueden enviarnos información sobre el número total de páginas vistas, contenidos compartidos y clics realizados sobre estos anuncios. El uso de su información por parte de terceros, incluido su despliegue de cookies y tecnologías de seguimiento, está sujeto a la política de privacidad del tercero, no a la nuestra.',
                  linkedCategory: 'terceros'
                }
              ]
            }
          }
        }
      }
    }
    );
  }, [])

  
  // useEffect(() => {
  //   cookieEventDataLayer("CMPLoaded", cookiesTypeValue.join(","))
  // }, [cookiesTypeValue])

  return ( 
    <>
        { children}
    </>
  )
}
