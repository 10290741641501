



const generateKey = async () => {
  return await crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256
    },
    true,
    ["encrypt", "decrypt"]
  );
};

const encryptData = async (data: any, key: any) => {
  const encodedData = new TextEncoder().encode(data);
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
  const encryptedData = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    key,
    encodedData
  );
  return {
    iv: Array.from(iv),
    data: Array.from(new Uint8Array(encryptedData))
  };
};

export const decryptData = async (encryptedData: any, key: any) => {
  const iv = new Uint8Array(encryptedData.iv);
  const data = new Uint8Array(encryptedData.data);
  const decryptedData = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    key,
    data
  );
  return new TextDecoder().decode(decryptedData);
};

const exportKey = async (key: any) => {
  return await crypto.subtle.exportKey("jwk", key);
};

export const importKey = async (keyData: any) => {
  return await crypto.subtle.importKey(
    "jwk",
    keyData,
    {
      name: "AES-GCM"
    },
    true,
    ["encrypt", "decrypt"]
  );
};

export const setEncryptedItem = async (key: any, value: any) => {
  const cryptoKey = await generateKey();
  const encryptedData = await encryptData(value, cryptoKey);
  const exportedKey = await exportKey(cryptoKey);

  sessionStorage.setItem(key, JSON.stringify({
    encryptedData: encryptedData,
    key: exportedKey
  }));
};

export const getDecryptedItem = async (key: any) => {
  const storedData = sessionStorage.getItem(key);
  if (!storedData) {
    return null;
  }

  const parsedData = JSON.parse(storedData);
  const importedKey = await importKey(parsedData.key);
  return await decryptData(parsedData.encryptedData, importedKey);
};