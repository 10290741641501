import { useEffect, useState } from 'react';
import './FilterComponent.css';
import { FaSearch, FaSync } from 'react-icons/fa';
import { exportCodes, exportMoments } from '../../services';
import { exportPrizes } from '../../services/PrizeService';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setCodesDateAfterValue, setCodesDateBeforeValue, setCodesPremiadosValue, setCodesSearchInputValue, setCodesSelectValue } from '../../store/codes';
import { setMomentsDateAfterValue, setMomentsDateBeforeValue, setMomentsSearchInputValue, setMomentsSelectValue } from '../../store/moments';
import { setPrizesSearchInputValue, setPrizesSelectValue } from '../../store/prizes';



interface Props {
  onFilter: (event: any) => void;
  onClear: () => void;
  page: string;
}


export const FilterComponent = ({ onFilter, onClear, page }: Props) => {

	const { codesSelectValue, codesDateAfterValue, codesDateBeforeValue, codesPremiadosValue, codesSearchInputValue } = useAppSelector(state => state.codes);
	const { momentsDateAfterValue, momentsDateBeforeValue, momentsSearchInputValue, momentsSelectValue } = useAppSelector(state => state.moments);
	const { prizesSearchInputValue, prizesSelectValue } = useAppSelector(state => state.prizes);
	const dispatch = useAppDispatch();
	const [filtersReady, setFiltersReady] = useState(false);

	const [selectValue, setSelectValue] = useState("");
	const [inputValue, setInputValue] = useState("");
	const [isDateFilter, setIsDateFilter] = useState(false);
	const selectOptions = [];

	const [dateBeforeValue, setDateBeforeValue] = useState("");
	const [dateAfterValue, setDateAfterValue] = useState("");

	const [filterQuery, setFilterQuery] = useState("");

	const [onlyPrize, setOnlyPrize] = useState(false);
	
	const handleClearInputs = () => {
		onClear();
	}

	const handleDateBeforeChange = (event: any) => {
		setDateBeforeValue(event.target.value);
		setInputValue("");
		switch (page) {
			case 'codes':
				dispatch(setCodesDateBeforeValue(event.target.value));
				dispatch(setCodesSearchInputValue(""));
				break;
			case 'moments':
				dispatch(setMomentsDateBeforeValue(event.target.value));
				dispatch(setMomentsSearchInputValue(""));
				break;
		}
	}

	const handleDateAfterChange = (event: any) => {
		setDateAfterValue(event.target.value);
		setInputValue("");
		switch (page) {
			case 'codes':
				dispatch(setCodesDateAfterValue(event.target.value));
				break;
			case 'moments':
				dispatch(setMomentsDateAfterValue(event.target.value));
				break;
		}
	}

	const handleSelectChange = (event : any ) => {
		setInputValue("");
		setSelectValue(event.currentTarget.value);
		switch (page) {
			case 'codes':
				dispatch(setCodesSelectValue(event.currentTarget.value));
				dispatch(setCodesSearchInputValue(""));
				break;
			case 'moments':
				dispatch(setMomentsSelectValue(event.currentTarget.value));
				dispatch(setMomentsSearchInputValue(""));
				break;
			case 'prizes':
				dispatch(setPrizesSelectValue(event.currentTarget.value));
				dispatch(setPrizesSearchInputValue(""));
				break;
		}
	}

	const handleInputChange = (e : any ) => {
		setInputValue(e.target.value.trim());
		switch (page) {
			case 'codes':
				dispatch(setCodesSearchInputValue(e.target.value.trim()));
				break;
			case 'moments':
				dispatch(setMomentsSearchInputValue(e.target.value.trim()));
				break;
			case 'prizes':
				dispatch(setPrizesSearchInputValue(e.target.value.trim()));
				break;
		}
	}

	const handleCheckboxChange = (e: any) => {
		setOnlyPrize(e.target.checked);
		dispatch(setCodesPremiadosValue(e.target.checked));
	}

	const handleExport = async() => {
		switch (page) {
			case 'codes':
				await exportCodes(filterQuery);
				break;
			case 'moments':
				await exportMoments(filterQuery);
				break;
			case 'prizes':
				await exportPrizes(filterQuery);
				break;
		}
	}

	const handleFilterChange = (fromBtn: boolean) => {
		let filterQuery = '&';
		if (selectValue && (inputValue || (isDateFilter && (dateAfterValue || dateBeforeValue)))) {
			const isMomentOrCodePage = 
				(page === 'moments' && selectValue === 'moment_at') || 
				(page === 'codes' && selectValue === 'used_at');
		
			if (isMomentOrCodePage) {
				filterQuery += `filterDates[]=${selectValue}`;
				
				if (dateBeforeValue && dateAfterValue) {
					filterQuery += `.before=${dateBeforeValue}&filterDates[]=${selectValue}.after=${dateAfterValue}`;
				} else if (dateBeforeValue) {
					filterQuery += `.before=${dateBeforeValue}`;
				} else if (dateAfterValue) {
					filterQuery += `.after=${dateAfterValue}`;
				}
			} else {
				filterQuery += `findBy[]=${selectValue}=${inputValue}`;
			}
		}

		if (onlyPrize) {
			const lastChar = filterQuery.substring(filterQuery.length - 1, filterQuery.length);
			(lastChar == '&') ? filterQuery += `hasPrize=${onlyPrize}` : filterQuery += `&hasPrize=${onlyPrize}`;
		}

		if (filterQuery.length <= 1) {
			return;
		}

		if (fromBtn) {
			setFilterQuery(filterQuery);
			onFilter(filterQuery);
		}
	}

	useEffect(() => {
		if((page == 'moments' && selectValue == 'moment_at') || (page == 'codes' && selectValue == 'used_at')) {
			setIsDateFilter(true);
		} else {
			setIsDateFilter(false);
		}
	}, [page, selectValue]);

	useEffect(() => {
		switch (page) {
			case 'codes':
				setSelectValue(codesSelectValue);
				setOnlyPrize(codesPremiadosValue);
				break;
			case 'moments':
				setSelectValue(momentsSelectValue);
				break;
			case 'prizes':
				setSelectValue(prizesSelectValue);
				setInputValue(prizesSearchInputValue);
				break;
		}
	}, []);

	useEffect(() => {
		switch (page) {
			case 'moments':
				if (selectValue == 'moment_at') {
					setDateBeforeValue(momentsDateBeforeValue);
					setDateAfterValue(momentsDateAfterValue);
				} else {
					setInputValue(momentsSearchInputValue);
				}
				break;
			case 'codes':
				if (selectValue == 'used_at') {
					setDateBeforeValue(codesDateBeforeValue);
					setDateAfterValue(codesDateAfterValue);
				} else {
					setInputValue(codesSearchInputValue);
				}
				break;
		}
		setFiltersReady(!filtersReady);
	}, [selectValue])

	useEffect(() => {
		handleFilterChange(false);
	}, [filtersReady])
	
	switch (page) {
		case "codes":
			selectOptions.push(
				{
					key: '',
					value: ""
				},
				{
					key: 'Código',
					value: "code"
				},
				{
					key: 'Usado el',
					value: "used_at"
				},
				{
					key: 'Premio ',
					value: "moment_id.prize_id.description"
				},
				{
					key: 'IP',
					value: 'ip.id'
				}
			)
		break;
		case "moments" : 
			selectOptions.push(
				{
					key: '',
					value: ""
				},
				{
					key: 'Momento',
					value: "moment_at"
				},
				{
					key: 'Premio asignado',
					value: "prize_id.description"
				},
				{
					key: 'Cupón',
					value: "prize_id.coupon"
				},
			)
		break;
		case "prizes":
			selectOptions.push(
				{
					key: '',
					value: ""
				},
				{
					key: 'Descripción',
					value: "description"
				},
				{
					key: 'Código EAN',
					value: "coupon"
				}
			)
		break;
	}


  return (
    <div className='admin-filter-box'>
			<div>
				{
					(page === 'codes') && (
						<button type="button" className='export-btn' onClick={handleExport}>
							EXPORTAR
						</button>
					)
				}
				<button type="button" className="admin-filter-reset-btn" onClick={handleClearInputs}>
					<FaSync />
				</button>
			</div>
			<div className="admin-filter-no-btn">
				{
					(page === 'codes') &&
						(
							<div className="admin-prize-checkbox-container mx-4">
								<input
									type="checkbox"
									name="checkboxform"
									style={{ marginRight: '10px' }}
									checked={onlyPrize}
									onChange={handleCheckboxChange}
								/>
								<label htmlFor="checkboxform">Premiados</label>
							</div>
						)
				}
				<select name='select-filter' value={selectValue} onChange={handleSelectChange} style={{ maxHeight: '40px' }}>
        	{
						selectOptions.map( op => 
							<option key={op.key} value={op.value}>{op.key}</option>
						)
					}
      	</select>
				{
					(isDateFilter)
						? (
							<div className="admin-filter-dates">
								<label htmlFor='dateBefore'> Desde: </label>
								<input
									name='dateBefore'
									type='datetime-local'
									value={dateBeforeValue}
									onChange={handleDateBeforeChange}
									className='admin-date-search-input'
								/>
								<label htmlFor='dateAfter'> Hasta: </label>
								<input
									name='dateAfter'
									type='datetime-local'
									value={dateAfterValue}
									onChange={handleDateAfterChange}
									className='admin-date-search-input'
								/>
								<button type="button" onClick={() => handleFilterChange(true)} className='date-search-btn'>
									Buscar
								</button>
							</div>
						)
						: (
							<div className="admin-filter-input">
								<input
									id="search"
									type="text"
									className='search-input'
									placeholder="Buscar"
									aria-label="Search Input"
									value={inputValue}
									onChange={handleInputChange}
								/>
								<button type="button" onClick={() => handleFilterChange(true)} className='clear-search-btn'>
									<FaSearch />
								</button>
							</div>
						)
					}
			</div>
		</div>
  )
}