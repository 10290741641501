import { Container, Spinner } from 'react-bootstrap';
import { AdminSidebar, FilterComponent } from '../../components';
import DataTable from 'react-data-table-component';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MomentResults } from '../../models';
import { getAllMoments } from '../../services';
import { AuthContext } from '../../context/AuthContext';
import { FaPowerOff } from 'react-icons/fa';
import './admin_pages.css'
import { useAppDispatch } from '../../store/hooks';
import { resetMomentsValues } from '../../store/moments';



export const Moments = () => {

  const [data, setData] = useState<MomentResults[]>([]);

  const ROW_SIZE = 30;
  const [totalRows, setTotalRows] = useState(0);
  const [totalOffset, setTotalOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filterText, setFilterText] = useState('');
  const [isSorted, setIsSorted] = useState(false);
  const [sortText, setSortText] = useState('');
  
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { logout } = useContext(AuthContext);

  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();

  const handlerLogOut = async (event : any) => {
    event.preventDefault();
    dispatch(resetMomentsValues());
    logout();
  }

  const columns = [
    {
      name: 'Momento',
      selector: (row: MomentResults) => row.moment_at,
      sortable: true,
    },
    {
      name: 'Premio asignado',
      selector: (row: MomentResults) => row.prize_id.description,
      sortable: true,
    },
    {
      name: 'Cupón',
      selector: (row: MomentResults) => row.prize_id.coupon,
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    noRowsPerPage: true, 
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
  };

  const fetchData = async (filters: string) => {
    setLoading(true);
    const response = await getAllMoments(filters, totalOffset);
    setData((response != null)? response.data : []);
    setTotalRows((response != null)? response.total : 0)
    setLoading(false);
  }

  const fetchSortData = async (sort : string) => {
    setLoading(true);
    setIsSorted(true);
    setSortText(sort);
    const fullFilter = filterText + "&" + sort;
    const response : any = await getAllMoments(fullFilter, totalOffset);
    setData((response != null)? response.data : []);
    setLoading(false);
  }

  const handlePageChange = async (page : any) => {
    if (currentPage < page) {
      setTotalOffset(totalOffset + ROW_SIZE);
    }
    if (currentPage > page) {
      setTotalOffset(totalOffset - ROW_SIZE);
    }
    setCurrentPage(page);
  };

  const customSort = async (rows : any, selector : any, direction: any) => {
    let cleanField = '';

    switch(rows.name) {
      case 'Momento':
        cleanField = 'moment_at';
        break;
      case 'Cupón':
        cleanField = 'prize_id.coupon';
        break;
      case 'Premio asignado':
        cleanField = 'prize_id.description';
        break;
    }
     
    let upperCaseSelector = selector.toUpperCase();
    let sortParam = `orderBy[]=${cleanField}=${upperCaseSelector}`
    await fetchSortData(sortParam)
  }

  const subHeaderComponentMemo = useMemo(() => {
		const handleClear = async() => {
      if (filterText) {
        setCurrentPage(1);
        setTotalOffset(0);
        setResetPaginationToggle(!resetPaginationToggle);
        await fetchData(filterText);
      }
		};

		return (
			<FilterComponent
        onFilter={(text: string) => setFilterText(text)}
        onClear={handleClear}
        page='moments'
      />
		);
	}, [filterText, data]);

  useEffect(() => {
    if (!filterText) {
      return;
    }
    if (isSorted) {
      fetchSortData(sortText)
    } else {
      fetchData(filterText);
    }
  }, [currentPage, filterText]);


  return (
    <>
      <Container fluid className="admin-main-navbar-container">
        <div className="admin-main-navbar-logo-menu">
          <div className="admin-main-navbar-logo-box">
            <img src={`${appURL}/images/bp-logo.png`} alt="Logo BP" className='admin-main-navbar-logo' />
          </div>
        </div>
        <div className="admin-main-navbar-profile">
          <FaPowerOff className='admin-navbar-profile-icon' onClick={handlerLogOut}/>
        </div>
      </Container>

    
      <div className="admin-main-container">
        <AdminSidebar />
        <div className="admin-main-content">
          <h1>Momentos ganadores</h1>
          <div className='admin-main-content-datatable'>
            <DataTable 
              columns={columns}
              data={data}
              progressPending={loading}
              progressComponent={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Spinner animation="border" role="status" style={{ marginTop: '15px', color: '#009640' }}></Spinner>
                </div>
              }
              noDataComponent={
                <div style={{ padding: '24px' }}>
                  {
                    (filterText.length > 0)
                      ? 'No se han encontrado datos con los filtros aplicados'
                      : 'Es necesario filtrar para poder mostrar los datos'
                  }
                </div>
               }
              pagination
              paginationServer
              paginationPerPage={ROW_SIZE}
              paginationTotalRows={totalRows}
              paginationComponentOptions={paginationComponentOptions}
              paginationResetDefaultPage={resetPaginationToggle}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              onChangePage={handlePageChange}
              sortServer
              onSort={customSort as any}
            />
          </div>
        </div>
      </div>
    </>
  )
}