import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './router/AppRouter';
import "vanilla-cookieconsent/dist/cookieconsent.css";

import { AuthProvider } from './context/AuthContext';
import { GaProvider } from './context/GaContext';
import AxiosInterceptor from './api/AxiosInterceptor';
import { CookiesTracking } from './components/tracking/CookiesTracking';
import { Provider } from 'react-redux';
import { store } from './store/store';

function App() {

  

  return (
    <AuthProvider>
      <Provider store={store}>
        <AxiosInterceptor>
          <BrowserRouter>
            <GaProvider>
              <CookiesTracking>
                <AppRouter />
              </CookiesTracking>
            </GaProvider>
          </BrowserRouter>
        </AxiosInterceptor>
      </Provider>
    </AuthProvider>
  );
}

export default App;
