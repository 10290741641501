import { useGa } from '../../hooks';
import './Footer.css';
import { showPreferences } from 'vanilla-cookieconsent';

interface Props {
  result ?: string
}

export const Footer = ({ result } : Props) => {

  const openCookiePopUp = () => showPreferences();
  const appVersion = (process.env.REACT_APP_VERSION) && process.env.REACT_APP_VERSION.toString();
  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();

  const { eventDataLayer } = useGa();

  return (
    <>
    {
      (result != undefined)
      ? (<div className="footer">
        <p>
          <a href="/cookies" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_politica de cookies')} rel="noreferrer">Política de Cookies</a>
          <span className="spacer">|</span>
          <a type="button" onClick={openCookiePopUp} className='pointer-link'>Configurar Cookies</a>
          <span className="spacer">|</span>
          <a href="/privacidad" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_declaracion de privacidad')} rel="noreferrer">Declaración de Privacidad</a>
          <span className="spacer">|</span>
          <a href="/condiciones" target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_terminos y condiciones')} rel="noreferrer">Términos y Condiciones</a>
          <span className="spacer">|</span>
          <a href="https://www.bp.com/es_es/spain/home.html" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_bp españa')} rel="noreferrer">BP España</a>
          <span className="spacer">|</span>
          <a href="https://mibp.es/es/app-mi-bp" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'primer nivel_mibp.es')} rel="noreferrer">miBP.es</a>
        </p>
        <p className='social-links'>
          <a href="https://www.instagram.com/bp_espana/" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'rrss_instagram')} rel="noreferrer">Instagram</a>
          <img src={`${appURL}/images/instagram.png`} alt="instagram" className='social-logo' />
          <span className="spacer">|</span>
          <a href="https://es-la.facebook.com/BPenEspana/" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname +"|" + result, 'clic_footer', 'rrss_facebook')} rel="noreferrer">Facebook</a>
          <img src={`${appURL}/images/facebook.png`} alt="facebook" className='social-logo' />
        </p>
        <div className="version">
          <p>Version: { appVersion }</p>
        </div>
      </div>)
      : (<div className="footer">
        <p>
          <a href="/cookies" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_politica de cookies')} rel="noreferrer">Política de Cookies</a>
          <span className="spacer">|</span>
          <a type="button" onClick={openCookiePopUp} className='pointer-link'>Configurar Cookies</a>
          <span className="spacer">|</span>
          <a href="/privacidad" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_declaracion de privacidad')} rel="noreferrer">Declaración de Privacidad</a>
          <span className="spacer">|</span>
          <a href="/condiciones" target='_blank' onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_terminos y condiciones')} rel="noreferrer">Términos y Condiciones</a>
          <span className="spacer">|</span>
          <a href="https://www.bp.com/es_es/spain/home.html" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_bp españa')} rel="noreferrer">BP España</a>
          <span className="spacer">|</span>
          <a href="https://mibp.es/es/app-mi-bp" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'primer nivel_mibp.es')} rel="noreferrer">miBP.es</a>
        </p>
        <p className='social-links'>
          <a href="https://www.instagram.com/bp_espana/" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'rrss_instagram')} rel="noreferrer">Instagram</a>
          <img src={`${appURL}/images/instagram.png`} alt="instagram" className='social-logo' />
          <span className="spacer">|</span>
          <a href="https://es-la.facebook.com/BPenEspana/" target="_blank" onClick={() => eventDataLayer('select_content', window.location.pathname, 'clic_footer', 'rrss_facebook')} rel="noreferrer">Facebook</a>
          <img src={`${appURL}/images/facebook.png`} alt="facebook" className='social-logo' />
        </p>
        <div className="version">
          <p>Version: { appVersion }</p>
        </div>
      </div>)
    }
      
    </>
  )
}