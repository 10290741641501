import './AdminSidebar.css'
import { useContext, useState } from "react"
import { Menu, MenuItem, MenuItemStyles, Sidebar } from "react-pro-sidebar"
import { FaAward, FaChevronRight, FaChevronLeft, FaClock, FaMoneyBillWaveAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';


export const AdminSidebar = () => {

  const { authState } = useContext(AuthContext);

  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();
  const [collapsed, setCollapsed] = useState(false);

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '20px',
      fontWeight: 400,
      color: 'white'
    },
    button: {
      '&:hover': {
        backgroundColor: '#007f00',
        color: 'white'
      }
    },
    icon: {
      color: 'white'
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div style={{ display: 'flex', height: '100vh', minHeight: '400px', maxWidth: '250px' }}>
      <Sidebar
        collapsed={collapsed}
        transitionDuration={500}
        style={{ height: '260vh', backgroundColor: '#009640', zIndex: '99999999', overflowY: 'hidden' }}
      >
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem icon={<FaMoneyBillWaveAlt />} style={{ marginTop: '40px' }} component={<Link to={'/admin-17827bh671n7/codes'} />}>
            Códigos
          </MenuItem>
          {
            (authState.user?.role === 'admin') &&
            (
              <>
                <MenuItem icon={<FaClock />} component={<Link to={'/admin-17827bh671n7/moments'} />}>
                  Momentos
                </MenuItem>
                <MenuItem icon={<FaAward />} component={<Link to={'/admin-17827bh671n7/prizes'} />}>
                  Premios
                </MenuItem>
              </>
            )
          }
        </Menu>
        <div>
          <button className="sb-button" onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <FaChevronRight /> : <FaChevronLeft />}
          </button>
        </div>
      </Sidebar>
    </div>
  );
}