import { createSlice } from "@reduxjs/toolkit";


export const codesSlice = createSlice({
  name: 'codes',
  initialState: {
    codesSelectValue: '',
    codesSearchInputValue: '',
    codesDateBeforeValue: '',
    codesDateAfterValue: '',
    codesPremiadosValue: false
  },
  reducers: {
    setCodesSelectValue: (state, action) => {
      state.codesSelectValue = action.payload
    },
    setCodesSearchInputValue: (state, action) => {
      state.codesSearchInputValue = action.payload
    },
    setCodesDateBeforeValue: (state, action) => {
      state.codesDateBeforeValue = action.payload
    },
    setCodesDateAfterValue: (state, action) => {
      state.codesDateAfterValue = action.payload
    },
    setCodesPremiadosValue: (state, action) => {
      state.codesPremiadosValue = action.payload
    },
    resetCodesValues: (state) => {
      state.codesSelectValue = '';
      state.codesSearchInputValue = '';
      state.codesDateBeforeValue = '';
      state.codesDateAfterValue = '';
      state.codesPremiadosValue = false;
    }
  }
});

export const { setCodesSelectValue, setCodesSearchInputValue, 
  setCodesDateBeforeValue, setCodesDateAfterValue, setCodesPremiadosValue, resetCodesValues } = codesSlice.actions;