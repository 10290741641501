import { useGa } from '../../hooks';
import './MainBanner.css'
import { Container } from 'react-bootstrap';


export const MainBanner = () => {

  const appURL = (process.env.REACT_APP_URL) && process.env.REACT_APP_URL.toString();
  const { eventDataLayer } = useGa();

  return (
    <>
      <div className='main-banner-container'>
        <div className='left-container'>
          <Container>
          <div>
            <h3 className="banner-text"> Canjear un código en esta web </h3>
            <h3 className="banner-text"> y ganar un viaje a Abu Dabi </h3>
            <h3 className="banner-text"> es de ser muy </h3>
          </div>
          <div className="p-0">
          <img src={`${appURL}/images/bip.png`} alt="BP Important Person" className='bipImage' />
          </div>
          <div className="p-0">
            <a href="#voucher-form">
              <button className='codeButton' onClick={() => eventDataLayer('navigation', window.location.pathname, 'clic_boton', 'boton_canjea tu premio aqui')}>Canjea tu código <strong>aquí</strong></button>
            </a>
          </div>
          <div style={{ position: 'relative' }}>
          <img src={`${appURL}/images/bp-alpine-box.png`} alt="Bp's Partner Alpine" className='main-bp-partner-image' />
          </div>
          </Container>
        </div>
      </div>
    </>
  )
}