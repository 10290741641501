import { useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import apiBuilder from './apiBuilder';

const AxiosInterceptor = ({ children }: any) => {
  const { authState: { token } } = useContext(AuthContext);

  const pendingRequests = new Map<string, { controller: AbortController, timeoutId: NodeJS.Timeout}>();
  const REQUEST_TIMEOUT = 60000;

  useEffect(() => {
    const requestInterceptor = apiBuilder.interceptors.request.use(
      (config) => {

        const controller = new AbortController();
        
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }

        const url = config.url!.substring(0, config.url!.indexOf("?")) || config.url!;
        
        if (pendingRequests.has(url)) {
          const { controller: existingController, timeoutId: existingTimeoutId } = pendingRequests.get(url)!;
          existingController.abort('Duplicated request removed');
          clearTimeout(existingTimeoutId);
          pendingRequests.delete(url);
        }
        
        config.signal = controller.signal;

        // Set timeout to abort request
        const timeoutId = setTimeout(() => {
          controller.abort('Request timeout');
          pendingRequests.delete(url);
        }, REQUEST_TIMEOUT);

        pendingRequests.set(url, { controller, timeoutId });

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = apiBuilder.interceptors.response.use(
      (response) => {
        const url = response.config.url!.substring(0, response.config.url!.indexOf("?")) || response.config.url!;
        const { timeoutId } = pendingRequests.get(url)!;

        clearTimeout(timeoutId);
        pendingRequests.delete(url);

        return response;
      },
      (error) => {
        if (error.config) {
          const url = error.config.url!.substring(0, error.config.url!.indexOf("?")) || error.config.url!;
          const { timeoutId } = pendingRequests.get(url)!;

          clearTimeout(timeoutId);
          pendingRequests.delete(url);
        }
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptor
    return () => {
      apiBuilder.interceptors.request.eject(requestInterceptor);
      apiBuilder.interceptors.response.eject(responseInterceptor);
    };
  }, [token]);

  return children;
};

export default AxiosInterceptor;